import classNames from "classnames";

type VideoPlayerProps = {
  poster?: string;
  sources: { src: string; type: "video/mp4" | "video/webm" | "video/ogg" }[];
  width: number;
  height: number;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  playsinline?: boolean;
  className?: string;
};

function VideoPlayer({
  poster,
  sources,
  width,
  height,
  autoPlay = true,
  loop = true,
  muted = true,
  controls = false,
  playsinline = true,
  className,
}: VideoPlayerProps) {
  return (
    <video
      poster={poster}
      width={width}
      height={height}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      controls={controls}
      playsInline={playsinline}
      className={classNames("border border-stealth-bomber", className)}
    >
      {sources.map(({ src, type }) => (
        <source key={src} type={type} src={src} />
      ))}
    </video>
  );
}

export type { VideoPlayerProps };
export default VideoPlayer;
