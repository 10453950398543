import React from "react";
import classNames from "classnames";

type ProfileBeforeAfterLabelProps = { before: true; after?: false } | { before?: false; after: true };

const ProfileBeforeAfterLabel = ({ before, after }: ProfileBeforeAfterLabelProps) => {
  return (
    <div
      className={classNames("rounded-sm pt-2.5 pb-2 px-4 font-[600] text-sm absolute bottom-2 pointer-events-none", {
        "bg-stealth-bomber-30": before,
        "bg-ekans": after,
        "left-2": before,
        "right-2": after,
      })}
    >
      {before ? "Original" : "Edited"}
    </div>
  );
};

export { ProfileBeforeAfterLabel };
