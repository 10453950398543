import { Image as DatoImage, renderMetaTags, TitleMetaLinkTag, useQuerySubscription } from "react-datocms";
import classNames from "classnames";
import { GetStaticProps, GetStaticPaths } from "next";
import Image from "next/image";
import { Api } from "@/api";
import { Layout, SEO, Section, Container, StickySideNav, LessonThreeUp, Video, HtmlToElement } from "@/components";
import { DATOCMS_API_TOKEN, EXTERNAL, PAGE_RECORDS } from "@/config";
import { metaTagsFragment, responsiveImageFragment } from "@/modules";
import { Product, Subscription, ILesson } from "@/types";
import { slugify, capitalize } from "@/utils";
import { AnchorButton, PromoBanner, HorizontalRule } from "@narrative-software/narrative-web-ui";
import styles from "../../styles/custom.module.css";

interface ILessonPage {
  moreLessons: ILesson[];
  page: ILesson & {
    seo: TitleMetaLinkTag[];
  };
}

type Props = {
  product: Product;
  preview: boolean;
  subscription: Subscription<ILessonPage>;
};

export const LessonPage: React.FC<Props> = ({ product, preview, subscription }) => {
  const { data } = useQuerySubscription(subscription);
  if (!data) return null;

  const {
    moreLessons,
    page: { seo, title, description, parentPage, position, video, steps },
  } = data;

  // Parent href
  const parentHref = `/${parentPage.parentPage.slug}/${parentPage.slug}`;

  // Video
  let videoUrl = video?.[0]?.videoUrl?.url;
  const isVideoFile = video?.[0]?.isVideoFile;
  if (isVideoFile) {
    videoUrl = video?.[0]?.videoFile.video.streamingUrl;
  }

  // Sticky nav items
  const navItems = steps.map((step, i) => ({
    ...step,
    title: `Step ${i + 1} - ${step.title}`,
  }));

  // Get the next lesson by drag and drop 'position'
  const nextLessonObj = moreLessons.find((lesson) => lesson.position === position + 1);
  const nextLesson = nextLessonObj && {
    title: nextLessonObj.title,
    path: `${parentHref}/${nextLessonObj.slug}`,
  };

  return (
    <Layout product={product} preview={preview}>
      <SEO title={`${title} | Narrative`}>{renderMetaTags(seo)}</SEO>

      <Section isFirstSection>
        <Container>
          <div className="flex flex-col items-center text-center">
            <h1 className="text-h2 font-semibold leading-tight lg:text-h1">
              {capitalize(product)} {title}
            </h1>
            <HorizontalRule topMargin bottomMargin={!!description} />
            {description && <HtmlToElement className="max-w-200 lg:text-lg">{description}</HtmlToElement>}
          </div>

          {videoUrl && (
            <div className="mt-12 border border-current">
              <Video url={videoUrl} isVideoFile={isVideoFile} />
            </div>
          )}
        </Container>
      </Section>

      <Section className="-mt-8 md:-mt-14 lg:-mt-18 xl:-mt-28">
        <Container>
          <div className={classNames("grid grid-cols-1 gap-15 lg:gap-22 xl:gap-30", styles.grid)}>
            <div>
              <StickySideNav title={title} nextPage={nextLesson} items={navItems} />
            </div>
            <div>
              {steps.map(({ id, title, content, image }, i) => {
                const stepNumber = i + 1;
                const stepTitle = `Step ${stepNumber} - ${title}`;
                return (
                  <section key={id} id={slugify(stepTitle)} className={classNames({ "pb-15": i < steps.length - 1 })}>
                    <h3 className="text-h3 font-semibold leading-tight mb-5">{stepTitle}</h3>
                    <HtmlToElement className="prose text-stealth-bomber leading-relaxed">{content}</HtmlToElement>
                    {image && (
                      <div className="mt-8 border border-current">
                        <DatoImage data={{ alt: title, ...image.responsiveImage }} />
                      </div>
                    )}
                  </section>
                );
              })}
            </div>
          </div>
        </Container>
      </Section>

      {moreLessons.length && <LessonThreeUp title="Up next" items={moreLessons} parentHref={parentHref} />}

      <PromoBanner
        title="Need more help?"
        backgroundColour={product === "select" ? "gengar" : "slowpoke"}
        image={
          <Image src="/images/photographer-male-back-question-mark.svg" alt="" width="220" height="320" unoptimized />
        }
      >
        <AnchorButton href={EXTERNAL.SUPPORT.HREF} colour="black" external>
          Visit support
        </AnchorButton>
      </PromoBanner>
    </Layout>
  );
};

/**
 * Static paths
 */
export const getStaticPaths: (product: Product) => GetStaticPaths = (product) => async () => {
  const {
    data: {
      data: { pages },
    },
  } = await Api.datocmsGraphqlRequest<{ pages: ILesson[] }>({
    query: `
      {
        pages: allPageLessons {
          slug
          parentPage {
            slug
            parentPage {
              ... on ${PAGE_RECORDS.select} {
                slug
              }
              ... on ${PAGE_RECORDS.publish} {
                slug
              }
            }
          }
        }
      }
    `,
  });

  // Remove lessons that don’t match the product
  const paths = pages
    .filter(({ parentPage }) => parentPage.parentPage.slug === product)
    .map(({ slug }) => ({ params: { slug } }));

  return {
    paths,
    fallback: false,
  };
};

/**
 * Static props
 */
export const getStaticProps: (product: Product) => GetStaticProps<Props> =
  (product) =>
  async ({ params, preview = false }) => {
    const graphqlRequest = {
      preview,
      variables: {
        slug: params?.slug,
      },
      query: `
      query($slug: String) {
        page: pageLesson(filter: { slug: { eq: $slug }}) {
          title
          description
          position
          parentPage {
            slug
            parentPage {
              ... on ${PAGE_RECORDS.select} {
                slug
              }
              ... on ${PAGE_RECORDS.publish} {
                slug
              }
            }
          }
          steps {
            id
            title
            content
            image {
              responsiveImage(imgixParams: { auto: format, w: 1576 }) {
                ...responsiveImageFragment
              }
            }
          }
          video {
            isVideoFile
            videoUrl {
              url
            }
            videoFile {
              url
              video {
                streamingUrl
                mp4Url(res: high)
                thumbnailUrl(format: jpg)
              }
            }
          }
          seo: _seoMetaTags {
            ...metaTagsFragment
          }
        }

        moreLessons: allPageLessons(
          first: 2,
          orderBy: position_ASC,
          filter: { slug: { neq: $slug }}
        ) {
          id
          title
          slug
          description
          duration
          position
          parentPage {
            slug
            parentPage {
              ... on ${PAGE_RECORDS.select} {
                slug
              }
              ... on ${PAGE_RECORDS.publish} {
                slug
              }
            }
          }
          image {
            responsiveImage(imgixParams: { auto: format, fit: crop, w: 780, h: 446 }) {
              ...responsiveImageFragment
            }
          }
        }
      }
      ${metaTagsFragment}
      ${responsiveImageFragment}
    `,
    };

    const {
      data: {
        data: { page, moreLessons },
      },
    } = await Api.datocmsGraphqlRequest<ILessonPage>(graphqlRequest);

    // Remove lessons that don’t match the product
    const initialData = {
      page,
      moreLessons: moreLessons.filter(({ parentPage }) => parentPage.parentPage.slug === product),
    };

    // If the page doesn’t exist, throw 404
    if (!initialData?.page) {
      return { notFound: true };
    }

    return {
      props: {
        product,
        preview,
        subscription: preview
          ? {
              ...graphqlRequest,
              initialData,
              token: DATOCMS_API_TOKEN,
            }
          : {
              initialData,
              enabled: false,
            },
      },
    };
  };
