const TiktokIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path
      d="M11.4974 0.737879L11.4975 0.737853C11.4183 0.280814 11.4524 0 11.4562 0H8.33005V11.9355C8.33005 11.946 8.33005 11.9564 8.33005 11.9669C8.33014 12.3753 8.33023 12.7697 8.19052 13.1642C7.96426 13.8086 7.4778 14.393 6.82919 14.6515C5.97317 14.9924 5.28308 15.0292 4.51002 14.6209C4.51136 14.6227 4.51268 14.6245 4.51398 14.6263C4.51263 14.6245 4.51127 14.6227 4.50993 14.6209C4.40388 14.5646 4.27705 14.4874 4.16722 14.416C3.23557 13.7663 3.06514 12.7936 3.06893 12.1063C3.17876 11.1711 3.54991 10.6491 4.24675 10.112C5.24658 9.38716 6.50634 9.78626 6.50634 9.78626V6.68879C5.92183 6.60638 5.32796 6.58963 4.74723 6.69078C4.36259 6.76196 3.98549 6.8706 3.62347 7.02045C3.04651 7.24147 2.50725 7.5599 2.03211 7.957C1.49286 8.41779 1.04034 8.97223 0.693403 9.58661C0.561418 9.81138 0.0636455 10.7142 0.00330948 12.179C-0.0344005 13.0107 0.218256 13.8723 0.338928 14.2282V14.2357C0.414348 14.4455 0.708487 15.161 1.1874 15.7641C1.5416 16.2048 1.9491 16.5957 2.40706 16.9281C2.44294 16.9662 2.47927 17.0039 2.516 17.0412L2.52357 17.0487C4.04224 18.0665 5.72755 17.9989 5.72755 17.9989C5.74902 17.9981 5.7742 17.9974 5.80288 17.9966C6.16375 17.9864 7.07796 17.9608 8.10592 17.4806C9.33676 16.906 10.0374 16.0497 10.0374 16.0497C10.0451 16.0405 10.0552 16.0286 10.0676 16.014C10.2258 15.8283 10.746 15.2177 11.0902 14.3259C11.3743 13.5898 11.469 12.7072 11.469 12.3541V6.00706C11.5068 6.02959 12.0143 6.36009 12.0143 6.36009C12.0143 6.36009 12.7452 6.82204 13.8852 7.12249C14.7032 7.33657 15.749 7.38163 15.749 7.38163V4.98984C15.7491 4.9897 15.7492 4.98956 15.7494 4.98943V4.31565C15.3647 4.35685 14.6088 4.23697 13.8093 3.84362C13.7097 3.79262 13.6148 3.73921 13.521 3.68148C13.4998 3.65953 13.4835 3.64198 13.4724 3.62972C13.1353 3.41189 12.8248 3.14899 12.5483 2.85605C11.8591 2.07864 11.5921 1.28996 11.4974 0.737879Z"
      fill="currentColor"
    />
  </svg>
);

export default TiktokIcon;
