import classNames from "classnames";
import { BLACK_FRIDAY_2024_SLUGS, CreditPurchasingPack, numberToCurrency } from "@/utils";

type EditPlanPackRadioItemProps = {
  pack: CreditPurchasingPack;
  selected: boolean;
  onSelectPack: (pack: CreditPurchasingPack) => void;
};

function EditPlanPackRadioItem({ pack, selected, onSelectPack }: EditPlanPackRadioItemProps) {
  const isSale = pack.isSale;

  let description = pack.description;
  // BLACKFRIDAY2024TEMP
  // don't show the description for Black Friday packs
  if(BLACK_FRIDAY_2024_SLUGS.includes(pack.slug)) {
    description = "";
  }

  return (
    <label
      key={pack.id}
      className={classNames("border rounded-sm cursor-pointer flex transition-colors bg-caspar relative", {
        "border-cinder-block": !isSale && !selected,
        "hover:border-stealth-bomber": !isSale && !selected,
        "border-stealth-bomber": isSale || (!isSale && selected),
        "text-white": isSale,
        "text-stealth-bomber": !isSale,
        'bg-[url("/images/edit/sale-pack-bg.webp")] bg-no-repeat bg-center bg-cover': isSale,
      })}
    >
      <div
        className={classNames("flex flex col items-center justify-center border-r px-2", {
          "border-ball-bearing": !isSale,
          "border-stealth-bomber": isSale,
        })}
      >
        <div
          className={classNames("rounded-full h-4.5 w-4.5 border flex flex-col items-center justify-center", {
            "border-stealth-bomber": !isSale,
            "border-white": isSale,
          })}
        >
          <div
            className={classNames("rounded-full transition-opacity h-3 w-3", {
              "opacity-0": !selected,
              "opacity-100": selected,
              "bg-stealth-bomber": !isSale,
              "bg-white": isSale,
            })}
          />
        </div>
      </div>
      <div className="px-4.5 text-left py-4.5 w-full">
        <p
          className={classNames("text-md font-semibold md:text-lg", {
            "mt-3 lg:mt-2": isSale,
          })}
        >
          {getPackName(pack)}
        </p>
        <p className="text-xxs font-bold">
          {`${pack.perCreditAmount}c per image. `}
          <span
            className={classNames("font-normal", {
              "text-shady-lady": !isSale,
              "text-white": isSale,
            })}
          >
            {getCostDescription(pack)}
          </span>
          {isSale && (
            <span
              className={classNames("font-semibold text-jolteon", {
                "text-shady-lady": !isSale,
                "text-white": isSale,
              })}
            >
              {" "}
              <br className="block sm:none" />
              {description}
            </span>
          )}
        </p>
        <input type="radio" value={pack.slug} name="pack" onChange={() => onSelectPack(pack)} hidden />
      </div>
      {pack.saving > 0 && (
        <div
          className={classNames(
            "py-2 px-3 whitespace-nowrap uppercase text-xxxs font-bold tracking-wider leading-[100%] min-w-[90px] absolute top-0 right-0",
            {
              "bg-coupon-green text-white": !isSale,
              "bg-jolteon text-stealth-bomber": isSale,
            },
          )}
        >{`Save ${numberToCurrency(pack.saving)}`}</div>
      )}
    </label>
  );
}

function getPackName(pack: CreditPurchasingPack) {
  if (pack.slug === "edit_payg") {
    return "Pay as you go";
  }
  if (pack.slug === "edit_20k_sale") {
    return "20,000 launch sale pack 🎉";
  }
  if(BLACK_FRIDAY_2024_SLUGS.includes(pack.slug)) {
    return `${pack.credits.toLocaleString("en-US")} Black Friday pack`;
  }
  return `${pack.credits.toLocaleString("en-US")} credit pack`;
}

function getCostDescription(pack: CreditPurchasingPack) {
  if (pack.slug === "edit_payg") {
    return "Charged at time of use";
  }
  return `${numberToCurrency(pack.unitAmount)} Upfront.`;
}

export default EditPlanPackRadioItem;
