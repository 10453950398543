import { useModal, useModalDispatch } from "@/providers";
import { Modal, ModalActionType } from "@narrative-software/narrative-web-ui";

const ModalContainer: React.FC = () => {
  const modalDispatch = useModalDispatch();
  const { size, isOpen, hideBorder, showOverflow, children } = useModal();

  const closeModal = () => modalDispatch({ type: ModalActionType.Close });

  return (
    <Modal {...{ size, isOpen, hideBorder, showOverflow }} onClose={closeModal}>
      {children}
    </Modal>
  );
};

export default ModalContainer;
