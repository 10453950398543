import { useState, useEffect } from "react";
import { useCountUp } from "react-countup";

interface Props {
  icon: React.ReactNode;
  end: number;
  start?: number;
  decimals?: number;
  suffix?: string;
  canStart?: boolean;
}

const Figure: React.FC<Props> = ({ icon, end, start, decimals, suffix, canStart, children }) => {
  const [hasInitiated, setInitiated] = useState(false);

  const { countUp, start: initiate } = useCountUp({
    end,
    start: start || 0,
    decimals: decimals || 0,
    duration: 3,
  });

  useEffect(() => {
    if (canStart && !hasInitiated) {
      initiate();
      setInitiated(true);
    }
  }, [canStart, hasInitiated]);

  return (
    <article className="flex flex-col flex-1 items-center w-full text-center">
      <div className="flex shrink-0 items-center mb-1 h-[40px]">{icon}</div>
      <div className="text-34 font-semibold lg:text-68">
        {countUp}
        {suffix}
      </div>
      <p className="text-lg leading-tight">{children}</p>
    </article>
  );
};

export { Figure };
