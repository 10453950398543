const FacebookIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.84842 0C1.723 0 0 1.72299 0 3.84841V14.1514C0 16.2769 1.723 17.9998 3.84842 17.9998H8.13142V11.0532H5.63369V8.34563H8.13142V6.34889C8.13142 4.03173 9.64337 2.76995 11.8518 2.76995C12.9096 2.76995 13.8189 2.84367 14.0838 2.87667V5.29831L12.5521 5.29894C11.3511 5.29894 11.1186 5.83314 11.1186 6.61703V8.34563H13.983L13.6099 11.0532H11.1186V17.9998H14.4602C16.5856 17.9998 18.3086 16.2769 18.3086 14.1514V3.84842C18.3086 1.723 16.5856 0 14.4602 0H3.84842Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIcon;
