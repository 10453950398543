import { Section, Container } from "@/components";
import { Accordion as UIAccordion } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface AccordionFragmentType {
  _modelApiKey: typeof MODULE_KEYS.accordion;
  id: string;
  accordionId: string;
  items: AccordionItemFragmentType[];
  heading?: string;
  headingSize?: "h1" | "h2" | "h3";
  hasLine?: boolean;
  allowMultipleExpanded?: boolean;
  isSection?: boolean;
  className?: string;
}

export interface AccordionItemFragmentType {
  id: string;
  heading: string;
  content: string;
  isDefaultExpanded?: boolean;
}

// Fragment
export const accordionFragment = `
  fragment accordionFragment on ModuleAccordionRecord {
    _modelApiKey
    id
    heading
    headingSize
    accordionId
    hasLine
    isSection
    allowMultipleExpanded
    items {
      id
      heading
      content
      isDefaultExpanded
    }
  }
`;

export const Accordion: React.FC<AccordionFragmentType> = ({
  id,
  items,
  accordionId,
  heading = "",
  headingSize = "h3",
  hasLine = false,
  allowMultipleExpanded = false,
  className,
}) => (
  <Section key={id} className={className}>
    <Container>
      <UIAccordion id={accordionId} title={heading} titleSize={headingSize} {...{ hasLine, allowMultipleExpanded }}>
        {items?.map(({ id: itemId, heading, content, isDefaultExpanded }) => (
          <UIAccordion.Item key={itemId} id={itemId} heading={heading} isExpanded={isDefaultExpanded}>
            {content}
          </UIAccordion.Item>
        ))}
      </UIAccordion>
    </Container>
  </Section>
);
