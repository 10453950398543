type Props = {
  className?: string;
};

const EmailIcon: React.FC<Props> = ({ className }) => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0877 1H1.82797C2.50891 1.58393 3.48874 2.41266 4.59854 3.33321C6.58713 4.98269 8.95513 6.89566 10.6838 8.15842C10.8557 8.28395 11.0955 8.2794 11.271 8.13734L20.0877 1ZM1 1.6069V15H21V1.54804L11.9002 8.91458C11.3823 9.33379 10.6424 9.36656 10.0939 8.96592C8.33875 7.68379 5.95076 5.75408 3.96011 4.10289C2.76719 3.11339 1.70311 2.21247 1 1.6069ZM0 1C0 0.447715 0.447715 0 1 0H21C21.5523 0 22 0.447715 22 1V15C22 15.5523 21.5523 16 21 16H1C0.447716 16 0 15.5523 0 15V1Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailIcon;
