const EnvelopeIcon: React.FC = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.72764 4.85936C1.95534 5.02003 2.64171 5.49724 3.78677 6.29073C4.93187 7.08423 5.8091 7.6952 6.41849 8.12368C6.48544 8.17065 6.62768 8.27274 6.84528 8.43011C7.06291 8.58759 7.24375 8.71484 7.38764 8.81191C7.53164 8.90894 7.70572 9.01779 7.91006 9.13829C8.11432 9.25868 8.30688 9.34924 8.48768 9.40919C8.66851 9.46963 8.83591 9.49957 8.9899 9.49957H9H9.01013C9.16412 9.49957 9.33158 9.4696 9.51242 9.40919C9.69315 9.34924 9.88588 9.25858 10.09 9.13829C10.2942 9.01765 10.4682 8.9089 10.6122 8.81191C10.7562 8.71484 10.9369 8.58759 11.1546 8.43011C11.3722 8.2726 11.5146 8.17065 11.5816 8.12368C12.1975 7.6952 13.7646 6.60699 16.2824 4.85915C16.7712 4.51778 17.1796 4.10587 17.5077 3.6237C17.836 3.14174 18 2.63614 18 2.10718C18 1.66516 17.8409 1.28677 17.5227 0.972071C17.2047 0.657298 16.8279 0.5 16.3928 0.5H1.60708C1.09148 0.5 0.694702 0.67408 0.4168 1.02224C0.138933 1.37047 0 1.80574 0 2.32802C0 2.74988 0.184213 3.20704 0.552462 3.6992C0.920676 4.19139 1.31253 4.57815 1.72764 4.85936Z"
      fill="currentColor"
    />
    <path
      d="M16.9954 5.93244C14.7992 7.41891 13.1316 8.57415 11.9933 9.3979C11.6116 9.67904 11.302 9.8985 11.0642 10.0558C10.8264 10.2131 10.5102 10.3738 10.115 10.5378C9.71998 10.7021 9.35184 10.7839 9.01026 10.7839H9.00002H8.98992C8.64841 10.7839 8.28006 10.7021 7.88503 10.5378C7.49001 10.3738 7.17355 10.2131 6.93583 10.0558C6.69817 9.8985 6.38843 9.67904 6.00678 9.3979C5.10271 8.735 3.43868 7.5797 1.01461 5.93244C0.632816 5.67815 0.294648 5.38667 0 5.05859V13.0338C0 13.476 0.157298 13.8542 0.47207 14.169C0.786772 14.4838 1.16519 14.6412 1.60718 14.6412H16.3929C16.8348 14.6412 17.2132 14.4838 17.5279 14.169C17.8428 13.8541 18 13.4761 18 13.0338V5.05859C17.712 5.37988 17.3773 5.67136 16.9954 5.93244Z"
      fill="currentColor"
    />
  </svg>
);

export default EnvelopeIcon;
