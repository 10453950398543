import { useEffect } from "react";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { TOPICS, Metadata, PageLoad, publishEvent } from "@/events";
import { useWindowEvent, useDocumentEvent, useQueryParams } from "@/hooks";

// Hack to not send a pageview event on the initial page load because we have a separate pageview trigger for that,
// which is being used by the old site and account portal right now so we don't want to remove it.
let initialPageLoadComplete = false;

export const useGlobalAnalyticsEvents = () => {
  // On mount
  useEffect(() => {
    PageLoad.setStartDate();
    Metadata.set(); // Set metadata
    publishEvent(TOPICS.pageLoadStart); // Initial page load start

    const timeout = setTimeout(() => {
      initialPageLoadComplete = true;
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Initial page load finish
  useWindowEvent("load", () => publishEvent(TOPICS.pageLoadFinish));

  // Page load events fired via route change
  const { events } = useRouter();
  useEffect(() => {
    const handleRouteChangeStart = (_path: string) => {
      if (!initialPageLoadComplete) return;
      const path = _path.split("?")[0];
      PageLoad.resetId();
      PageLoad.setStartDate();
      publishEvent(TOPICS.pageLoadStart, { path });
    };

    const handleRouteChangeComplete = (_path: string) => {
      if (!initialPageLoadComplete) return;
      const path = _path.split("?")[0];
      publishEvent(TOPICS.pageLoadFinish, { path });
      const dataLayer = window.dataLayer;
      if (dataLayer) dataLayer.push({ event: "pageview" });
    };

    events.on("routeChangeStart", handleRouteChangeStart);
    events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      events.off("routeChangeStart", handleRouteChangeStart);
      events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [events]);

  // Referral or affiliate click
  const { ref, fp_ref, referral, affiliate } = useQueryParams();
  useEffect(() => {
    const code = affiliate || referral || fp_ref || ref;
    if (code) {
      publishEvent(TOPICS.referralClick, {
        coupon_code: code,
        landing_page: window.location.href,
      });
    }
  }, [ref, fp_ref, referral, affiliate]);

  // Page navigate away
  useWindowEvent("beforeunload", () => publishEvent(TOPICS.pageNavigateAway));

  // Page visibility change
  useDocumentEvent("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      publishEvent(TOPICS.pageVisibilityChangeVisible);
    } else {
      publishEvent(TOPICS.pageVisibilityChangeHidden);
    }
  });

  // Page scroll
  useWindowEvent(
    "scroll",
    debounce(() => {
      Metadata.refreshViewport();
      publishEvent(TOPICS.pageScroll);
    }, 1500),
    true,
  );

  // Page resized
  useWindowEvent(
    "resize",
    debounce(() => {
      Metadata.refreshViewport();
      publishEvent(TOPICS.pageResized);
    }, 1000),
  );
};
