import { Image as DatoImage } from "react-datocms";
import classNames from "classnames";
import Link from "next/link";
import { FixedAspectRatioContainer, ClockIcon, HtmlToElement } from "@/components";
import { ImageType } from "@/types";

type Props = {
  title: string;
  href: string;
  description: string;
  image: ImageType | null;
  duration?: number;
  className?: string;
};

const PreviewCard: React.FC<Props> = ({ title, href, description, image, duration, className }) => (
  <article className={classNames("flex flex-1", className)}>
    <Link href={href}>
      <a className="flex flex-col flex-1 text-center transform transition duration-150 hover:bg-off-white hover:scale-103 hover:shadow-md">
        <FixedAspectRatioContainer aspectRatio={384 / 220} className="border border-current bg-cinder-block">
          {image && <DatoImage data={{ alt: title, ...image.responsiveImage }} />}
          {duration && (
            <div className="absolute bottom-0 right-0 flex items-center p-1.5 bg-off-white">
              <ClockIcon />
              <span className="ml-1.5 text-12 leading-none">{duration}m</span>
            </div>
          )}
        </FixedAspectRatioContainer>

        <div className="flex flex-col flex-1 py-7 px-6">
          <h3 className="text-h3 font-semibold leading-tight mb-4.5">{title}</h3>
          <HtmlToElement>{description}</HtmlToElement>
        </div>
      </a>
    </Link>
  </article>
);

export default PreviewCard;
