import React from "react";
import { Image as DatoImage, ResponsiveImageType as ImageProps } from "react-datocms";
import classNames from "classnames";
import { IMAGE_OBJECT_POSITION } from "@/constants";
import { ProfileBeforeAfterLabel } from "./ProfileBeforeAfterLabel";

type ProfileBeforeAfterStaticProps = {
  className?: string;
  beforeImage: ImageProps;
  afterImage: ImageProps;
  withLabels?: boolean;
};

const OFFSET_RIGHT_PERCENT = 75;

/**
 * Used for devices without a mouse to show a preview of the before and after images in a static way.
 */
const ProfileBeforeAfterStatic = ({
  beforeImage,
  afterImage,
  className,
  withLabels = false,
  ...props
}: ProfileBeforeAfterStaticProps) => {
  return (
    <div
      className={classNames(
        "shimmer-gradient animate-background-shimmer-large absolute overflow-hidden bg-cover bg-center bg-no-repeat top-0 right-0 left-0 bottom-0 text-white",
        className,
      )}
      {...props}
    >
      {/* Divider line */}
      <div className="bg-white absolute top-0 bottom-0 z-10 w-[1px]" style={{ right: `${OFFSET_RIGHT_PERCENT}%` }} />
      {/* After image which is mostly displayed */}
      <div className="absolute top-0 left-0 bottom-0 right-0 z-0">
        <DatoImage
          data={{ ...afterImage }}
          style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, maxWidth: "10000px" }}
          objectFit="cover"
          objectPosition={IMAGE_OBJECT_POSITION}
          usePlaceholder={false}
        />
      </div>
      {/* Before image which is partially displayed */}
      <div className="z-1 absolute top-0 left-0 bottom-0 right-0 overflow-hidden">
        <DatoImage
          data={{ ...beforeImage }}
          objectPosition={IMAGE_OBJECT_POSITION}
          objectFit="cover"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            maxWidth: "10000px",
            clipPath: `inset(0 ${OFFSET_RIGHT_PERCENT}% 0 0)`,
          }}
          usePlaceholder={false}
        />
      </div>
      {withLabels && (
        <>
          <ProfileBeforeAfterLabel before />
          <ProfileBeforeAfterLabel after />
        </>
      )}
    </div>
  );
};

export { ProfileBeforeAfterStatic };
