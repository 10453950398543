import classNames from "classnames";
import Image from "next/image";

interface Props {
  className: string;
}

const PurpleBlush: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames("absolute z-0", className)}>
      <Image src="/images/purple-blush.png" alt="purple blush" layout="fill" />
    </div>
  );
};

export default PurpleBlush;
