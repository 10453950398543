import classNames from "classnames";
import { Header, Footer } from "@/components";
import { Product } from "@/types";

const PreviewBar: React.FC = () => (
  <div className="py-3 text-center bg-magmar">
    <p className="text-white">Preview mode</p>
  </div>
);

interface Props {
  className?: string;
  product?: Product;
  preview?: boolean;
  hideHeader?: boolean;
  siteBanner?: React.ReactNode;
  headerCtaHref?: string;
}

const Layout: React.FC<Props> = ({ children, className, product, preview, hideHeader, siteBanner, headerCtaHref }) => (
  <>
    {siteBanner || null}
    {!hideHeader && <Header product={product} ctaHref={headerCtaHref} />}
    {preview && <PreviewBar />}
    <main className={classNames("flex flex-col flex-grow", className)}>{children}</main>
    <Footer />
  </>
);

export default Layout;
