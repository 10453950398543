const ZoomFaceIcon: React.FC = () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8996 23.191C16.5293 23.191 14.6078 21.2695 14.6078 18.8992C14.6078 16.5289 16.5293 14.6074 18.8996 14.6074C21.2699 14.6074 23.1914 16.5289 23.1914 18.8992C23.1914 21.2695 21.2699 23.191 18.8996 23.191Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <circle
      cx="10.5831"
      cy="6.04775"
      r="5.04775"
      transform="rotate(90 10.5831 6.04775)"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line x1="21.8751" y1="21.9716" x2="26.196" y2="26.2925" stroke="currentColor" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6351 13.5984C13.4283 12.9511 12.0488 12.584 10.5836 12.584C5.8432 12.584 2.00034 16.4266 2 21.1669H0C0.000342213 15.3221 4.73864 10.584 10.5836 10.584C12.833 10.584 14.9186 11.2858 16.6333 12.4824C15.9017 12.7409 15.2275 13.121 14.6351 13.5984Z"
      fill="currentColor"
    />
  </svg>
);

export default ZoomFaceIcon;
