import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { ProfileBeforeAfterSlider } from "@/components/shared/ProfileBeforeAfterSlider";
import { ProfileBeforeAfterStatic } from "@/components/shared/ProfileBeforeAfterStatic";
import { ArtistStyle } from "@/types";
import { AnchorButton } from "@narrative-software/narrative-web-ui";

type EditHeroSectionProps = {
  heroHeadingLine1: string;
  heroHeadingLine2: string;
  heroDescription: string;
  heroCtaButtonText: string;
  heroCtaHref: string;
  artistStyles: ArtistStyle[];
};

function EditHeroSection({
  artistStyles,
  heroHeadingLine1,
  heroHeadingLine2,
  heroDescription,
  heroCtaButtonText,
  heroCtaHref,
}: EditHeroSectionProps) {
  const [selectedStyle, setSelectedStyle] = useState(0);

  return (
    <section className="w-full pt-16 md:pt-38 relative bg-mankey">
      <div className="absolute top-0 right-0 left-0 h-[84%] md:h-[78%] bg-ekans">
        <Image
          src="/images/edit-hero-bg.png"
          alt=""
          objectFit="cover"
          objectPosition="top center"
          className="absolute top-0 right-0 bottom-0 left-0"
          layout="fill"
          quality={100}
          sizes="100vw"
        />
      </div>
      <div className="m-auto max-w-[1050px] w-full text-center relative text-white px-3">
        <div className="md:gap-7.5 gap-4 flex flex-col px-2">
          <h1 className="md:text-90 font-[600] md:leading-[5.8rem] text-5xl leading-[3.25rem]">
            {heroHeadingLine1}
            <br />
            <span className="font-[300]">{heroHeadingLine2}</span>
          </h1>
          <div className="text-lg max-w-160 mx-auto" dangerouslySetInnerHTML={{ __html: heroDescription }} />
          <div className="relative max-w-[385px] w-full mx-auto lg:mb-20 mb-3">
            <AnchorButton colour="white" href={heroCtaHref}>
              {heroCtaButtonText}
            </AnchorButton>
            <div className="absolute h-[94px] w-[204px] left-[calc(100%+14px)] top-[-15px] lg:block hidden">
              <Image
                src="/images/edit-hero-desktop-cta.png"
                alt=""
                objectFit="cover"
                objectPosition="top center"
                className="absolute top-0 right-0 bottom-0 left-0"
                layout="fill"
                quality={100}
                sizes="204px"
              />
            </div>
            <div className="h-[66px] w-[216px] lg:hidden block mx-auto mt-[7px] mb-5">
              <Image
                src="/images/edit-hero-mobile-cta.png"
                alt=""
                quality={100}
                sizes="216px"
                width={216}
                height={66}
              />
            </div>
          </div>
        </div>

        <div className="w-full h-0 pb-[60%] relative bg-off-white max-w-[988px] mx-auto">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              className="absolute top-0 right-0 bottom-0 left-0"
              key={selectedStyle}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ProfileBeforeAfterSlider
                beforeImage={artistStyles[selectedStyle].image1Before.responsiveImage}
                afterImage={artistStyles[selectedStyle].image1After.responsiveImage}
                withLabels
                data-mouse
              />
              <ProfileBeforeAfterStatic
                beforeImage={artistStyles[selectedStyle].image1Before.responsiveImage}
                afterImage={artistStyles[selectedStyle].image1After.responsiveImage}
                withLabels
                data-touch
              />
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="flex mt-5 justify-center flex-wrap">
          {artistStyles.map((style, i) => (
            <button
              key={style.id}
              className={classNames("text-stealth-bomber px-2 md:px-5 py-1 transition-colors", {
                underline: selectedStyle === i,
                "text-shady-lady": selectedStyle !== i,
              })}
              onClick={() => setSelectedStyle(i)}
            >
              {style.name}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

export { EditHeroSection };
