const WhatsAppIcon: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.07288 7.13704C9.28455 7.14573 9.51894 7.15575 9.74175 7.65061C9.89407 7.98941 10.1505 8.62137 10.3547 9.12442C10.5047 9.49407 10.6265 9.79411 10.6579 9.85706C10.7323 10.0057 10.7818 10.1792 10.6827 10.3778C10.668 10.4071 10.6544 10.4348 10.6414 10.4613C10.5668 10.6136 10.5121 10.7254 10.3859 10.8733C10.3359 10.9316 10.2842 10.9945 10.2325 11.0573C10.1304 11.1815 10.0284 11.3055 9.93982 11.394C9.79076 11.5421 9.63591 11.703 9.80926 12.0005C9.98282 12.2979 10.5793 13.2709 11.4629 14.0587C12.4121 14.9052 13.2368 15.2631 13.6556 15.4449C13.7378 15.4806 13.8044 15.5095 13.8532 15.5339C14.1506 15.6826 14.324 15.6578 14.4973 15.4593C14.6709 15.261 15.2402 14.5917 15.4382 14.2942C15.6365 13.9968 15.8346 14.0463 16.1071 14.1454C16.3796 14.2446 17.841 14.9638 18.1382 15.1124C18.1961 15.1413 18.2503 15.1675 18.3005 15.1917C18.5081 15.2918 18.6482 15.3594 18.7081 15.4593C18.7823 15.5836 18.7823 16.1783 18.5346 16.8723C18.287 17.5664 17.0734 18.2359 16.5284 18.2854C16.4759 18.2901 16.4237 18.2963 16.3701 18.3026C15.8663 18.3618 15.2306 18.4365 12.9615 17.5417C10.1686 16.4404 8.32732 13.7109 7.94739 13.1477C7.91631 13.1016 7.895 13.07 7.88376 13.055L7.88014 13.0502C7.7189 12.8345 6.67014 11.4321 6.67014 9.98094C6.67014 8.61209 7.34294 7.89457 7.65253 7.56441C7.67371 7.54182 7.69319 7.52104 7.71064 7.50199C7.98291 7.20455 8.30487 7.13013 8.50317 7.13013L8.5353 7.13013C8.72262 7.13011 8.90891 7.13008 9.07288 7.13704Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07025 23.8512C2.58085 24.2418 1.22726 22.8734 1.63399 21.3883L2.186 19.3728C2.32711 18.8576 2.24879 18.3109 2.01498 17.8306C1.22871 16.2154 0.817989 14.4376 0.818567 12.6223C0.821235 6.06512 6.15746 0.730469 12.7149 0.730469C15.897 0.732021 18.8837 1.97039 21.1301 4.21828C23.3757 6.46618 24.6123 9.45401 24.6112 12.6319C24.6083 19.1886 19.2712 24.5242 12.7149 24.5242H12.7098C10.9855 24.5235 9.28714 24.1488 7.73427 23.4328C7.27125 23.2193 6.75039 23.1484 6.25719 23.2778L4.07025 23.8512ZM12.7187 2.73932C7.2646 2.73932 2.82916 7.17314 2.82716 12.623C2.82635 14.4906 3.349 16.3095 4.3387 17.8834C4.48862 18.1217 4.53164 18.4119 4.45727 18.6834L4.37162 18.9961C4.01755 20.2886 5.19564 21.4797 6.49199 21.1398L6.91086 21.03C7.17086 20.9618 7.44732 21.0005 7.67854 21.1376C9.1963 22.0384 10.9364 22.5147 12.7111 22.5156H12.7148C18.1647 22.5156 22.6001 18.0813 22.6023 12.631C22.6033 9.98986 21.5759 7.50624 19.709 5.638C17.8421 3.76977 15.3598 2.74043 12.7187 2.73932Z"
      fill="currentColor"
    />
  </svg>
);

export default WhatsAppIcon;
