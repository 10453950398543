import React from "react";
import { IPlanObject, ProductInterval } from "@/api";
import { SELECT_PLAN_SLUGS } from "@/config";
import { SelectPlanComparisonItem } from "@/modules/select-plan-comparison/SelectPlanComparisonItem";
import { SelectPlan } from "@/modules/select-plan-comparison/types";
import { mergeCMSPlanWithStripePlan } from "@/modules/select-plan-comparison/utils";
import { Tooltip } from "@narrative-software/narrative-web-ui";
import { SelectPlanWithStripeData } from "../../modules/select-plan-comparison/utils";

interface Props {
  blackFridayPlans: (SelectPlan & { __typename: string })[];
  planHeader: string;
  planDetails: string;
  stripePlans: IPlanObject[];
}

export const getPlanSpecialType = (plan: SelectPlanWithStripeData) => {
  if (!plan.blackFridayPlan) return;

  if (plan.stripePlanSlug === SELECT_PLAN_SLUGS.defaultPro) {
    return "bf-select";
  }

  return "bf-publish";
};

const BlackFridayPlanComparison = React.forwardRef<HTMLDivElement, Props>(
  ({ planHeader, planDetails, blackFridayPlans, stripePlans }, ref) => {
    const plansWithPriceData = mergeCMSPlanWithStripePlan(blackFridayPlans, stripePlans);

    return (
      <div className="md:grid md:grid-cols-12 md:px-0 bf-background-plan-pattern" ref={ref}>
        <div className="col-span-12 lg:col-span-8 md:col-start-1 lg:col-start-2 xl:col-start-3 md:min-w-[915px] max-w-[1400px] mx-5 md:mx-auto">
          <h2 className="font-light text-34 md:text-58 text-center">{planHeader}</h2>
          <p className="text-lg text-center mt-4 md:mt-0">{planDetails}</p>
          <div className="md:space-y-0 space-y-5 md:flex md:space-x-4 mt-10">
            {/* Plan Cards */}
            {plansWithPriceData.map((plan, i) => (
              <SelectPlanComparisonItem
                {...plan}
                key={plan.id}
                position={i}
                billingInterval={ProductInterval.Year}
                mode="normal"
                special={getPlanSpecialType(plan)}
              />
            ))}
            {/* End Plan Cards */}
          </div>
        </div>
        <Tooltip />
      </div>
    );
  },
);

BlackFridayPlanComparison.displayName = "BlackFridayPlanComparison";

export default BlackFridayPlanComparison;
