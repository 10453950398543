import classNames from "classnames";
import {
  Section,
  Container,
  FixedAspectRatioContainer,
  CredibilityBar,
  StartFreeButtons,
  SignUpForm,
} from "@/components";
import { ILogo } from "@/components/shared/CredibilityBar";
import { IS_GOOGLE_AUTH_ENABLED } from "@/config";
import { Product } from "@/types";

type Props = {
  product: Product;
  title: React.ReactNode;
  companyLogosLabel?: string;
  companyLogos?: ILogo[];
  videoSrc: string;
  videoPoster: string;
  videoAspectRatio: number;
  videoLoop?: boolean;
  videoControls?: boolean;
};

const HeroSection: React.FC<Props> = ({
  product,
  title,
  videoSrc,
  videoPoster,
  videoAspectRatio,
  companyLogos,
  companyLogosLabel,
  videoLoop,
  videoControls,
  children,
}) => (
  <Section isFirstSection>
    <Container>
      <div className="flex flex-col items-center text-center lg:flex-row lg:text-left">
        <div className="flex flex-col items-center w-full pb-6 lg:items-start lg:w-1/2 lg:pb-0 lg:pr-22">
          <h1 className="text-h2 font-semibold leading-tight lg:text-h1 lg:leading-compact">{title}</h1>
          <p className="mt-6 mb-7 text-lg">{children}</p>
          {IS_GOOGLE_AUTH_ENABLED ? (
            <StartFreeButtons product={product} emailButtonColour={product === "select" ? "gengar" : "slowpoke"} />
          ) : (
            <SignUpForm product={product} buttonColour={product === "select" ? "gengar" : "slowpoke"} />
          )}
        </div>
        <div className="w-full mt-4 flex-1 lg:w-auto lg:mt-0">
          <FixedAspectRatioContainer
            aspectRatio={videoAspectRatio}
            className={classNames("flex-grow w-full overflow-hidden lg:w-auto", {
              "border border-cinder-block rounded shadow-md": product === "select",
            })}
          >
            <video
              src={videoSrc}
              poster={videoPoster}
              autoPlay
              muted
              playsInline
              loop={videoLoop}
              controls={videoControls}
            />
          </FixedAspectRatioContainer>
          {product === "publish" && (
            <p className="-mt-1 mr-5.5 text-10 text-shady-lady text-right">
              Image credit:{" "}
              <a href="https://georgiaverrells.com" target="_blank" rel="noopener noreferrer" className="underline">
                Georgia Verrels
              </a>
            </p>
          )}
        </div>
      </div>
      {companyLogos ? (
        <CredibilityBar label={companyLogosLabel} logos={companyLogos} className="mt-11 lg:mt-24 lg:-mr-3" />
      ) : null}
    </Container>
  </Section>
);

export default HeroSection;
