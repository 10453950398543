import classNames from "classnames";
import Image, { StaticImageData } from "next/image";
import { Fstoppers, FastCompany, TechCrunch, ShotKit, SlrLounge } from "@/images/logos";

const DEFAULT_COMPANY_LOGOS = [
  { src: Fstoppers, width: 155, height: 42, companyName: "Fstoppers" },
  { src: FastCompany, width: 175, height: 25, companyName: "Fast Company" },
  { src: TechCrunch, width: 182, height: 28, companyName: "TechCrunch" },
  { src: ShotKit, width: 143, height: 41, companyName: "ShotKit" },
  { src: SlrLounge, width: 177, height: 69, companyName: "SLR Lounge" },
];

export interface ILogo {
  src: StaticImageData;
  width: number;
  height: number;
  companyName: string;
  className?: string;
}

const CredibilityBar = ({
  logos = DEFAULT_COMPANY_LOGOS,
  label,
  className,
}: {
  logos?: ILogo[];
  label?: string;
  className?: string;
}) => (
  <div className={classNames("flex flex-col items-center lg:flex-row", className)}>
    {label && <p className="text-base mb-3 lg:mr-10 lg:mb-0 flex-shrink-0">{label}</p>}
    <div className="flex items-center justify-between flex-grow flex-wrap lg:flex-nowrap -m-3">
      {logos.map(({ src, width, height, companyName, className: logoClassName }, i) => (
        <div key={i} className={classNames("flex justify-center flex-grow w-1/2 lg:w-auto p-3", logoClassName)}>
          <Image src={src} alt={companyName} width={width} height={height} className="h-full" placeholder="empty" />
        </div>
      ))}
    </div>
  </div>
);

export default CredibilityBar;
