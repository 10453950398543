import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const JWT_KEY = "narrative-session-token";

const AuthHelper = {
  getToken: () => {
    return Cookies.get(JWT_KEY);
  },

  isAuthToken: () => {
    return !!AuthHelper.getToken();
  },

  getUserId: () => {
    const jwt = AuthHelper.getToken();
    if (jwt) {
      const jwtDecoded = jwt_decode<{ sub: string }>(jwt);
      return jwtDecoded.sub.split(":").pop() || null;
    }
    return null;
  },
};

export default AuthHelper;
