import { useAlert, useAlertDispatch } from "@/providers";
import { Alert, AlertActionType } from "@narrative-software/narrative-web-ui";

const AlertContainer: React.FC = () => {
  const alertDispatch = useAlertDispatch();
  const { type, title, isVisible, children } = useAlert();

  const closeAlert = () => alertDispatch({ type: AlertActionType.Close });

  return (
    <Alert {...{ type, title, isVisible }} onClose={closeAlert}>
      {children}
    </Alert>
  );
};

export default AlertContainer;
