import classNames from "classnames";
import Image from "next/image";
import { Section, Container, FixedAspectRatioContainer, LinkWithArrow } from "@/components";
import { ROUTES } from "@/config";
import CustomerCare from "@/images/customer-care@2x.jpg";
import { Product } from "@/types";
import { RainbowBorder } from "@narrative-software/narrative-web-ui";

const linkMap = {
  select: [
    {
      url: `/${ROUTES.SELECT.LESSONS.SLUG}/quickstart-guide`,
      text: "Quickstart Guide",
      isExternal: false,
    },
    {
      url: "https://help.narrative.so",
      text: "Support articles",
      isExternal: true,
    },
    {
      url: "https://www.facebook.com/groups/narrativephotographycommunity",
      text: "Facebook community",
      isExternal: true,
    },
  ],
  publish: [
    {
      url: "https://help.narrative.so",
      text: "Support articles",
      isExternal: true,
    },
    {
      url: "https://www.facebook.com/groups/narrativephotographycommunity",
      text: "Facebook community",
      isExternal: true,
    },
  ],
};

type Props = {
  product: Product;
  links?: { url: string; text: string; isExternal: boolean }[];
};

const CustomerCareSection: React.FC<Props> = ({ product, links }) => {
  const linksToRender =
    links && links.length > 0 ? links : product in linkMap ? linkMap[product as keyof typeof linkMap] : [];

  return (
    <Section className={classNames({ "bg-white": product === "select" || product === "edit" })}>
      <Container>
        <div className="flex flex-col items-center lg:flex-row">
          <FixedAspectRatioContainer
            aspectRatio={3 / 2}
            className="relative w-full mb-10 flex-grow border border-solid border-current lg:w-auto lg:mb-0"
          >
            <Image src={CustomerCare} alt="Dedicated customer care" layout="fill" placeholder="blur" />
            <RainbowBorder className="absolute left-0 right-0 bottom-0" />
          </FixedAspectRatioContainer>
          <div className="lg:w-5/12 lg:pl-20 xl:pl-30">
            <h2 className="text-h3 font-semibold leading-compact md:text-h2">
              Dedicated
              <br /> customer care.
            </h2>
            <p className="text-lg my-6">
              We have a range of great help resources, as well as dedicated support via email or chat. We’re here to
              help and we’d love to hear from you.
            </p>
            {linksToRender.map(({ url, text, isExternal }) => (
              <p key={`${url}_${text.replaceAll(" ", "")}`}>
                <LinkWithArrow href={url} isExternal={isExternal} isNewTab={isExternal}>
                  {text}
                </LinkWithArrow>
              </p>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default CustomerCareSection;
