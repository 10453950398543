import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { HorizontalRule } from "@/components";
import { Spinner } from "@narrative-software/narrative-web-ui";

type Props = {
  open: boolean;
  progress: number;
  uploadCount: number;
  onCancel(): void;
};

const UploadProgress: React.FC<Props> = ({ open, progress, uploadCount, onCancel }) => {
  const isUploadComplete = progress === 100;
  const isPaymentRequired = uploadCount > 1;

  let heading = `Uploading your ${uploadCount === 1 ? "entry" : `${uploadCount} entries`}`;
  let subheading = "Please keep this tab open";

  if (isUploadComplete) {
    heading = "Upload complete";
    subheading = `Redirecting${isPaymentRequired ? " to payment page" : ""}...`;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-stealth-bomber bg-opacity-95 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative text-white text-left overflow-hidden transform transition-all sm:my-8">
                <h2 className="text-48 leading-none mb-16 lg:text-70 lg:mb-30">{heading}</h2>
                <HorizontalRule className="mb-7" />
                <h3 className="text-32 leading-none mb-10 lg:text-40">{subheading}</h3>
                {isUploadComplete ? (
                  <Spinner size={12} />
                ) : (
                  <div className="relative flex items-center justify-center w-full h-15 border-4 border-gray-700 bg-black">
                    <div
                      className="absolute top-0 left-0 h-full"
                      style={{
                        width: `${progress}%`,
                        background: "linear-gradient(90deg, #B44893 0%, #F16370 57%, #F77B58 98%)",
                      }}
                    />
                    <span className="relative text-24">{progress}%</span>
                  </div>
                )}
                {!isUploadComplete && (
                  <button type="button" className="mt-5 text-11 text-white tracking-tight underline" onClick={onCancel}>
                    Cancel upload
                  </button>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UploadProgress;
