import ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import { FixedAspectRatioContainer } from "@/components";
import { Spinner } from "@narrative-software/narrative-web-ui";

interface Props extends ReactPlayerProps {
  aspectRatio?: number;
  isVideoFile?: boolean;
}

const Video: React.FC<Props> = ({ isVideoFile, aspectRatio = 16 / 9, ...rest }) => {
  const player = (
    <ReactPlayer
      {...rest}
      width="100%"
      height="100%"
      controls
      className="opacity-0"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onReady={(player: any) => {
        // Fade in video when ready
        if (player.wrapper) {
          setTimeout(() => {
            player.wrapper.className = "opacity-100 transition-opacity duration-500";
          }, 500);
        }
      }}
    />
  );

  if (isVideoFile) {
    return player;
  }

  return (
    <FixedAspectRatioContainer {...{ aspectRatio }}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner size={12} />
      </div>
      <div className="relative h-full">{player}</div>
    </FixedAspectRatioContainer>
  );
};

export default Video;
