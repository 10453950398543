import classNames from "classnames";
import { Section, Container, PreviewCard, LinkWithArrow } from "@/components";
import { ILesson } from "@/types";
import { createHref } from "@/utils";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import { KeyboardShortcutsArticle } from "./LessonHolderPage";

type Props = {
  title: string;
  parentHref: string;
  items: ILesson[];
};

const LessonThreeUp: React.FC<Props> = ({ title, parentHref, items }) => (
  <Section className="bg-white">
    <Container>
      <div className="relative text-center">
        <HorizontalRule bottomMargin />
        <h1 className="text-h2 font-semibold leading-tight">{title}</h1>
        <LinkWithArrow href={parentHref} className="mt-4 md:absolute md:bottom-0 md:right-0 md:mt-0">
          View all guides
        </LinkWithArrow>
      </div>

      <div className="mt-8 grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {items.map((item, i) => (
          <PreviewCard
            {...item}
            key={item.id}
            href={createHref(item)}
            className={classNames({ "sm:hidden lg:flex": i === 2 })}
          />
        ))}

        {/* TODO: Temporary external link to help center article */}
        <KeyboardShortcutsArticle />
      </div>
    </Container>
  </Section>
);

export default LessonThreeUp;
