import { Image as DatoImage } from "react-datocms";
import moment from "moment";
import { ImageType } from "@/types";

interface Props {
  name: string;
  date: string;
  image: ImageType | null;
}

const BlogAuthor: React.FC<Props> = ({ name, date, image }) => (
  <div className="flex items-center">
    {image && (
      <div className="relative h-18 w-18 mr-4 rounded-full flex-shrink-0 overflow-hidden">
        <DatoImage data={{ alt: name, ...image.responsiveImage }} />
      </div>
    )}
    <div className="mt-1">
      <p className="mb-0.5 font-semibold">By {name}</p>
      <p>
        <time dateTime={date}>{moment(date).format("dddd, MMMM D, YYYY")}</time>
      </p>
    </div>
  </div>
);

export default BlogAuthor;
