import { ROUTES, EXTERNAL } from "@/config";
import { AuthHelper } from "@/helpers";
import { useMounted } from "@/hooks";
import { Footer as UIFooter } from "@narrative-software/narrative-web-ui";

const items = [
  { href: `/${ROUTES.SELECT.BASE.SLUG}`, label: "Select" },
  { href: `/${ROUTES.EDIT.BASE.SLUG}`, label: "Edit" },
  { href: `/${ROUTES.PUBLISH.BASE.SLUG}`, label: "Publish" },
  { href: EXTERNAL.SUPPORT.HREF, label: "Support", isExternal: true, isNewTab: true },
  { href: `/${ROUTES.ABOUT.SLUG}`, label: "About Us" },
  { href: `/${ROUTES.CAREERS.SLUG}`, label: "Careers" },
  { href: `/${ROUTES.BLOG.SLUG}`, label: "Blog" },
  { href: `/${ROUTES.PARTNERSHIPS.SLUG}`, label: "Partnerships" },
];

const legal = [
  { href: `/${ROUTES.TERMS.SLUG}`, label: "Terms & conditions", shallow: true, isExternal: true },
  { href: `/${ROUTES.PRIVACY.SLUG}`, label: "Privacy policy", shallow: true, isExternal: true },
];

const downloads = [
  { href: EXTERNAL.SELECT.GET_STARTED.HREF, label: "Select", isExternal: true },
  { href: EXTERNAL.EDIT.GET_STARTED.HREF, label: "Edit", isExternal: true },
  { href: EXTERNAL.PUBLISH.GET_STARTED.HREF, label: "Publish", isExternal: true },
];

const Footer: React.FC = () => {
  const isMounted = useMounted();
  const isAuthenticated = AuthHelper.isAuthToken();

  if (isMounted) {
    if (isAuthenticated) {
      downloads[0] = { href: EXTERNAL.SELECT.DOWNLOAD.HREF, label: "Select", isExternal: true };
      downloads[1] = { href: EXTERNAL.EDIT.DOWNLOAD.HREF, label: "Edit", isExternal: true };
      downloads[2] = { href: EXTERNAL.PUBLISH.DOWNLOAD.HREF, label: "Publish", isExternal: true };
    }
  }

  return <UIFooter {...{ items, legal, downloads }} />;
};

export default Footer;
