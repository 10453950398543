/**
 * Regex for URL validation
 */
export const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

/**
 * Capitalize a string
 */
export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Slugify a string for URLs, IDs etc.
 */
export const slugify = (string: string) => string.replace(/\W+/g, "-").toLowerCase();

/**
 * Check if a number is odd
 */
export const isOdd = (n: number) => Math.abs(n % 2) === 1;

/**
 * Check if a number is even
 */
export const isEven = (n: number) => n % 2 === 0;

/**
 * Build a URL
 */
export const createUrl = (baseUrl: string, params?: Record<string, string>) => {
  const url = new URL(baseUrl);
  if (params) {
    url.search = new URLSearchParams(params).toString();
  }
  return url.toString();
};

/**
 * Mock API request
 */
export const mockApiRequest = async (delay = 2000) => {
  return await new Promise<{ ok: boolean; status: number; statusText: string }>((resolve) => {
    setTimeout(() => resolve({ ok: true, status: 200, statusText: "ok" }), delay);
  });
};
