import classNames from "classnames";
import { isEmptyDocument } from "datocms-structured-text-utils";
import { ProductInterval } from "@/api";
import { DownChevronIcon, InfoTooltip } from "@/components";
import { CheckIcon } from "@/components";
import styles from "@/styles/custom.module.css";
import { numberToCurrency } from "@/utils";
import { AnchorButton, Button } from "@narrative-software/narrative-web-ui";
import { StructuredText } from "../structured-text";
import { useToggleOverflow } from "./hooks";
import { SelectPlan } from "./types";

type SelectPlanComparisonItemProps = SelectPlan & {
  position: number;
  billingInterval: ProductInterval;
  yearlyPrice: number;
  monthlyPrice: number;
  stripePlanId: string;
  mode: "normal" | "upgrade" | "downgrade" | "prioritisePro";
  getCtaUrl: (billingInterval: ProductInterval) => string;
  getDiscount: (billingInterval: ProductInterval) => number;
  special?: "bf-publish" | "bf-select";
};

const SelectPlanComparisonItem = ({
  planName,
  description,
  ctaLabel: ctaLabelDefault,
  ctaLabelDowngrade,
  ctaLabelUpgrade,
  mode,
  features,
  position,
  billingInterval,
  yearlyPrice,
  monthlyPrice,
  getCtaUrl,
  stripePlanSlug,
  special,
  getDiscount,
  stripeCouponCode,
}: SelectPlanComparisonItemProps) => {
  const { ref: overflowRef, ...featureOverflow } = useToggleOverflow();
  const isFree = yearlyPrice === 0 && monthlyPrice === 0;
  // This is used primarily for adding "rainbow" styling to the plan card
  const hasProminentCardStyle =
    (mode === "normal" && isFree) ||
    (!isFree && ["upgrade", "downgrade"].includes(mode)) ||
    (!isFree && mode === "prioritisePro");
  const hasDisabledCta = (mode === "downgrade" && !isFree) || (mode === "upgrade" && isFree);

  const ctaLabel = {
    normal: ctaLabelDefault,
    upgrade: ctaLabelUpgrade,
    downgrade: ctaLabelDowngrade,
    prioritisePro: ctaLabelDefault,
  }[mode];

  const renderPriceWithDiscount = (discount: number) => {
    const priceWithDiscountDollars =
      (billingInterval === ProductInterval.Month ? monthlyPrice : yearlyPrice) * discount;

    if ((priceWithDiscountDollars / 100) % 2 === 0) return numberToCurrency(priceWithDiscountDollars, true);
    const [dollars, cents] = (priceWithDiscountDollars / 100).toFixed(2).split(".");
    return (
      <>
        <span className="tracking-[-5px]">{dollars}</span>
        <span className="text-34">.{cents}</span>
      </>
    );
  };

  const discount = getDiscount(billingInterval);

  return (
    <div
      id={`${stripePlanSlug}`}
      className={classNames("md:col-span-5 w-full flex flex-col lg:flex-1 relative", styles["scroll-snap-child"], {
        "bg-stealth-bomber text-white": !!special,
        "bg-white": !special,
        "md:col-start-2": position === 0,
        "bg-gradient-to-r from-[#31256F] to-[#FCB76F] via-[#B44893]": !special,
        "lifetime-box-gradient-select": special === "bf-select",
        "bg-slowpoke": special === "bf-publish",

        // Reveal the gradient background
        "px-[1px] pb-[1px] pt-2": hasProminentCardStyle && !special,
        "p-[2px]": hasProminentCardStyle || !!special,
        "border-1": !hasProminentCardStyle && !special,
      })}
    >
      {!!discount && (
        <div className="absolute right-0 top-0 bg-[#FFDA18] text-stealth-bomber py-2 px-4 rounded-bl-lg font-semibold font-18">
          Save <span className="font-light">$</span>
          {(yearlyPrice * discount * 12) / 100}
        </div>
      )}

      <div
        className={classNames("bg-white pb-10 md:pb-13 flex flex-col h-full", {
          "pt-[29px]": hasProminentCardStyle,
          "pt-9": !hasProminentCardStyle,
          "select-plan-background-gradient": !!special,
          "bg-white": !special,
        })}
      >
        {/* Header */}
        <div
          className={classNames("flex flex-col items-center px-10 pt-6 pb-6 border-b-1 text-center min-h-[210px]", {
            "border-gumboot": !!special,
          })}
        >
          <h3 className={classNames("relative mb-2.5 text-h3 leading-none md:text-34 font-semibold")}>{planName}</h3>
          <div className="relative text-6xl font-semibold leading-none">
            <sup className="absolute mt-7 -ml-4 text-2xl font-light">$</sup>
            {discount
              ? renderPriceWithDiscount(discount)
              : numberToCurrency(billingInterval === ProductInterval.Month ? monthlyPrice : yearlyPrice, true)}
            {!!discount && (
              <div className="absolute bottom-[15%] w-[100px] left-[95%]">
                <p className="text-shadyLady text-[15px]">
                  usually{" "}
                  <span className="strikethrough">
                    {numberToCurrency(billingInterval === ProductInterval.Month ? monthlyPrice : yearlyPrice)}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="mt-1 leading-relaxed">
            {isEmptyDocument(description) ? (
              <>
                USD per month, billed&nbsp;
                {billingInterval === ProductInterval.Month ? "monthly" : "yearly"}
              </>
            ) : (
              <StructuredText data={description} />
            )}
          </div>
        </div>
        {/* End Header */}
        {/* Features */}
        <div className="px-4 md:px-8 pt-8 relative">
          <div
            ref={overflowRef}
            className={classNames("transition-[max-height] overflow-hidden", {
              "max-h-[440px] md:max-h-[310px]": !featureOverflow.isOpen,
              "max-h-[600px]": featureOverflow.isOpen,
            })}
          >
            <ul>
              {features.map((feature) => (
                <li key={feature.id} className="text-sm flex items-center justify-between mb-2">
                  <div className="flex items-center md:mr-5">
                    <div
                      className={classNames("mr-5", {
                        "text-slowpoke": special === "bf-publish",
                        "text-gengar": special !== "bf-publish",
                      })}
                    >
                      <CheckIcon />
                    </div>
                    <StructuredText data={feature.name} />
                  </div>
                  {feature.description && <InfoTooltip description={feature.description} special={special} />}
                </li>
              ))}
            </ul>
          </div>
          {featureOverflow.hasOverflow && (
            <>
              {!featureOverflow.isOpen && !special && (
                <div className="h-[66px] w-full left-0 bottom-[30px] bg-gradient-to-t from-white to-transparent absolute"></div>
              )}
              <button
                className="text-sm py-2 flex items-center w-[60px] justify-between"
                onClick={() => featureOverflow.toggle()}
              >
                {featureOverflow.isOpen ? "Less" : "More"}
                <DownChevronIcon className={classNames("origin-center", { "rotate-180": featureOverflow.isOpen })} />
              </button>
            </>
          )}
        </div>
        {/* End Features */}
        {/* CTA */}
        <div className="px-4 md:px-8 mt-auto">
          {hasDisabledCta ? (
            <Button appearance="primary" colour="gray-600" disabled className="text-sm normal-case">
              {ctaLabel}
            </Button>
          ) : (
            <AnchorButton
              href={getCtaUrl(billingInterval)}
              appearance={hasProminentCardStyle ? "rainbow" : "secondary"}
              colour="gray-600"
              className={classNames("text-sm normal-case", {
                "lifetime-background-gradient-publish border-none !text-white": special === "bf-publish",
                "lifetime-background-gradient-select border-none !text-white": special === "bf-select",
              })}
            >
              {ctaLabel}
            </AnchorButton>
          )}
        </div>
        {/* End CTA */}
      </div>
    </div>
  );
};

export { SelectPlanComparisonItem };
