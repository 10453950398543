import { HorizontalRule } from "@narrative-software/narrative-web-ui";

type EditStylesHeroSectionProps = {
  title: string;
  description: string;
};

function EditStylesHeroSection({ title, description }: EditStylesHeroSectionProps) {
  return (
    <section className="py-[80px] md:py-[120px] bg-mankey">
      <div className="max-w-[820px] px-5 mx-auto">
        <h1 className="text-h1 font-semibold leading-compact mb-10 text-center">{title}</h1>
        <HorizontalRule />
        <p className="mt-10">{description}</p>
      </div>
    </section>
  );
}

export { EditStylesHeroSection };
