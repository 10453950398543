import { Video } from "@/components";
import { formatImageData } from "@/config";
import { ImageType } from "@/types";
import { Spinner, RainbowBorder } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface MediaBannerFragmentType {
  _modelApiKey: typeof MODULE_KEYS.mediaBanner;
  id: string;
  image?: ImageType;
  isVideo?: boolean;
  isVideoFile?: boolean;
  hasColourFrame?: boolean;
  videoUrl?: {
    url: string;
    width: number;
    height: number;
  };
  videoFile?: {
    url: string;
    width: number;
    height: number;
    video: {
      streamingUrl: string;
    };
  };
  isSection?: undefined;
}

// Fragment
export const mediaBannerFragment = `
  fragment mediaBannerFragment on ModuleImageBannerRecord {
    _modelApiKey
    id
    isVideo
    isVideoFile
    hasColourFrame
    videoUrl {
      url
      width
      height
    }
    videoFile {
      url
      width
      height
      video {
        streamingUrl
      }
    }
    image {
      url
      responsiveImage(imgixParams: { auto: format }) {
        ...responsiveImageFragment
      }
    }
  }
`;

export const MediaBanner: React.FC<MediaBannerFragmentType> = ({
  id,
  image,
  videoUrl,
  videoFile,
  isVideo,
  isVideoFile,
  hasColourFrame,
}) => {
  const border = hasColourFrame ? <RainbowBorder thin className="absolute left-0 right-0 bottom-0" /> : null;

  if (isVideo) {
    let url;
    let aspectRatio;

    if (videoUrl) {
      const { width, height } = videoUrl;
      url = videoUrl.url;
      aspectRatio = width / height;
    }

    if (isVideoFile && videoFile) {
      const { width, height } = videoFile;
      url = videoFile.video.streamingUrl;
      aspectRatio = width / height;
    }

    if (!url) return null;

    return (
      <section key={id} className="relative">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner size={12} />
        </div>
        <Video {...{ url, aspectRatio, isVideoFile }} />
        {border}
      </section>
    );
  }

  if (image) {
    const imageUrl = formatImageData({ image }).src;
    return (
      <section
        key={id}
        className="relative h-95 bg-cover bg-center bg-no-repeat md:h-110 lg:h-160 xl:h-200"
        style={{ backgroundImage: `url('${imageUrl}')` }}
      >
        {border}
      </section>
    );
  }

  return null;
};
