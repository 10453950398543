import { Link as ScrollLink } from "react-scroll";
import Link from "next/link";
import { slugify } from "@/utils";
import { AnchorButton } from "@narrative-software/narrative-web-ui";
import styles from "../../styles/custom.module.css";

type NavItem = {
  id: string;
  title: string;
};

type NextPage = {
  title: string;
  path: string;
};

type Props = {
  title: string;
  items: NavItem[];
  nextPage?: NextPage;
};

const StickySideNav: React.FC<Props> = ({ title, items, nextPage }) => (
  <nav className="top-28 pt-7 border-t-4 border-current lg:sticky">
    <h5 className="text-h5 font-semibold leading-tight mb-3.5">{title}</h5>
    <ul className="max-h-30vh pl-4 overflow-y-auto overscroll-contain lg:max-h-70vh">
      {items.map(({ id, title }) => (
        <li key={id}>
          <ScrollLink
            to={`${slugify(title)}`}
            className="relative block py-1.5 underline cursor-pointer transition-colors duration-200 hover:text-gray-600"
            activeClass={styles.active}
            duration={500}
            offset={-110}
            smooth
            spy
          >
            {title}
          </ScrollLink>
        </li>
      ))}
    </ul>
    {nextPage && (
      <div className="mt-6">
        <Link href={nextPage.path} passHref>
          <AnchorButton colour="black">Next: {nextPage.title}</AnchorButton>
        </Link>
      </div>
    )}
  </nav>
);

export default StickySideNav;
