import Image from "next/image";
import { Section, Container } from "@/components";
import Edit from "@/images/workflow/edit@2x.png";
import PostLogo from "@/images/workflow/post-logo@2x.png";
import PostScreenshot from "@/images/workflow/post-screenshot@2x.png";
import PublishScreenshot from "@/images/workflow/publish-screenshot@2x.png";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

const PublishWorkflowSection: React.FC = () => (
  <Section className="bg-white">
    <Container>
      <HorizontalRule bottomMargin />
      <h2 className="text-h2 font-semibold leading-compact text-center mb-14 lg:mb-16">
        How Publish fits into your workflow
      </h2>

      <div className="flex flex-col items-center lg:flex-row lg:items-start lg:justify-between">
        {/* Edit */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image src={Edit} alt="Edit in Lightroom" width="258" height="170" placeholder="blur" />
          <div className="flex absolute top-0 mt-35">
            <Image src="/images/workflow/lightroom-icon.svg" alt="" width="93" height="42" unoptimized />
          </div>
          <h3 className="mt-8 text-h3 font-semibold">Edit</h3>
          <p className="mt-3 lg:px-4 xl:px-15">Edit images in Lightroom.</p>
        </div>

        <div className="hidden mt-19 lg:flex">
          <Image src="/images/arrow-right.svg" alt="" width="47" height="16" unoptimized />
        </div>

        {/* Publish */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image
            src={PublishScreenshot}
            alt="Build your blog with Publish"
            width="267"
            height="170"
            placeholder="blur"
          />
          <h3 className="mt-3 text-h3 font-semibold lg:mt-8">Publish</h3>
          <p className="mt-3 lg:px-4 xl:px-15">
            Then, build your blog using <strong>Publish</strong> in minutes.
          </p>
        </div>

        <div className="hidden mt-19 lg:flex">
          <Image src="/images/arrow-right.svg" alt="" width="47" height="16" unoptimized />
        </div>

        {/* Post */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image
            src={PostScreenshot}
            alt="Posts load lightning-fast on every device"
            width="267"
            height="170"
            placeholder="blur"
          />
          <div className="flex absolute top-0 mt-35">
            <Image src={PostLogo} alt="" width="187" height="40" placeholder="blur" />
          </div>
          <h3 className="mt-8 text-h3 font-semibold">Post</h3>
          <p className="mt-3 lg:px-4 xl:px-15">
            Once published to your website your posts load lightning-fast on every device.
          </p>
        </div>
      </div>
    </Container>
  </Section>
);

export default PublishWorkflowSection;
