import React, { ReactNode, useCallback, useRef } from "react";
import Slider, { Settings } from "react-slick";
import classNames from "classnames";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ArtistStyle } from "@/types";
import { ArtistStylesCarouselSlide } from "./ArtistStylesCarouselSlide";

const SLIDE_WIDTH = 182;
const SLIDE_GAP = 20;

type ArtistStylesCarouselProps = {
  artistStyles: ArtistStyle[];
  selectedSlideIndex: number;
  onSlideChange: (i: number) => void;
  className?: string;
};

function ArtistStylesCarousel({
  artistStyles,
  onSlideChange,
  selectedSlideIndex,
  className,
}: ArtistStylesCarouselProps) {
  const sliderRef = useRef(null);

  const settings: Settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    arrows: false,
    variableWidth: true,
    beforeChange: (_, i) => onSlideChange(i),
    initialSlide: 0,
  };

  const goToSlide = useCallback((i: number) => {
    if (sliderRef.current) {
      (sliderRef.current as Slider).slickGoTo(i);
    }
  }, []);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .slick-track {
              display: flex;
            }
            .slick-slide {
              width: ${SLIDE_WIDTH + SLIDE_GAP}px !important;
              min-width: ${SLIDE_WIDTH + SLIDE_GAP}px !important;
              max-width: ${SLIDE_WIDTH + SLIDE_GAP}px !important;
            }
          `,
        }}
      />
      {/* TODO: Bump this max width back up to 1740px once we have more styles */}
      <div className={classNames("max-w-[1740px] w-full m-auto mb-[10px]", className)}>
        <div className="slider-container relative">
          <Slider {...settings} ref={sliderRef}>
            {artistStyles.map((style, i) => {
              return (
                <ArtistStylesCarouselSlide
                  key={style.id}
                  artistStyle={style}
                  selected={i === selectedSlideIndex}
                  onClick={() => goToSlide(i)}
                />
              );
            })}
          </Slider>
          <OpaqueMask
            left
            onClick={() => {
              if (sliderRef.current) {
                (sliderRef.current as Slider).slickPrev();
              }
            }}
          >
            <CarouselArrow left />
          </OpaqueMask>
          <OpaqueMask
            right
            onClick={() => {
              if (sliderRef.current) {
                (sliderRef.current as Slider).slickNext();
              }
            }}
          >
            <CarouselArrow right />
          </OpaqueMask>
        </div>
      </div>
    </>
  );
}

type OpaqueMaskProps =
  | { left: true; right?: false; children?: ReactNode; onClick: () => void }
  | { left?: false; right: true; children?: ReactNode; onClick: () => void };

function OpaqueMask({ children, left, right, onClick }: OpaqueMaskProps) {
  return (
    <button
      data-opaque-mask
      className={classNames("absolute top-0 bottom-0 sm:w-[184px] w-[calc((100vw-184px)/2)]", {
        "left-0": left,
        "right-0": right,
      })}
      style={{
        background: right
          ? "linear-gradient(90deg, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 100%)"
          : "linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(250,250,250,0) 100%)",
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

type CarouselArrowProps = { left: true; right?: false } | { left?: false; right: true };

function CarouselArrow({ left, right }: CarouselArrowProps) {
  return (
    <div
      className={classNames("absolute top-[30%] py-2 px-5", {
        "left-0": left,
        "right-0": right,
        "rotate-180": right,
      })}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="53" height="12" viewBox="0 0 53 12" fill="none">
        <path d="M52.1162 6L2.11621 6" stroke="#1C1C1C" strokeWidth="2" />
        <path d="M7.03809 1L2 6.03809L7.03809 11.0762" stroke="#1C1C1C" strokeWidth="2" />
      </svg>
    </div>
  );
}

export { ArtistStylesCarousel };
