export const TOPICS = {
  pageLoadStart: "web_page_load_start",
  pageLoadFinish: "web_page_load_finish",
  pageNavigateAway: "web_page_navigate_away",
  pageVisibilityChangeHidden: "web_page_visibility_change_hidden",
  pageVisibilityChangeVisible: "web_page_visibility_change_visible",
  pageScroll: "web_page_scroll",
  pageResized: "web_page_resized",
  downloadStartedAutomatically: "web_download_started_automatically",
  downloadStartedManually: "web_download_started_manually",
  referralClick: "web_affiliate_referral",
  emailLead: "web_lead_email_captured",
  chatOpened: "web_support_chat_opened",
  chatClosed: "web_support_chat_closed",
};
