import classNames from "classnames";

interface Props {
  className?: string;
}

const Container: React.FC<Props> = ({ children, className }) => (
  <div className={classNames("container mx-auto px-5 sm:px-6 lg:px-8", className)}>{children}</div>
);

export default Container;
