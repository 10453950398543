import { ReactNode } from "react";
import classNames from "classnames";
import Image from "next/image";
import { Section, Container } from "@/components";
import Cull from "@/images/workflow/select/cull@2x.png";
import Edit from "@/images/workflow/select/edit@2x.png";
import Import from "@/images/workflow/select/import@2x.png";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

type Step = { title: string; description: ReactNode };

type NarrativeWorkflowSectionProps = {
  title: string;
  className?: string;
  steps: [Step, Step, Step];
};

/**
 * @deprecated use WorkflowSteps and the Workflow Steps block instead
 */
const NarrativeWorkflowSection: React.FC<NarrativeWorkflowSectionProps> = ({ title, steps, className }) => (
  <Section className={classNames("bg-white", className)}>
    <Container>
      <HorizontalRule bottomMargin />
      <h2 className="text-h2 font-semibold leading-compact text-center mb-14 lg:mb-16">{title}</h2>

      <div className="flex flex-col items-center lg:flex-row lg:items-start lg:justify-between">
        {/* Import */}
        <div className="relative flex flex-col flex-1 items-center text-center">
          <Image src={Import} alt="Import your images" width="260" height="172" placeholder="blur" />
          <div className="flex absolute top-0 right-1/2 -mt-3.5 mr-25 w-[86px] h-[109px]">
            <Image src="/images/workflow/select/folder-icon.svg" alt="" width="86" height="109" unoptimized />
          </div>
          <h3 className="mt-3 text-h3 font-semibold lg:mt-8">{steps[0].title}</h3>
          <p className="mt-3 lg:px-8">{steps[0].description}</p>
        </div>

        <div className="hidden mt-19 lg:flex">
          <Image src="/images/arrow-right.svg" alt="" width="47" height="16" unoptimized />
        </div>

        {/* Cull */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image src={Cull} alt="Cull your images" width="260" height="172" placeholder="blur" />
          <h3 className="mt-3 text-h3 font-semibold lg:mt-8">{steps[1].title}</h3>
          <p className="mt-3 lg:px-4 xl:px-15">{steps[1].description}</p>
        </div>

        <div className="hidden mt-19 lg:flex">
          <Image src="/images/arrow-right.svg" alt="" width="47" height="16" unoptimized />
        </div>

        {/* Edit */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image src={Edit} alt="Import to Lightroom" width="260" height="172" placeholder="blur" />
          <div className="flex absolute top-0 mt-35">
            <Image src="/images/workflow/select/lightroom-icon.svg" alt="" width="93" height="42" unoptimized />
          </div>
          <h3 className="mt-8 text-h3 font-semibold">{steps[2].title}</h3>
          <p className="mt-3 lg:px-10 xl:px-20">{steps[2].description}</p>
        </div>
      </div>
    </Container>
  </Section>
);

export default NarrativeWorkflowSection;
