import { renderMetaTags, useQuerySubscription } from "react-datocms";
import { GetStaticProps } from "next";
import Image from "next/image";
import { Api } from "@/api";
import { Layout, SEO, Section, Container, PreviewCard, FixedAspectRatioContainer, HtmlToElement } from "@/components";
import { DATOCMS_API_TOKEN, EXTERNAL, PAGE_RECORDS } from "@/config";
import { metaTagsFragment, responsiveImageFragment } from "@/modules";
import { Product, Subscription, ILesson, ILessonHolder, Maybe } from "@/types";
import { capitalize, createHref } from "@/utils";
import { AnchorButton, PromoBanner, HorizontalRule } from "@narrative-software/narrative-web-ui";

// TODO: Temporary external link to help center article
export const KeyboardShortcutsArticle: React.FC = () => (
  <article className="flex flex-1">
    <a
      href="https://help.narrative.so/en/articles/7337364-select-keyboard-shortcuts"
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-col flex-1 text-center transform transition duration-150 hover:bg-off-white hover:scale-102 hover:shadow-md"
    >
      <FixedAspectRatioContainer aspectRatio={384 / 220}>
        <Image src="/images/keyboard-shortcuts@2x.jpg" alt="Keyboard shortcuts" width="384" height="220" />
      </FixedAspectRatioContainer>
      <div className="flex flex-col flex-1 py-7 px-6">
        <h3 className="text-h3 font-semibold leading-tight mb-4.5">Keyboard Shortcuts</h3>
        <p>
          We highly recommend you learn Select’s keyboard shortcuts to speed up your culling and make your life easier.
        </p>
      </div>
    </a>
  </article>
);

interface ILessonHolderPage {
  page: Maybe<ILessonHolder>;
  lessons: ILesson[];
}

type Props = {
  product: Product;
  preview: boolean;
  subscription: Subscription<ILessonHolderPage>;
};

export const LessonHolderPage: React.FC<Props> = ({ product, preview, subscription }) => {
  const { data } = useQuerySubscription(subscription);
  if (!data?.page) return null;

  const {
    lessons,
    page: { seo, title, subheading },
  } = data;

  return (
    <Layout product={product} preview={preview}>
      <SEO title={`${title} | Narrative`}>{renderMetaTags(seo)}</SEO>

      <Section isFirstSection>
        <Container>
          <div className="flex flex-col items-center mb-12 text-center">
            <h1 className="text-h2 font-semibold leading-tight lg:text-h1">{title}</h1>
            <HorizontalRule topMargin bottomMargin={!!subheading} />
            {subheading && <HtmlToElement className="max-w-200 lg:text-lg">{subheading}</HtmlToElement>}
          </div>

          {lessons.length ? (
            <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {lessons.map((lesson) => (
                <PreviewCard {...lesson} key={lesson.id} href={createHref(lesson)} />
              ))}

              {/* TODO: Temporary external link to help center article */}
              {product === "select" && <KeyboardShortcutsArticle />}
            </div>
          ) : (
            <p className="text-center">There are currently no {capitalize(product)} lessons.</p>
          )}
        </Container>
      </Section>

      <PromoBanner
        title="Need more help?"
        backgroundColour={product === "select" ? "gengar" : "slowpoke"}
        image={
          <Image src="/images/photographer-male-back-question-mark.svg" alt="" width="220" height="320" unoptimized />
        }
      >
        <AnchorButton href={EXTERNAL.SUPPORT.HREF} colour="black" external>
          Visit support
        </AnchorButton>
      </PromoBanner>
    </Layout>
  );
};

/**
 * Static props
 */
export const getStaticProps: (product: Product) => GetStaticProps<Props> =
  (product) =>
  async ({ preview = false }) => {
    const graphqlRequest = {
      preview,
      variables: {},
      query: `
      {
        pages: allPageLessonHolders {
          title
          subheading
          seo: _seoMetaTags {
            ...metaTagsFragment
          }
          parentPage {
            ... on ${PAGE_RECORDS.select} {
              slug
            }
            ... on ${PAGE_RECORDS.publish} {
              slug
            }
          }
        }

        lessons: allPageLessons(orderBy: position_ASC) {
          id
          title
          slug
          description
          duration
          position
          image {
            responsiveImage(imgixParams: { auto: format, fit: crop, w: 780, h: 446 }) {
              ...responsiveImageFragment
            }
          }
          parentPage {
            slug
            parentPage {
              ... on ${PAGE_RECORDS.select} {
                slug
              }
              ... on ${PAGE_RECORDS.publish} {
                slug
              }
            }
          }
        }
      }
      ${metaTagsFragment}
      ${responsiveImageFragment}
    `,
    };

    const {
      data: {
        data: { pages, lessons },
      },
    } = await Api.datocmsGraphqlRequest<{ pages: ILessonHolder[]; lessons: ILesson[] }>(graphqlRequest);

    // Remove pages and lessons that don’t match the product
    const initialData = {
      page: pages.find((page) => page.parentPage.slug === product),
      lessons: lessons.filter((lesson) => lesson.parentPage.parentPage.slug === product),
    };

    return {
      props: {
        product,
        preview,
        subscription: preview
          ? {
              ...graphqlRequest,
              initialData,
              token: DATOCMS_API_TOKEN,
            }
          : {
              initialData,
              enabled: false,
            },
      },
    };
  };
