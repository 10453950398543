const GlobeIcon: React.FC = () => (
  <svg width="36" height="40" viewBox="0 0 36 40" fill="none">
    <path
      d="M32.3411 1L29.3302 4.01091C36.3215 11.0022 36.3215 22.3374 29.3302 29.3287C22.3388 36.3201 11.0037 36.3201 4.01234 29.3287L1 32.3411M16.9993 35V40M11.9993 39H21.9993M30.9993 16.5C30.9993 24.5081 24.5074 31 16.4993 31C8.49116 31 1.99929 24.5081 1.99929 16.5C1.99929 8.49187 8.49116 2 16.4993 2C24.5074 2 30.9993 8.49187 30.9993 16.5Z"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
  </svg>
);

export default GlobeIcon;
