import { scroller } from "react-scroll";
import Link from "next/link";
import { LinkWithArrow } from "@/components";
import { PageFragmentType, pageFields } from "@/config";
import { createHref } from "@/utils";
import { Button, AnchorButton, ButtonColour, ButtonAppearance } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface ButtonFragmentType {
  _modelApiKey: typeof MODULE_KEYS.button;
  id: string;
  label: string;
  page: PageFragmentType;
  anchorId: string;
  url: string;
  isAnchor: boolean;
  external: boolean;
  newTab: boolean;
  arrowLink: boolean;
  colour?: ButtonColour;
  appearance?: ButtonAppearance;
  isSection?: undefined;
}

// Fields
export const buttonFields = `
  id
  label
  anchorId
  url
  isAnchor
  external
  newTab
  arrowLink
  colour
  appearance
  page {
    ${pageFields}
  }
`;

// Fragment
export const buttonFragment = `
  fragment buttonFragment on LinkRecord {
    ${buttonFields}
  }
`;

export const DatoButton: React.FC<ButtonFragmentType> = ({
  id,
  label,
  page,
  anchorId,
  url,
  isAnchor,
  external,
  newTab,
  arrowLink,
  colour = "black",
  appearance = "primary",
  ...rest
}) => {
  switch (true) {
    // Anchor
    case isAnchor: {
      const handleClick = () => {
        scroller.scrollTo(anchorId, {
          duration: 600,
          offset: -80,
          smooth: "easeInOut",
        });
      };
      return (
        <Button key={id} onClick={handleClick} {...{ colour, appearance, ...rest }}>
          {label}
        </Button>
      );
    }

    // External
    case external: {
      if (arrowLink) {
        return (
          <LinkWithArrow key={id} href={url} isExternal isNewTab={newTab}>
            {label}
          </LinkWithArrow>
        );
      }
      return (
        <AnchorButton key={id} href={url} external={newTab} {...{ colour, appearance, ...rest }}>
          {label}
        </AnchorButton>
      );
    }

    // Internal
    default: {
      const path = createHref(page);
      if (arrowLink) {
        return (
          <LinkWithArrow key={id} href={path}>
            {label}
          </LinkWithArrow>
        );
      }
      return (
        <Link key={id} href={path} passHref>
          <AnchorButton {...{ colour, appearance, ...rest }}>{label}</AnchorButton>
        </Link>
      );
    }
  }
};
