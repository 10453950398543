import { Image as DatoImage } from "react-datocms";
import { Section, Container } from "@/components";
import { formatImageData } from "@/config";
import { ImageType } from "@/types";
import { ThreeUp as UIThreeUp } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface ThreeUpFragmentType {
  _modelApiKey: typeof MODULE_KEYS.threeUp;
  id: string;
  items: ThreeUpItemFragmentType[];
  heading?: string;
  subheading?: string;
  textAlign?: "left" | "center" | "right";
  textSize?: "base" | "large" | "extra large";
  layout?: "tight" | "loose";
  columns?: 2 | 3;
  hideLine?: boolean;
  disableImageHeight?: boolean;
  isSection?: boolean;
}

export interface ThreeUpItemFragmentType {
  id: string;
  title: string;
  description: string;
  image?: ImageType;
}

// Fragment
export const threeUpFragment = `
  fragment threeUpFragment on ModuleThreeUpRecord {
    _modelApiKey
    id
    heading
    subheading
    textAlign
    textSize
    layout
    columns
    hideLine
    disableImageHeight
    isSection
    items {
      id
      title
      description
      image {
        url
        alt
        width
        height
        responsiveImage(imgixParams: { auto: format }) {
          ...responsiveImageFragment
        }
      }
    }
  }
`;

export const ThreeUp: React.FC<ThreeUpFragmentType> = ({
  id,
  heading,
  subheading,
  textAlign,
  items,
  isSection,
  ...rest
}) => {
  const Component = (
    <UIThreeUp key={id} title={heading} subTitle={subheading} titleAlign={textAlign} {...rest}>
      {items.map(({ id: itemId, title, description, image }) => {
        const imageData = image && formatImageData({ image, removeBase64: false });
        return (
          <UIThreeUp.Item
            key={itemId}
            title={title}
            imageHeight={imageData?.height || undefined}
            image={imageData && <DatoImage data={imageData} priority />}
          >
            {description}
          </UIThreeUp.Item>
        );
      })}
    </UIThreeUp>
  );

  if (!isSection) return Component;

  return (
    <Section key={`section-${id}`}>
      <Container>{Component}</Container>
    </Section>
  );
};
