import Link from "next/link";

export type LogoProps = {
  href: string;
  isExternal?: boolean;
};

const Logo: React.FC<LogoProps> = ({ href, isExternal }) => {
  const Anchor = (
    <a
      href={href}
      className="group flex flex-shrink-0 items-center text-white text-15 leading-[1.15] cursor-pointer lg:text-19"
    >
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        className="duration-300 ease-out group-hover:rotate-180"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.22975e-07 3L-1.56082e-06 21L3 21L3 3L12 3L12 15L15 15L15 3L15 1.54243e-06L12 1.23395e-06L3 3.08487e-07L0 0L-2.22975e-07 3ZM21 2.1594e-06L18 1.85092e-06L18 18L9 18L9 6L6 6L6 21L9 21L18 21L21 21L21 18L21 2.1594e-06Z"
          fill="white"
        />
      </svg>
      <span className="mt-1 ml-3">
        <strong className="font-semibold">Narrative</strong> International
        <br className="lg:hidden" /> Photography Awards
      </span>
    </a>
  );

  return isExternal ? Anchor : <Link href={href}>{Anchor}</Link>;
};

export default Logo;
