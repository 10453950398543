import { ProductInterval } from "@/api";

const BILLING_INTERVALS_WITH_LABELS = [
  {
    value: ProductInterval.Year,
    label: "Billed yearly (save 25%)",
  },
  {
    value: ProductInterval.Month,
    label: "Billed monthly",
  },
];

export { BILLING_INTERVALS_WITH_LABELS };
