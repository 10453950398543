import Bowser from "bowser";

export type UserCurrentMachine = "mobile" | "tablet" | "macos" | "windows" | "linux" | "chrome" | "";

/**
 * Is the device a browser
 */
export const isBrowser = () => typeof window !== "undefined";

/**
 * Is the device operating system iPadOS
 */
export const isIPadOS = () =>
  isBrowser() &&
  !!window.navigator.userAgent.match(/Macintosh/) &&
  !!window.navigator.maxTouchPoints &&
  window.navigator.maxTouchPoints > 2;

/**
 * Get browser detection
 */
export const browserDetection = () => {
  const parser = isBrowser() && Bowser.getParser(window.navigator.userAgent);
  const bowserDeviceType = parser && parser.getPlatformType(true);
  const operatingSystem = parser && parser.getOSName(true);
  const isIpadOS = isIPadOS();
  const isMobile = bowserDeviceType === "mobile" && !isIpadOS;
  const isTablet = bowserDeviceType === "tablet" || isIpadOS;
  const isDesktop = bowserDeviceType === "desktop" && !isIpadOS;
  const isMacOS = operatingSystem === "macos" && isDesktop;
  const isChromeOS = operatingSystem === "chrome";
  const isWindows = operatingSystem === "windows";
  const isLinux = operatingSystem === "linux";
  const userCurrentMachine = (isMobile ? "mobile" : isTablet ? "tablet" : operatingSystem) as UserCurrentMachine;
  return { isMobile, isTablet, isDesktop, isMacOS, isChromeOS, isWindows, isLinux, userCurrentMachine };
};

/**
 * Get the first query param
 */
export const getQueryParam = (param: string | string[] | undefined) => (Array.isArray(param) ? param[0] : param);

/**
 * Get single query params object
 */
export const getSingleQueryParams = (query: Record<string, string | string[] | undefined>) =>
  Object.entries(query).reduce<Record<string, string | undefined>>(
    (prev, [key, value]) => ({
      ...prev,
      [key]: getQueryParam(value),
    }),
    {},
  );

/**
 * Check if the current browser is supported
 */
export const isValidBrowser = () => {
  if (!isBrowser()) return true;

  const browser = Bowser.getParser(window.navigator.userAgent);
  return !browser.satisfies({
    windows: {
      "internet explorer": "<=11",
    },
    macos: {
      safari: "<12",
    },
  });
};

/**
 * Show an alert if the current browser is unsupported
 */
export const checkBrowserSupport = () => {
  if (isValidBrowser()) return;

  window.alert(
    "Your browser isn’t supported!\n\nThis website may not function correctly in your current browser. We only support recent versions of major browsers like Chrome, Firefox, Safari, and Edge.\n\nTo upgrade your browser, please visit browsehappy.com",
  );
};
