import React, { MouseEvent } from "react";
import { Image as DatoImage, ResponsiveImageType as ImageProps } from "react-datocms";
import classNames from "classnames";
import { IMAGE_OBJECT_POSITION } from "@/constants";
import { ProfileBeforeAfterLabel } from "./ProfileBeforeAfterLabel";

type ProfileBeforeAfterSliderProps = {
  className?: string;
  beforeImage: ImageProps;
  afterImage: ImageProps;
  withLabels?: boolean;
};

const MIN_LEFT_OFFSET = 107;
const MAX_RIGHT_OFFSET = 95;

const ProfileBeforeAfterSlider = ({
  beforeImage,
  afterImage,
  className,
  withLabels = false,
  ...props
}: ProfileBeforeAfterSliderProps) => {
  return (
    <div
      className={classNames(
        "shimmer-gradient animate-background-shimmer-large absolute overflow-hidden bg-cover bg-center bg-no-repeat top-0 right-0 left-0 bottom-0 text-white",
        className,
      )}
      // Don't show the before and after slider effect if the before and after image are the same
      onMouseMove={
        beforeImage === afterImage ? undefined : (e) => handleMouseMove(e.currentTarget, e.clientX, withLabels)
      }
      onMouseLeave={(e) => (beforeImage === afterImage ? undefined : handleMouseLeave(e, withLabels))}
      ref={(el) => {
        if (el) {
          handleMouseMove(el, 0, withLabels);
        }
      }}
      {...props}
    >
      {/* Divider line that follows the cursor */}
      <div
        className="bg-white absolute top-0 bottom-0 z-10 w-[1px]"
        style={{
          transform: `translateX(-1px)`,
        }}
      />
      {/* After image which is displayed by default */}
      <div className="absolute top-0 left-0 bottom-0 right-0 z-0 [&>div>img[role='presentation']]:bottom-0">
        <DatoImage
          data={{ ...afterImage }}
          style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, maxWidth: "10000px" }}
          objectFit="cover"
          objectPosition={IMAGE_OBJECT_POSITION}
          usePlaceholder={false}
        />
      </div>
      {/* Before image which is displayed on hover */}
      <div
        className="z-1 absolute top-0 left-0 bottom-0 right-0 h-full overflow-hidden [&>img[role='presentation']]:bottom-0"
        style={{ width: "0%" }}
      >
        <DatoImage
          data={{ ...beforeImage }}
          objectPosition={IMAGE_OBJECT_POSITION}
          objectFit="cover"
          style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, maxWidth: "10000px" }}
          usePlaceholder={false}
        />
      </div>
      {withLabels && (
        <>
          <ProfileBeforeAfterLabel before />
          <ProfileBeforeAfterLabel after />
        </>
      )}
    </div>
  );
};

const handleMouseMove = (el: HTMLDivElement, clientX: number, withLabels: boolean) => {
  const rect = el.getBoundingClientRect();
  const offsetLeft = clientX - rect.left;
  const divider = el.children[0] as HTMLDivElement;
  const afterImageWrapper = el.children[1] as HTMLDivElement;
  const afterImageInnerWrapper = afterImageWrapper.children[0] as HTMLDivElement;
  const afterImage = afterImageInnerWrapper.children[0] as HTMLImageElement;
  const beforeImageWrapper = el.children[2] as HTMLDivElement;
  const beforeImageInnerWrapper = beforeImageWrapper.children[0] as HTMLDivElement;
  const beforeImage = beforeImageInnerWrapper.children[0] as HTMLImageElement;
  if (beforeImage.complete && afterImage.complete) {
    const min = withLabels ? MIN_LEFT_OFFSET : 0;
    const max = withLabels ? rect.width - MAX_RIGHT_OFFSET : rect.width;
    const offset = offsetLeft < min ? min : offsetLeft > max ? max : offsetLeft;
    // Only show the divider and before image if both images are loaded
    divider.style.opacity = "1";
    divider.style.transform = `translateX(${offset}px)`;
    beforeImageWrapper.style.opacity = "1";
    beforeImageWrapper.style.width = `${offset}px`;
    beforeImageInnerWrapper.style.minWidth = `${rect.width}px`;
  }
};

const handleMouseLeave = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, withLabels: boolean) => {
  const divider = e.currentTarget.children[0] as HTMLDivElement;
  const beforeImageWrapper = e.currentTarget.children[2] as HTMLDivElement;
  const rect = e.currentTarget.getBoundingClientRect();
  const exitedLeft = e.clientX < rect.left;
  const exitedRight = e.clientX > rect.right;

  if (withLabels) {
    divider.style.transform = `translateX(${withLabels ? MIN_LEFT_OFFSET : -1}px)`;
    beforeImageWrapper.style.width = `${MIN_LEFT_OFFSET}px`;
    return;
  }

  divider.style.opacity = "0";
  beforeImageWrapper.style.opacity = "0";

  if (exitedLeft) {
    divider.style.transform = `translateX(${withLabels ? MIN_LEFT_OFFSET : -1}px)`;
    beforeImageWrapper.style.width = `0px`;
  }
  if (exitedRight) {
    divider.style.transform = `translateX(${rect.width + 1}px)`;
    beforeImageWrapper.style.width = `${rect.width}px`;
  }
};

export { ProfileBeforeAfterSlider };
