import React, { useState } from "react";
import { Image as DatoImage } from "react-datocms";
import { motion, AnimatePresence } from "framer-motion";
import Link from "next/link";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ProfileBeforeAfterSlider } from "@/components/shared/ProfileBeforeAfterSlider";
import { ROUTES } from "@/config";
import { IMAGE_OBJECT_POSITION } from "@/constants";
import { ArtistStyle } from "@/types";
import { AnchorButton, HorizontalRule } from "@narrative-software/narrative-web-ui";
import { ArtistStylesCarousel } from "./ArtistStylesCarousel";

const SLIDE_WIDTH = 182;
const SLIDE_GAP = 20;

type ArtistStylesSectionProps = {
  artistStyles: ArtistStyle[];
  title: string;
  subtitle: string;
};

function ArtistStylesSection({ artistStyles, title, subtitle }: ArtistStylesSectionProps) {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);

  return (
    <section className="flex-col bg-off-white text-center py-20 md:py-30">
      <HorizontalRule bottomMargin />
      <div className="px-5">
        <h2 className="text-h2 font-semibold leading-compact mb-[14px] text-center">{title}</h2>
        <p className="text-lg mb-[34px]">{subtitle}</p>
      </div>

      <ArtistStylesCarousel
        artistStyles={artistStyles}
        selectedSlideIndex={selectedSlideIndex}
        onSlideChange={(i) => setSelectedSlideIndex(i)}
        className="max-w-[1152px]"
      />
      <div data-mouse className="w-full m-auto mb-5 px-5" style={{ maxWidth: `${SLIDE_WIDTH * 6 + SLIDE_GAP * 5}px` }}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={selectedSlideIndex}
            className="w-full relative h-0 pb-[50%] md:pv"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="absolute top-0 left-0 bottom-0 w-[100%] bg-ekans md:w-[75%]">
              <ProfileBeforeAfterSlider
                beforeImage={artistStyles[selectedSlideIndex].image1Before.responsiveImage}
                afterImage={artistStyles[selectedSlideIndex].image1After.responsiveImage}
                withLabels
              />
            </div>
            <div className="absolute top-0 right-0 bottom-0 w-[calc(25%-20px)]">
              <div className="absolute top-0 left-0 right-0 h-[32%]">
                <ProfileBeforeAfterSlider
                  beforeImage={artistStyles[selectedSlideIndex].image2Before.responsiveImage}
                  afterImage={artistStyles[selectedSlideIndex].image2After.responsiveImage}
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 h-[calc(68%-20px)]">
                <ProfileBeforeAfterSlider
                  beforeImage={artistStyles[selectedSlideIndex].image3Before.responsiveImage}
                  afterImage={artistStyles[selectedSlideIndex].image3After.responsiveImage}
                />
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <div data-touch className="w-full m-auto mb-5 px-5 max-w-[840px]">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={selectedSlideIndex}
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="relative h-0 w-full pb-[66.67%]">
              <DatoImage
                data={{ ...artistStyles[selectedSlideIndex].image1After.responsiveImage }}
                style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
                objectFit="cover"
                objectPosition={IMAGE_OBJECT_POSITION}
              />
            </div>
            <div className="relative h-0 w-full pb-[43.33%] mt-[10px]">
              <div className="absolute top-0 left-0 bottom-0 w-[calc(67.8%-10px)]">
                <DatoImage
                  data={{ ...artistStyles[selectedSlideIndex].image2After.responsiveImage }}
                  style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
                  objectFit="cover"
                  objectPosition={IMAGE_OBJECT_POSITION}
                />
              </div>
              <div className="absolute top-0 right-0 bottom-0 w-[32.2%]">
                <DatoImage
                  data={{ ...artistStyles[selectedSlideIndex].image3After.responsiveImage }}
                  style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
                  objectFit="cover"
                  objectPosition={IMAGE_OBJECT_POSITION}
                />
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="flex flex-col md:flex-row max-w-[500px] gap-4 w-auto mx-auto px-4">
        <Link href={`/${ROUTES.EDIT.STYLES.SLUG}/${artistStyles[selectedSlideIndex].slug}`} passHref>
          <AnchorButton colour="black">Preview This Style</AnchorButton>
        </Link>
        <Link href={`/${ROUTES.EDIT.STYLES.SLUG}`} passHref>
          <AnchorButton colour="black" appearance="secondary">
            View all Artist Styles
          </AnchorButton>
        </Link>
      </div>
    </section>
  );
}

export { ArtistStylesSection };
