type Props = {
  className?: string;
};

const ClockIcon: React.FC<Props> = ({ className }) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" className={className}>
    <circle cx="10.5" cy="10.5" r="10" stroke="black" />
    <line x1="10.5" y1="11" x2="10.5" y2="3" stroke="black" />
    <line x1="10.3536" y1="10.6464" x2="13.3536" y2="13.6464" stroke="black" />
  </svg>
);

export default ClockIcon;
