import { camelCase } from "lodash";
import { IPlanObject } from "@/api";
import DealOfLifeTimeBanner, {
  DealOfLifeTimeBannerType,
  dealOfLifetimeFragment,
} from "@/components/lifetime-deal/DealOfLifeTimeBanner";
import { Stat, statFragment, StatFragmentType } from "./Stat";
// Must be imported by relative path or the GraphQL query will fail
import { Accordion, accordionFragment, AccordionFragmentType } from "./accordion";
import { buttonFragment, ButtonFragmentType } from "./button";
import { Carousel, carouselFragment, CarouselFragmentType } from "./carousel";
import { Content, contentFragment, ContentFragmentType } from "./content";
import { formFragment, FormFragmentType, DatoForm } from "./form";
import { MediaBanner, mediaBannerFragment, MediaBannerFragmentType } from "./media-banner";
import { PromoBanner, promoBannerFragment, PromoBannerFragmentType } from "./promo-banner";
import { Resources, resourceFragment, ResourceFragmentType } from "./resource";
import {
  SelectPlanComparison,
  selectPlanComparisonFragment,
  SelectPlanComparisonFragmentType,
} from "./select-plan-comparison/SelectPlanComparison";
import {
  SelectTimeCostCalculator,
  selectTimeCostCalculatorFragment,
  SelectTimeCostCalculatorFragmentType,
} from "./select-time-cost-calculator";
import {
  TextMediaSection,
  SingleTextMediaSection,
  textMediaItemFragment,
  textMediaSectionFragment,
  TextMediaItemFragmentType,
  TextMediaSectionFragmentType,
} from "./text-media";
import { ThreeUp, threeUpFragment, ThreeUpFragmentType } from "./three-up";

// DatoCMS modal API keys from the '_modelApiKey' property
export const MODULE_KEYS = {
  accordion: "module_accordion",
  button: "link",
  carousel: "module_testimonial_carousel",
  content: "module_content",
  form: "module_form",
  heroV2: "module_hero_v2",
  mediaBanner: "module_image_banner",
  promoBanner: "module_promo_banner",
  resource: "module_resource",
  textMediaItem: "module_image_text_item",
  textMediaSection: "module_image_text",
  threeUp: "module_three_up",
  stat: "module_stat",
  selectPlanComparison: "module_select_plan_comparison",
  timeCostCalculator: "module_time_cost_calculator",
  lifetimeDealModule: "module_lifetime_deal",
} as const;

type ModuleKey = (typeof MODULE_KEYS)[keyof typeof MODULE_KEYS];
type ModuleComponent =
  | typeof Accordion
  | typeof Carousel
  | typeof Content
  | typeof DatoForm
  | typeof MediaBanner
  | typeof PromoBanner
  | typeof Resources
  | typeof SingleTextMediaSection
  | typeof TextMediaSection
  | typeof ThreeUp
  | typeof SelectTimeCostCalculator
  | typeof SelectPlanComparison
  | typeof Stat
  | typeof DealOfLifeTimeBanner;

// Module map
export const MODULE_MAP: Partial<Record<ModuleKey, ModuleComponent>> = {
  [MODULE_KEYS.accordion]: Accordion,
  [MODULE_KEYS.carousel]: Carousel,
  [MODULE_KEYS.content]: Content,
  [MODULE_KEYS.form]: DatoForm,
  [MODULE_KEYS.mediaBanner]: MediaBanner,
  [MODULE_KEYS.promoBanner]: PromoBanner,
  [MODULE_KEYS.resource]: Resources,
  [MODULE_KEYS.textMediaItem]: SingleTextMediaSection,
  [MODULE_KEYS.textMediaSection]: TextMediaSection,
  [MODULE_KEYS.threeUp]: ThreeUp,
  [MODULE_KEYS.timeCostCalculator]: SelectTimeCostCalculator,
  [MODULE_KEYS.selectPlanComparison]: SelectPlanComparison,
  [MODULE_KEYS.stat]: Stat,
  [MODULE_KEYS.lifetimeDealModule]: DealOfLifeTimeBanner,
};

export const FRAGMENT_MAP: Partial<Record<ModuleKey, string>> = {
  [MODULE_KEYS.accordion]: accordionFragment,
  [MODULE_KEYS.carousel]: carouselFragment,
  [MODULE_KEYS.content]: contentFragment,
  [MODULE_KEYS.form]: formFragment,
  [MODULE_KEYS.mediaBanner]: mediaBannerFragment,
  [MODULE_KEYS.promoBanner]: promoBannerFragment,
  [MODULE_KEYS.resource]: resourceFragment,
  [MODULE_KEYS.textMediaItem]: textMediaItemFragment,
  [MODULE_KEYS.textMediaSection]: textMediaSectionFragment,
  [MODULE_KEYS.threeUp]: threeUpFragment,
  [MODULE_KEYS.timeCostCalculator]: selectTimeCostCalculatorFragment,
  [MODULE_KEYS.selectPlanComparison]: selectPlanComparisonFragment,
  [MODULE_KEYS.stat]: statFragment,
  [MODULE_KEYS.lifetimeDealModule]: dealOfLifetimeFragment,
};
// Used to combine services API data with CMS data at the page level
const MODULES_THAT_REQUIRE_STRIPE_PLAN_DATA: string[] = [MODULE_KEYS.selectPlanComparison];

export const addPlanDataToModules = (modules: Modules, plans: IPlanObject[]) => {
  return modules.map((module) => {
    if (!MODULES_THAT_REQUIRE_STRIPE_PLAN_DATA.includes(module._modelApiKey)) return module;

    return {
      ...module,
      stripePlans: plans,
    };
  });
};

// Responsive image
// See: https://www.datocms.com/blog/offer-responsive-progressive-lqip-images-in-2020
export const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }
`;

// Responsive video
// See: https://www.datocms.com/docs/content-delivery-api/images-and-videos#videos
export const muxVideoQuery = `
  video {
    muxPlaybackId
    title
    width
    height
    blurUpThumb
  }
`;

// Meta tags
export const metaTagsFragment = `
  fragment metaTagsFragment on Tag {
    attributes
    content
    tag
  }
`;

/**
 * For pages that have a "link to (multiple) modules" field (often called "modules"). Such fields have a whitelist of
 * assignable modules in their config. The query must correspond exactly to the whitelist. This function provides the
 * modules key declaration and associated fragments for the provided modules. We use naming conventions here as does
 * DatoCMS to stitch it all the way:
 *
 * a module key 'module_testimonial_carousel' from MODULE_KEYS will expect a fragment called
 * 'testimonialCarouselFragment' on the FRAGMENT_MAP which must do a GraphQL 'on' case for
 * 'ModuleTestimonialCarouselRecord' which corresponds to a model in DatoCMS called module_testimonial_carousel.
 *
 * @param moduleList - list of modules to include in the modules field.
 *
 * @returns query partials - the modules key & corresponding fragments: ["modules{ ...a ...b }",  "fragment a on A {}   fragment b on B {}"]
 */
export function getLinkFieldAndFragments(moduleList: ModuleKey[], fieldName = "modules"): string[] {
  return [
    `${fieldName} {
    ${moduleList.map((module) => `...${camelCase(module.replace("module", ""))}Fragment`).join("\n    ")}
      }`,
    moduleList.map((module) => FRAGMENT_MAP[module]).join("\n"),
  ];
}

// deprecated in favor of getLinkFieldAndFragments
export const modulesField = `
  modules {
    ...accordionFragment,
    ...testimonialCarouselFragment,
    ...contentFragment,
    ...formFragment,
    ...mediaBannerFragment,
    ...promoBannerFragment,
    ...resourceFragment,
    ...textMediaItemFragment,
    ...textMediaSectionFragment,
    ...threeUpFragment,
    ...lifetimeDealFragment
  }
`;

// deprecated in favor of getLinkFieldAndFragments
export const modulesFragments =
  accordionFragment +
  buttonFragment +
  carouselFragment +
  contentFragment +
  formFragment +
  mediaBannerFragment +
  promoBannerFragment +
  resourceFragment +
  responsiveImageFragment +
  textMediaItemFragment +
  textMediaSectionFragment +
  threeUpFragment +
  dealOfLifetimeFragment;

export type Modules = (
  | AccordionFragmentType
  | ButtonFragmentType
  | CarouselFragmentType
  | ContentFragmentType
  | FormFragmentType
  | MediaBannerFragmentType
  | PromoBannerFragmentType
  | ResourceFragmentType
  | ThreeUpFragmentType
  | TextMediaItemFragmentType
  | TextMediaSectionFragmentType
  | SelectTimeCostCalculatorFragmentType
  | SelectPlanComparisonFragmentType
  | StatFragmentType
  | DealOfLifeTimeBannerType
)[];
