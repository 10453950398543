import classNames from "classnames";

interface Props {
  className?: string;
}

const HorizontalRule: React.FC<Props> = ({ className }) => (
  <hr className={classNames("w-20 border-t-4 border-solid border-white", className)} />
);

export default HorizontalRule;
