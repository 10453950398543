import { useState, useEffect } from "react";
import { MobileSecondaryNav } from ".";
import { NavItem, NavItemPrimary } from "..";
import classNames from "classnames";
import Link from "next/link";

type Props = {
  items: NavItemPrimary[];
  activePath: string;
  onClose: () => void;
  open?: boolean;
  mobileLegalItems?: NavItem[];
};

const MobileNav: React.FC<Props> = ({ items, activePath, onClose, open, mobileLegalItems = [] }) => {
  const className = "flex items-center w-full pl-9 pr-6 transition-colors hover:text-white";

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.currentTarget.pathname === activePath) {
      onClose();
    }
  };

  const [toggle, setToggle] = useState<Record<string, boolean>>({});
  const toggleSecondary = (label: string) => {
    setToggle((prevState) => ({ [label]: !prevState[label] }));
  };

  useEffect(() => {
    if (!open) {
      setToggle({});
    }
  }, [open, setToggle]);

  return (
    <div
      className={classNames("bg-gray-900 lg:hidden", {
        block: open,
        hidden: !open,
      })}
    >
      <ul className="mb-5 border-t border-black">
        {items.map(({ href, label, isActive, isExternal, isNewTab, secondaryNav }) => {
          const Anchor = (
            <a
              href={href}
              target={isNewTab ? "_blank" : undefined}
              rel={isNewTab ? "noreferrer noopener" : undefined}
              onClick={handleClick}
              className={classNames(className, "py-2.5 text-lg", {
                "text-gray-500": !isActive,
                "text-white font-semibold": isActive,
              })}
            >
              {label}
            </a>
          );

          return (
            <li key={label} className="border-b border-black">
              {secondaryNav ? (
                <>
                  <button
                    type="button"
                    onClick={() => toggleSecondary(label)}
                    className={classNames(className, "py-2.5 text-lg text-gray-500 justify-between")}
                  >
                    {label}
                    <svg
                      width="14"
                      height="7"
                      viewBox="0 0 14 7"
                      fill="none"
                      className={classNames("transform", { "rotate-180": toggle[label] })}
                    >
                      <path fill="#E0E0E0" fillRule="evenodd" clipRule="evenodd" d="M0 0L7 7L14 0L0 0Z" />
                    </svg>
                  </button>

                  <MobileSecondaryNav
                    {...{ activePath, onClose, className }}
                    open={toggle[label]}
                    items={secondaryNav}
                  />
                </>
              ) : isExternal ? (
                Anchor
              ) : (
                <Link href={href}>{Anchor}</Link>
              )}
            </li>
          );
        })}

        {mobileLegalItems.map(({ href, label, isExternal, isNewTab }, i) => {
          const Anchor = (
            <a
              href={href}
              target={isNewTab ? "_blank" : undefined}
              rel={isNewTab ? "noreferrer noopener" : undefined}
              onClick={handleClick}
              className="block px-9 py-2 text-11 text-gray-600 underline transition-color hover:text-white"
            >
              {label}
            </a>
          );

          return (
            <li key={label} className={classNames({ "mt-4": i === 0 })}>
              {isExternal ? Anchor : <Link href={href}>{Anchor}</Link>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MobileNav;
