import classNames from "classnames";
import Link from "next/link";
import {
  Section,
  Container,
  FixedAspectRatioContainer,
  CredibilityBar,
  StartFreeButtons,
  SignUpForm,
} from "@/components";
import { ILogo } from "@/components/shared/CredibilityBar";
import { IS_GOOGLE_AUTH_ENABLED, ROUTES } from "@/config";
import { Product } from "@/types";
import { AnchorButton } from "@narrative-software/narrative-web-ui";

type Props = {
  product: Product;
  title: React.ReactNode;
  videoSrc: string;
  videoPoster: string;
  videoAspectRatio: number;
  companyLogosLabel: string;
  companyLogos: ILogo[];
};

const HeroSectionSale: React.FC<Props> = ({
  product,
  title,
  videoSrc,
  videoPoster,
  videoAspectRatio,
  companyLogos,
  companyLogosLabel,
  children,
}) => (
  <Section isFirstSection>
    <Container>
      <div className="flex flex-col items-center text-center lg:flex-row lg:text-left">
        <div className="flex flex-col items-center w-full pb-6 lg:items-start lg:w-1/2 lg:pb-0 lg:pr-23">
          <h1 className="text-h2 font-semibold leading-tight lg:text-h1 lg:leading-compact">{title}</h1>
          <p className="mt-6 mb-8 text-lg">{children}</p>
          {product === "publish" ? (
            <Link href={`/${ROUTES.PUBLISH.PRICING.SLUG}`} passHref>
              <AnchorButton className="mt-2 sm:w-1/2 sm:mt-0" colour={"slowpoke"}>
                Shop sale
              </AnchorButton>
            </Link>
          ) : IS_GOOGLE_AUTH_ENABLED ? (
            <StartFreeButtons product={product} emailButtonColour="slowpoke" />
          ) : (
            <SignUpForm product={product} buttonColour={"gengar"} />
          )}
        </div>
        <div className="w-full mt-4 flex-1 lg:w-auto lg:mt-0">
          <FixedAspectRatioContainer
            aspectRatio={videoAspectRatio}
            className={classNames("flex-grow w-full overflow-hidden lg:w-auto", {
              "border border-cinder-block rounded shadow-md": product === "select",
            })}
          >
            <video src={videoSrc} poster={videoPoster} autoPlay muted loop playsInline />
          </FixedAspectRatioContainer>
          {product === "publish" && (
            <p className="-mt-1 mr-5.5 text-10 text-shady-lady text-right">
              Image credit:{" "}
              <a href="https://georgiaverrells.com" target="_blank" rel="noopener noreferrer" className="underline">
                Georgia Verrels
              </a>
            </p>
          )}
        </div>
      </div>
      <CredibilityBar label={companyLogosLabel} logos={companyLogos} className="mt-11 lg:mt-24 lg:-mr-3" />
    </Container>
  </Section>
);

export default HeroSectionSale;
