const MagnifyingGlassIcon: React.FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00038 16.9992C4.5823 16.9992 1.00075 13.4177 1.00075 8.99961C1.00075 4.58155 4.5823 0.999999 9.00038 1C13.4184 1 17 4.58155 17 8.99961C17 13.4177 13.4184 16.9992 9.00038 16.9992Z"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="5.10524"
      y2="-1"
      transform="matrix(0.707107 0.707106 -0.707107 0.707106 14.2461 15.4043)"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
    <path
      d="M17.3146 19.3721L19.3715 17.3152L25.6084 23.5521L23.5515 25.609L17.3146 19.3721Z"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
  </svg>
);

export default MagnifyingGlassIcon;
