import { MODULE_MAP, Modules } from "./config";

type Props = {
  modules: Modules;
  addBackgroundColour?: boolean;
};

const ModuleToComponent: React.FC<Props> = ({ modules, ...rest }) => {
  if (!modules?.length) return null;

  let addBackgroundColour = rest.addBackgroundColour;

  return (
    <>
      {modules.map((data) => {
        const { _modelApiKey, id, isSection } = data;

        const Component = MODULE_MAP[_modelApiKey];
        if (!Component) return null;

        let className;

        if (isSection === false) {
          className = "-mt-10 md:-mt-12 lg:-mt-16 xl:-mt-36";
        } else if (addBackgroundColour) {
          className = "bg-white";
          addBackgroundColour = false;
        } else {
          addBackgroundColour = true;
        }

        return (
          <div key={id} className={className}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Component {...(data as any)} />
          </div>
        );
      })}
    </>
  );
};

export default ModuleToComponent;
