const AlertIcon: React.FC = () => (
  <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 8.07143L12.7273 1.16071L24.4545 8.07143V21.9286L12.7273 28.8393L1 21.9286V8.07143Z"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
    <rect x="11.8186" y="21.8158" width="1.81818" height="1.81818" stroke="#1C1C1C" strokeWidth="1.81818" />
    <path d="M11.9095 6.15979H12.7277H13.5459V16.8871H11.9095V6.15979Z" stroke="#1C1C1C" strokeWidth="2" />
  </svg>
);

export default AlertIcon;
