import { ResponsiveImageType } from "react-datocms";
import { ImageType } from "@/types";

export interface PageFragmentType {
  slug: string;
  parent?: PageFragmentType;
  parentPage?: PageFragmentType;
}

// DatoCMS GraphQL page records
export const PAGE_RECORDS = {
  select: "PageSelectRecord",
  publish: "PagePublishRecord",
  pricing: "PagePricingRecord",
  upgrade: "PageUpgradeRecord",
  download: "PageDownloadRecord",
  changelog: "PageChangelogRecord",
  blog: "PageBlogRecord",
  blogPost: "PageBlogPostRecord",
  lesson: "PageLessonRecord",
  lessonHolder: "PageLessonHolderRecord",
  partnership: "PagePartnershipRecord",
  partnershipHolder: "PagePartnershipHolderRecord",
  about: "PageAboutRecord",
  career: "PageCareerRecord",
  job: "PageJobRecord",
  generic: "PageGenericRecord",
  awards2022: "PageAwardsTwentyTwentyTwoRecord",
};

const TOP_LEVEL_PAGES = [
  PAGE_RECORDS.select,
  PAGE_RECORDS.publish,
  PAGE_RECORDS.blog,
  PAGE_RECORDS.about,
  PAGE_RECORDS.career,
  PAGE_RECORDS.partnershipHolder,
  PAGE_RECORDS.awards2022,
];

const SECOND_LEVEL_PAGES = [PAGE_RECORDS.blogPost, PAGE_RECORDS.partnership, PAGE_RECORDS.job];

const PRODUCT_SECOND_LEVEL_PAGES = [
  PAGE_RECORDS.pricing,
  PAGE_RECORDS.upgrade,
  PAGE_RECORDS.download,
  PAGE_RECORDS.changelog,
  PAGE_RECORDS.lessonHolder,
];

const PRODUCT_THIRD_LEVEL_PAGES = [PAGE_RECORDS.lesson];

// Page fields
export const pageFields = `
  ${TOP_LEVEL_PAGES.map(
    (pageRecord) => `
      ... on ${pageRecord} {
        id
        slug
        title
      }
    `,
  )}
  ${SECOND_LEVEL_PAGES.map(
    (pageRecord) => `
      ... on ${pageRecord} {
        id
        slug
        title
        parentPage {
          slug
        }
      }
    `,
  )}
  ${PRODUCT_SECOND_LEVEL_PAGES.map(
    (pageRecord) => `
      ... on ${pageRecord} {
        id
        slug
        title
        parentPage {
          ... on ${PAGE_RECORDS.select} {
            slug
          }
          ... on ${PAGE_RECORDS.publish} {
            slug
          }
        }
      }
    `,
  )}
  ${PRODUCT_THIRD_LEVEL_PAGES.map(
    (pageRecord) => `
      ... on ${pageRecord} {
        id
        slug
        title
        parentPage {
          slug
          parentPage {
            ... on ${PAGE_RECORDS.select} {
              slug
            }
            ... on ${PAGE_RECORDS.publish} {
              slug
            }
          }
        }
      }
    `,
  )}
  ... on ${PAGE_RECORDS.generic} {
    id
    slug
    title
    parent {
      slug
      parent {
        slug
      }
    }
  }
`;

export const formatImageData = ({
  image,
  removeBase64 = true,
}: {
  image: ImageType;
  removeBase64?: boolean;
}): ResponsiveImageType =>
  image.responsiveImage
    ? {
        ...image.responsiveImage,
        base64: removeBase64 ? "" : image.responsiveImage.base64,
      }
    : {
        src: image.url,
        aspectRatio: image.width / image.height,
        ...image,
      };
