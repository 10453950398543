import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { LegalLinks } from "@/components";
import { PartnershipSubmitHandlerParams } from "@/types";
import { urlRegex } from "@/utils";
import { TextField } from "@material-ui/core";
import { Button } from "@narrative-software/narrative-web-ui";

// Hidden inputs
const hiddenInputs = {
  u: "8",
  f: "8",
  s: "",
  c: "0",
  m: "0",
  act: "sub",
  v: "2",
};

// Field types
export interface IInstagramFormFields {
  name: string;
  email: string;
  website: string;
  instagram: string;
  bio: string;
  image: string;
  about: string;
}

// Field map
const fieldMap: IInstagramFormFields = {
  name: "fullname",
  email: "email",
  website: "field[46]",
  instagram: "field[47]",
  bio: "field[61]",
  image: "field[62]",
  about: "field[63]",
};

// Initial values
const initialValues = Object.keys(fieldMap).reduce(
  (accumulator, currentValue) => ({
    ...accumulator,
    [currentValue]: "",
  }),
  {} as IInstagramFormFields,
);

// Validation
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  website: Yup.string().matches(urlRegex, "Invalid URL").required("Website required"),
  instagram: Yup.string().required("Instagram required"),
  bio: Yup.string().optional(),
  image: Yup.string().matches(urlRegex, "Invalid URL").required("Image required"),
  about: Yup.string().required("Image description required"),
});

type Props = {
  onSubmit: <T extends Record<string, string>>({
    values,
    actions,
    fieldMap,
    hiddenInputs,
  }: PartnershipSubmitHandlerParams<T>) => Promise<void>;
};

const InstagramForm: React.FC<Props> = ({ onSubmit }) => {
  const handleSubmit = async (values: IInstagramFormFields, actions: FormikHelpers<IInstagramFormFields>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await onSubmit<any>({ values, actions, fieldMap, hiddenInputs });
  };

  return (
    <>
      <h2 className="text-h3 font-semibold leading-compact text-center mb-6 lg:text-h2">Submit your photo:</h2>
      <div className="w-full">
        <Formik {...{ initialValues, validationSchema }} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
            const getFieldProps = (name: keyof IInstagramFormFields, helperText?: string) => ({
              name,
              id: `form-instagram-${name}`,
              type: "text",
              value: values[name],
              error: touched[name] && !!errors[name],
              helperText: helperText ? (touched[name] && errors[name]) || helperText : touched[name] && errors[name],
              onChange: handleChange,
              onBlur: handleBlur,
              fullWidth: true,
            });

            return (
              <Form noValidate>
                <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                  <TextField {...getFieldProps("name")} label="Name" />
                  <TextField {...getFieldProps("email")} label="Email" type="email" />
                  <TextField {...getFieldProps("website")} label="Website" type="url" />
                  <TextField {...getFieldProps("instagram")} label="Instagram" />
                  <TextField {...getFieldProps("bio")} label="Short Bio (optional)" />
                  <TextField
                    {...getFieldProps("image", "Link to a high-res version of the image, e.g. Dropbox or Google Drive")}
                    label="Image link"
                    type="url"
                  />
                </div>

                <div className="mt-6 grid grid-cols-1 gap-5">
                  <TextField
                    {...getFieldProps("about", "Tell us about the photo you’ve submitted")}
                    label="About your photo"
                    multiline
                  />
                </div>

                <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 md:mt-14">
                  <div className="flex items-center">
                    <LegalLinks />
                  </div>
                  <div>
                    <Button type="submit" colour="black" isLoading={isSubmitting} showLoader>
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default InstagramForm;
