import { forwardRef } from "react";
import classNames from "classnames";

interface Props extends React.ComponentPropsWithRef<"section"> {
  addXPadding?: boolean;
  isFirstSection?: boolean;
}

const Section: React.FC<Props> = forwardRef<HTMLElement, Props>(
  // eslint-disable-next-line no-unused-vars
  ({ children, className, addXPadding, isFirstSection, ...rest }, ref) => (
    <section
      {...rest}
      ref={ref}
      className={classNames("flex py-12 md:py-16 lg:py-20 xl:py-30", className, {
        "px-5 sm:px-6 lg:px-8": addXPadding,
      })}
    >
      {children}
    </section>
  ),
);

Section.displayName = "Section";

export default Section;
