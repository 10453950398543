const ImageIcon: React.FC = () => (
  <svg width="32" height="40" viewBox="0 0 32 40" fill="none">
    <path
      d="M18.4822 34.1766L17.7827 35H25.9211L21.9764 30.0628L18.4822 34.1766ZM6.03532 35H17.6134L16.9379 34.1524L11.7325 27.6203L6.03532 35Z"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
    <circle cx="23.5" cy="16.5" r="3.5" stroke="#1C1C1C" strokeWidth="2" />
    <path d="M6.06218 0L12.1247 5H0L6.06218 0Z" fill="#1C1C1C" />
    <rect x="5.0625" y="4" width="2" height="11" fill="#1C1C1C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 10H30V38H2V10H3V8H0V40H32V8H9V10Z" fill="#1C1C1C" />
  </svg>
);

export default ImageIcon;
