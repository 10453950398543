import parse, { domToReact, attributesToProps, DOMNode } from "html-react-parser";
import React from "react";
import { Element } from "domhandler";
import Link from "next/link";
import Script from "next/script";
import { PageFragmentType } from "@/config";

/**
 * Strip tags from string
 */
export const stripParagraphTags = (string: string) => string.replace(/<\/?p[^>]*>/g, "");

/**
 * Get the full pathname of a page
 */
export const createHref = (page: PageFragmentType) => {
  let path = "";
  const { slug, parent, parentPage } = page || {};

  if (parent) {
    if (parent.parent) {
      path = `/${parent.parent.slug}`;
    }
    path += `/${parent.slug}`;
  } else if (parentPage) {
    if (parentPage.parentPage) {
      path = `/${parentPage.parentPage.slug}`;
    }
    path += `/${parentPage.slug}`;
  }

  path += `/${slug}`;

  return path;
};

export const enhanceContent = (content: string) =>
  parse(content, {
    replace: (domNode) => [replaceLinksInHtmlString(domNode), replaceScriptsInHtmlString(domNode)].find(Boolean),
  });

/**
 * Parse and replace same site links (anchors tags with href="https://narrative.so...") with Next.js <Link> element
 */
const replaceLinksInHtmlString = (domNode: DOMNode) => {
  if (domNode instanceof Element && domNode.name === "a" && domNode.attribs?.href) {
    const url = new URL(domNode.attribs.href);
    const { hostname, pathname, search, hash } = url;
    if (hostname === "narrative.so") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { href, ...props } = domNode.attribs;
      return (
        <Link href={{ pathname, search, hash }}>
          <a {...attributesToProps(props)}>{domToReact(domNode.children)}</a>
        </Link>
      );
    }
  }
};

/**
 * Parse and replace publish embeds with Next Script components
 */
const replaceScriptsInHtmlString = (domNode: DOMNode) => {
  if (domNode instanceof Element && domNode.name === "script" && domNode.attribs?.src) {
    const { hostname } = new URL(domNode.attribs.src);
    if (hostname === "fetch.getnarrativeapp.com") {
      // Cache bust is a workaround as the publish renderer script has to download and run each time the page is visited
      return <Script src={`${domNode.attribs.src}?cache-bust=${Date.now()}`} />;
    }
  }
};
