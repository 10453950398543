type Props = {
  onClick(): void;
  open?: boolean;
};

const MobileButton: React.FC<Props> = ({ open, onClick }) => (
  <div className="-mr-3 flex items-center lg:hidden">
    <button
      type="button"
      onClick={onClick}
      aria-label="Navigation"
      aria-expanded={open}
      className="inline-flex items-center justify-center p-3 rounded-md text-white"
    >
      {open ? (
        <svg width="24" height="20" viewBox="0 0 14 14" fill="none">
          <path
            fill="currentColor"
            d="M13.4173 1.87453L12.1248 0.582031L7.00065 5.7062L1.87648 0.582031L0.583984 1.87453L5.70815 6.9987L0.583984 12.1229L1.87648 13.4154L7.00065 8.2912L12.1248 13.4154L13.4173 12.1229L8.29315 6.9987L13.4173 1.87453Z"
          />
        </svg>
      ) : (
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none">
          <rect width="24" height="2" fill="currentColor" />
          <rect y="9" width="24" height="2" fill="currentColor" />
          <rect y="18" width="24" height="2" fill="currentColor" />
        </svg>
      )}
    </button>
  </div>
);

export default MobileButton;
