import { Section, Container, HtmlToElement } from "@/components";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface ContentFragmentType {
  _modelApiKey: typeof MODULE_KEYS.content;
  id: string;
  content: string;
  heading?: string;
  isSection?: boolean;
}

// Fragment
export const contentFragment = `
  fragment contentFragment on ModuleContentRecord {
    _modelApiKey
    id
    heading
    content
    isSection
  }
`;

export const Content: React.FC<ContentFragmentType> = ({ id, content, heading = "" }) => (
  <Section key={id}>
    <Container className="flex flex-col items-center">
      {heading && (
        <div className="mb-12 lg:mb-18">
          <HtmlToElement element="h1" className="text-h2 font-semibold leading-compact text-center lg:text-h1">
            {heading}
          </HtmlToElement>
          <HorizontalRule topMargin />
        </div>
      )}
      <div className="w-full max-w-200">
        <HtmlToElement className="prose text-stealth-bomber">{content}</HtmlToElement>
      </div>
    </Container>
  </Section>
);
