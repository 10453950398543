import { useEffect, useRef } from "react";

/**
 * Generic event
 */
export const useEvent = <K extends keyof HTMLElementEventMap>(
  type: K,
  ref: React.RefObject<HTMLElement>,
  onEvent: (e: HTMLElementEventMap[K]) => void,
  options?: AddEventListenerOptions | boolean,
): void => {
  const onEventRef = useRef(onEvent);
  onEventRef.current = onEvent;

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const listener = (e: HTMLElementEventMap[K]) => onEventRef.current(e);
      element.addEventListener(type, listener, options);
      return () => element.removeEventListener(type, listener);
    }
  }, [ref, type, options]);
};

/**
 * Document event
 */
export const useDocumentEvent = <K extends keyof DocumentEventMap>(
  type: K,
  onEvent: (e: DocumentEventMap[K]) => void,
  options?: AddEventListenerOptions | boolean,
): void => {
  const onEventRef = useRef(onEvent);
  onEventRef.current = onEvent;

  useEffect(() => {
    const listener = (e: DocumentEventMap[K]) => onEventRef.current(e);
    document.addEventListener(type, listener, options);
    return () => document.removeEventListener(type, listener);
  }, [type, options]);
};

/**
 * Window event
 */
export const useWindowEvent = <K extends keyof WindowEventMap>(
  type: K,
  onEvent: (e: WindowEventMap[K]) => void,
  options?: AddEventListenerOptions | boolean,
): void => {
  const onEventRef = useRef(onEvent);
  onEventRef.current = onEvent;

  useEffect(() => {
    const listener = (e: WindowEventMap[K]) => onEventRef.current(e);
    window.addEventListener(type, listener, options);
    return () => window.removeEventListener(type, listener);
  }, [type, options]);
};
