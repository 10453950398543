import { ILinks, ProductName } from "@/api";

export enum PlanStatus {
  Live = "live",
  Draft = "draft",
  Grandfathered = "grandfathered",
}

export interface IPlanFeature {
  name: string;
  slug: string;
  description: string;
  quantity: number | null;
  "singular-noun": string;
  "plural-noun": string;
  active?: boolean;
}

export interface IPlanObject {
  type: "plans";
  relationships: Record<string, unknown>;
  links: ILinks;
  id: string;
  attributes: {
    name: string;
    status: number;
    slug: string;
    "product-name": ProductName;
    "status-label": PlanStatus;
    "default-free": boolean;
    "monthly-trial": number;
    "monthly-price": number;
    "yearly-trial": number;
    "yearly-price": number;
    "monthly-discount": number;
    "yearly-discount": number;
    "inserted-at": string;
    "updated-at": string;
    features: IPlanFeature[];
  };
}

export interface IGetPlanResponse {
  links: ILinks;
  included: [];
  data: IPlanObject;
}

export interface IGetPlansResponse {
  links: ILinks;
  included: [];
  data: IPlanObject[];
}
