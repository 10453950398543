type Props = {
  className?: string;
};

const PeopleIcon: React.FC<Props> = ({ className }) => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" className={className}>
    <circle cx="10.5" cy="11" r="10" stroke="black" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="22">
      <circle cx="10.5" cy="11" r="10.5" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <circle r="4" transform="matrix(1 0 0 -1 5.5 18)" stroke="black" />
      <path
        d="M19.5 18C19.5 14.6863 16.8137 12 13.5 12C10.1863 12 7.5 14.6863 7.5 18C7.5 19.6372 7.50074 20.8227 7.5985 21.7026C7.69668 22.5861 7.88372 23.0516 8.16605 23.3339C8.44839 23.6163 8.91385 23.8033 9.7974 23.9015C10.6773 23.9993 11.8628 24 13.5 24C15.1372 24 16.3227 23.9993 17.2026 23.9015C18.0861 23.8033 18.5516 23.6163 18.8339 23.3339C19.1163 23.0516 19.3033 22.5861 19.4015 21.7026C19.4993 20.8227 19.5 19.6372 19.5 18Z"
        fill="#F5F5F3"
        stroke="black"
      />
      <circle cx="13.5" cy="8" r="4" stroke="black" />
      <circle cx="5.5" cy="11" r="3" stroke="black" />
    </g>
  </svg>
);

export default PeopleIcon;
