const FacesIcon: React.FC = () => (
  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.6675" cy="3.25926" r="2.25926" stroke="currentColor" strokeWidth="2" />
    <circle cx="5.88817" cy="6.51902" r="2.25926" stroke="currentColor" strokeWidth="2" />
    <circle cx="9.14865" cy="17.926" r="3.88889" stroke="currentColor" strokeWidth="2" />
    <circle cx="22.1858" cy="17.926" r="3.88889" stroke="currentColor" strokeWidth="2" />
    <circle cx="25.4448" cy="6.51902" r="2.25926" stroke="currentColor" strokeWidth="2" />
    <path
      d="M20.5551 11.4074V11.4074C20.5551 8.70739 18.3663 6.51855 15.6662 6.51855V6.51855C12.9662 6.51855 10.7773 8.70739 10.7773 11.4074V11.4074"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M11.5926 9.77734H10.7778H7.51852C3.91844 9.77734 1 12.6958 1 16.2959V16.2959"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M30.3328 16.2959V16.2959C30.3328 12.6958 27.4144 9.77734 23.8143 9.77734H20.555H19.7402"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M28.7031 29.333V29.333C28.7031 25.7329 25.7846 22.8145 22.1845 22.8145V22.8145C18.5845 22.8145 15.666 25.7329 15.666 29.333V29.333"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M15.6659 29.333V29.333C15.6659 25.7329 12.7475 22.8145 9.14742 22.8145V22.8145C5.54735 22.8145 2.62891 25.7329 2.62891 29.333V29.333"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default FacesIcon;
