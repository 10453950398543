/**
 * Number to currency
 * Convert a number (in cents) to a currency string e.g. '$123.45' (or '123.45' if removeSymbol is true)
 * @param number | Number to convert (in cents)
 * @param removeSymbol | Remove the dollar symbol
 */
export const numberToCurrency = (number: number, removeSymbol?: boolean) => {
  let currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number / 100);

  // If the number has been rounded to 1 decimal place, add an extra 0 ('currency' is a string)
  if (currency.charAt(currency.length - 2) === ".") {
    currency = `${currency}0`;
  }

  // Remove '$' symbol
  if (removeSymbol) {
    currency = currency.substring(1);
  }

  return currency;
};
