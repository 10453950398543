import { IPlanObject } from "@/api";
import { PUBLISH_LIVE_PLAN_SLUGS, SELECT_PLAN_SLUGS } from "@/config";

const getLivePlans = (plans: IPlanObject[]) => plans.filter(({ attributes: { status } }) => status === 1);

const getLiveSelectPlans = (plans: IPlanObject[]) =>
  getLivePlans(plans).filter(({ attributes: { slug } }) => SELECT_PLAN_SLUGS.all.includes(slug));

// We use fallbacks here, because during rollouts the defaultFree might not be available yet. The default free is used for new users.
const findDefaultFreePlanInPlans = (plans: IPlanObject[]) =>
  plans.find(
    ({ attributes: { status, "default-free": defaultFree, slug } }) =>
      status === 1 && slug === SELECT_PLAN_SLUGS.defaultFree && defaultFree,
  ) ||
  plans.find(
    ({ attributes: { status, "default-free": defaultFree, slug } }) =>
      status === 1 && SELECT_PLAN_SLUGS.allFree.includes(slug) && defaultFree,
  ) ||
  plans.find(({ attributes: { slug } }) => SELECT_PLAN_SLUGS.allFree.includes(slug));

// We use a fallbacks because during rollouts the defaultPro might not be available yet. The default pro is used for users who upgrade.
const findDefaultProPlanInPlans = (plans: IPlanObject[]) =>
  plans.find(({ attributes: { status, slug } }) => status === 1 && slug === SELECT_PLAN_SLUGS.defaultPro) ||
  plans.find(({ attributes: { status, slug } }) => status === 1 && SELECT_PLAN_SLUGS.allPro.includes(slug)) ||
  plans.find(({ attributes: { slug } }) => SELECT_PLAN_SLUGS.allPro.includes(slug));

const getSelectAndPublishProPlans = (plans: IPlanObject[]) => {
  const proSelectPlanSlug = findDefaultProPlanInPlans(plans)?.attributes.slug;

  return plans
    .filter(({ attributes: { slug } }) => [PUBLISH_LIVE_PLAN_SLUGS.pro, proSelectPlanSlug].includes(slug))
    .sort((a) => (a.attributes.slug === proSelectPlanSlug ? -1 : 1));
};

export {
  getLivePlans,
  getLiveSelectPlans,
  findDefaultFreePlanInPlans,
  findDefaultProPlanInPlans,
  getSelectAndPublishProPlans,
};
