import { ReactNode } from "react";
import { Image as DatoImage } from "react-datocms";
import { FacebookIcon, InstagramIcon, TiktokIcon, WebsiteIcon, YouTubeIcon } from "@/components";
import { IMAGE_OBJECT_POSITION } from "@/constants";
import { ArtistStyle } from "@/types";

type EditStyleHeroSectionProps = Pick<ArtistStyle, "name" | "description" | "artist">;

function EditStyleHeroSection({ name, description, artist }: EditStyleHeroSectionProps) {
  return (
    <section className="py-[53px] bg-mankey">
      <div className="max-w-[808px] px-5 mx-auto">
        <div className="relative mb-10 h-0 w-[180px] h-[180px] md:w-[265px] md:h-[265px] mx-auto">
          <DatoImage
            data={{ ...artist.profilePicture.responsiveImage }}
            objectFit="cover"
            objectPosition={IMAGE_OBJECT_POSITION}
            className="rounded-full absolute top-0 right-0 bottom-0 left-0"
          />
        </div>

        <h1 className="text-h2 md:text-h1 font-semibold leading-compact mb-4 text-center">
          {name} by {artist.name}
        </h1>
        <div className="flex gap-1 justify-center">
          {artist.facebookUrl && (
            <SocialLink url={artist.facebookUrl}>
              <FacebookIcon />
            </SocialLink>
          )}
          {artist.tiktokUrl && (
            <SocialLink url={artist.tiktokUrl}>
              <TiktokIcon />
            </SocialLink>
          )}
          {artist.instagramUrl && (
            <SocialLink url={artist.instagramUrl}>
              <InstagramIcon />
            </SocialLink>
          )}
          {artist.youtubeUrl && (
            <SocialLink url={artist.youtubeUrl}>
              <YouTubeIcon />
            </SocialLink>
          )}
          {artist.websiteUrl && (
            <SocialLink url={artist.websiteUrl}>
              <WebsiteIcon />
            </SocialLink>
          )}
        </div>
        <p className="mt-4">{description}</p>
      </div>
    </section>
  );
}

type SocialLinkProps = {
  url: string;
  children: ReactNode;
};

function SocialLink({ url, children }: SocialLinkProps) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="min-w-[48px] min-h-[48px] flex flex-col items-center justify-center rounded-sm hover:bg-off-white bg-transparent transition-colors"
    >
      {children}
    </a>
  );
}

export { EditStyleHeroSection };
