import { createContext, useReducer, useContext, Dispatch } from "react";
import { ModalActionType, ModalState, ModalAction } from "@narrative-software/narrative-web-ui";

const initialState: ModalState = {
  size: "small",
  isOpen: false,
  hideBorder: false,
  showOverflow: false,
  children: null,
};

const ModalStateContext = createContext<ModalState>(initialState);
const ModalDispatchContext = createContext<Dispatch<ModalAction>>(() => ({}));

const reducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case ModalActionType.Open: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case ModalActionType.Close: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case ModalActionType.Toggle: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }
    case ModalActionType.SetContent: {
      return {
        isOpen: true,
        size: action.payload?.size || initialState.size,
        hideBorder: action.payload?.hideBorder || initialState.hideBorder,
        showOverflow: action.payload?.showOverflow || initialState.showOverflow,
        children: action.payload?.children || initialState.children,
      };
    }
    default: {
      throw new Error(`Unknown modal action: ${action.type}`);
    }
  }
};

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ModalDispatchContext.Provider value={dispatch}>
      <ModalStateContext.Provider value={state}>{children}</ModalStateContext.Provider>
    </ModalDispatchContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error("useModalState must be used within a ModalProvider");
  }
  return context;
};

export const useModalDispatch = () => {
  const context = useContext(ModalDispatchContext);
  if (context === undefined) {
    throw new Error("useModalDispatch must be used within a ModalProvider");
  }
  return context;
};
