import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { BRANCH, COMMIT_REF } from "@/config";

/**
 * Referrer
 */
const getReferrer = () => {
  const key = "narrative-event-referrer";
  let referrer = window.sessionStorage.getItem(key);
  if (!referrer) {
    referrer = document.referrer;
    if (referrer) {
      window.sessionStorage.setItem(key, referrer);
    }
  }
  return referrer || null;
};

/**
 * Location
 */
const getLocation = (_path = "") => {
  const path = _path || window.location.pathname;
  const path_array = path.split("/").filter((value) => value);
  return {
    path,
    path_array,
    domain: window.location.hostname,
  };
};

/**
 * Page load
 */
export const PageLoad = {
  id: "",
  startDate: moment(),
  getId() {
    return this.id || (this.id = uuidv4());
  },
  resetId() {
    this.id = "";
    return this.getId();
  },
  setStartDate() {
    this.startDate = moment();
  },
  getDiff() {
    return moment().diff(this.startDate, "millisecond", true);
  },
};

/**
 * Schema
 */
export interface EventSchema {
  domain: string;
  path: string;
  path_array: string[];
  referrer: string | null;
  branch: string;
  commit_hash: string;
  page_load_id: string;
  ms_since_page_load: number;
  website_locale: string;
}

export const getSchema = (path = ""): EventSchema => ({
  ...getLocation(path),
  referrer: getReferrer(),
  branch: BRANCH || "",
  commit_hash: COMMIT_REF || "",
  page_load_id: PageLoad.getId(),
  ms_since_page_load: PageLoad.getDiff(),
  website_locale: window.document.documentElement.lang,
});
