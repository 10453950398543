import classNames from "classnames";
import { EmailIcon, GoogleButton } from "@/components";
import { ACCOUNT_BASE_URL, EXTERNAL } from "@/config";
import { Product } from "@/types";
import { AnchorButton, ButtonAppearance, ButtonColour } from "@narrative-software/narrative-web-ui";

interface Props {
  product: Product;
  className?: string;
  emailButtonColour?: ButtonColour;
  emailButtonAppearance?: ButtonAppearance;
  ctaHref?: string;
}

const StartFreeButtons: React.FC<Props> = ({
  product,
  className,
  emailButtonColour = "black",
  emailButtonAppearance,
  ctaHref,
}) => {
  const PRODUCT = product.toUpperCase() as "SELECT" | "PUBLISH";
  const href = ctaHref
    ? ctaHref.replace("<account-site>", ACCOUNT_BASE_URL as string)
    : EXTERNAL[PRODUCT].GET_STARTED.HREF;

  return (
    <div className={classNames("flex flex-col gap-y-3 w-full sm:flex-row sm:gap-x-5", className)}>
      <AnchorButton href={href} colour={emailButtonColour} appearance={emailButtonAppearance}>
        <EmailIcon className="mr-2.5 flex-shrink-0" />
        Start free with email
      </AnchorButton>
      <GoogleButton href={href}>Start free with Google</GoogleButton>
    </div>
  );
};

export default StartFreeButtons;
