const YoutubeIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84382 12.2713L9.8431 5.63391L16.1217 8.96404L9.84382 12.2713ZM23.6293 4.48496C23.6293 4.48496 23.402 2.85718 22.7056 2.14036C21.8218 1.19912 20.8313 1.19469 20.3774 1.14006C17.1256 0.900879 12.2478 0.900879 12.2478 0.900879H12.2376C12.2376 0.900879 7.35986 0.900879 4.10801 1.14006C3.65342 1.19469 2.66363 1.19912 1.77914 2.14036C1.08273 2.85718 0.856158 4.48496 0.856158 4.48496C0.856158 4.48496 0.623779 6.39697 0.623779 8.30823V10.1006C0.623779 12.0126 0.856158 13.9239 0.856158 13.9239C0.856158 13.9239 1.08273 15.5517 1.77914 16.2685C2.66363 17.2098 3.8248 17.1802 4.34184 17.2784C6.20087 17.46 12.2427 17.5161 12.2427 17.5161C12.2427 17.5161 17.1256 17.5087 20.3774 17.2696C20.8313 17.2142 21.8218 17.2098 22.7056 16.2685C23.402 15.5517 23.6293 13.9239 23.6293 13.9239C23.6293 13.9239 23.8617 12.0126 23.8617 10.1006V8.30823C23.8617 6.39697 23.6293 4.48496 23.6293 4.48496Z"
      fill="currentColor"
    />
  </svg>
);

export default YoutubeIcon;
