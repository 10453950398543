import { StyleTile } from "@/components/shared/StyleTile";
import { ArtistStyle } from "@/types";

type EditStylesBodySectionProps = {
  artistStyles: ArtistStyle[];
};

function EditStylesBodySection({ artistStyles }: EditStylesBodySectionProps) {
  return (
    <section className="bg-mankey md:pb-[120px] pb-[80px]">
      <div className="max-w-[1232px] px-5 mx-auto">
        <div className="grid md:grid-cols-2 grid-flow-row gap-5 grid-cols-1 lg:grid-cols-3">
          {artistStyles.map((style) => (
            <StyleTile
              key={style.id}
              slug={style.slug}
              description={style.summary}
              image1After={style.image1After}
              name={style.name}
              artist={style.artist}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export { EditStylesBodySection };
