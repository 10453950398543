const MessengerIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.64C0 4.953 5.241 0 12 0C18.759 0 24 4.956 24 11.643C24 18.33 18.759 23.283 12 23.283C10.785 23.283 9.621 23.121 8.526 22.821C8.313 22.764 8.085 22.779 7.884 22.869L5.502 23.919C4.878 24.195 4.176 23.751 4.155 23.07L4.089 20.934C4.083 20.67 3.963 20.424 3.768 20.25C1.434 18.162 0 15.138 0 11.64ZM8.31913 9.45309L4.79413 15.0451C4.45513 15.5821 5.11513 16.1851 5.61913 15.8011L9.40513 12.9271C9.66013 12.7321 10.0141 12.7321 10.2721 12.9241L13.0771 15.0271C13.9201 15.6571 15.1201 15.4381 15.6811 14.5471L19.2091 8.95809C19.5451 8.42109 18.8851 7.81509 18.3811 8.19909L14.5951 11.0731C14.3401 11.2681 13.9861 11.2681 13.7281 11.0761L10.9231 8.97309C10.0801 8.34309 8.88013 8.56209 8.31913 9.45309Z"
      fill="currentColor"
    />
  </svg>
);

export default MessengerIcon;
