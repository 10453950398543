const ShipIcon: React.FC = () => (
  <svg width="30" height="33" viewBox="0 0 30 33" fill="none">
    <path d="M0 21H18V18L29 18C29 32 18 31 14 31H0" stroke="#1C1C1C" strokeWidth="2" />
    <path d="M14 21V10H0.5" stroke="#1C1C1C" strokeWidth="2" />
    <rect x="8" y="4" width="4" height="6" stroke="#1C1C1C" strokeWidth="2" />
    <rect x="1" y="4" width="4" height="6" stroke="#1C1C1C" strokeWidth="2" />
    <rect width="4" height="3" transform="matrix(1 0 0 -1 8 4)" stroke="#1C1C1C" strokeWidth="2" />
    <rect width="4" height="3" transform="matrix(1 0 0 -1 1 4)" stroke="#1C1C1C" strokeWidth="2" />
    <rect x="5.5" y="24.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="1.5" y="24.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="5.5" y="17.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="5.5" y="13.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="9.5" y="24.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="9.5" y="17.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="1.5" y="17.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="9.5" y="13.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="1.5" y="13.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="13.5" y="24.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
    <rect x="17.5" y="24.5" width="1" height="1" fill="#C4C4C4" stroke="#1C1C1C" />
  </svg>
);

export default ShipIcon;
