import React from "react";
import { stripParagraphTags, enhanceContent } from "@/utils";

interface Props extends React.HTMLAttributes<HTMLElement> {
  element?: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
}

export const HtmlToElement: React.FC<Props> = ({ children, element = "div", ...props }) => {
  // If children is an HTML string
  if (typeof children === "string") {
    const htmlString = element !== "div" ? stripParagraphTags(children) : children;
    return React.createElement(element, props, enhanceContent(htmlString));
  }
  // If children is a React node
  return React.createElement(element, props, children);
};

export default HtmlToElement;
