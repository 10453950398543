import React from "react";
import classNames from "classnames";

interface Props extends React.ComponentPropsWithRef<"section"> {
  customPadding?: boolean;
  isFirstSection?: boolean;
}

const AwardsSection: React.FC<Props> = React.forwardRef<HTMLElement, Props>(
  ({ children, className, customPadding, isFirstSection, ...rest }, ref) => (
    <section
      {...rest}
      ref={ref}
      className={classNames(className, {
        "py-8 sm:py-10 lg:py-18 xl:py-20": !customPadding,
        "-mt-2 lg:-mt-5 xl:-mt-8": isFirstSection,
      })}
    >
      {children}
    </section>
  ),
);

AwardsSection.displayName = "AwardsSection";

export default AwardsSection;
