import classNames from "classnames";
import Link from "next/link";
import { RightArrowIcon } from "@/components";

/**
 * @component LinkWithArrow - "link -->"
 *
 * @prop href               - the link href
 * @prop className          - any additional classes to add to the link
 * @prop isExternal         - whether the link is external or not
 * @prop isNewTab           - whether the link should open in a new tab
 * @prop shouldChill        - removes uppercasing and full bolding style
 * @prop children           - the link text
 */
const LinkWithArrow = ({
  href,
  className,
  isExternal,
  isNewTab,
  shouldChill = false,
  children,
}: {
  href: string;
  className?: string;
  isExternal?: boolean;
  isNewTab?: boolean;
  shouldChill?: boolean;
  children: React.ReactNode;
}) => {
  let target;
  let rel;

  if (isNewTab) {
    target = "_blank";
    rel = "noreferrer noopener";
  }

  const Anchor = (
    <a
      href={href}
      target={target}
      rel={rel}
      className={classNames(
        "inline-flex items-center py-1 text-xxs tracking-wider cursor-pointer hover:underline",
        {
          uppercase: !shouldChill,
          "font-semibold": !shouldChill,
          "font-medium": shouldChill,
        },
        className,
      )}
    >
      <span className="mr-2">{children}</span>
      <RightArrowIcon />
    </a>
  );

  return isExternal ? Anchor : <Link href={href}>{Anchor}</Link>;
};

export default LinkWithArrow;
