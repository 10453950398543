const WebsiteIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      d="M9.88651 0C4.91851 0 0.895508 4.032 0.895508 9C0.895508 13.968 4.91851 18 9.88651 18C14.8635 18 18.8955 13.968 18.8955 9C18.8955 4.032 14.8635 0 9.88651 0ZM16.1235 5.4H13.4685C13.1805 4.275 12.7665 3.195 12.2265 2.196C13.8825 2.763 15.2595 3.915 16.1235 5.4ZM9.89551 1.836C10.6425 2.916 11.2275 4.113 11.6145 5.4H8.17651C8.56351 4.113 9.14851 2.916 9.89551 1.836ZM2.92951 10.8C2.78551 10.224 2.69551 9.621 2.69551 9C2.69551 8.379 2.78551 7.776 2.92951 7.2H5.97151C5.89951 7.794 5.84551 8.388 5.84551 9C5.84551 9.612 5.89951 10.206 5.97151 10.8H2.92951ZM3.66751 12.6H6.32251C6.61051 13.725 7.02451 14.805 7.56451 15.804C5.90851 15.237 4.53151 14.094 3.66751 12.6ZM6.32251 5.4H3.66751C4.53151 3.906 5.90851 2.763 7.56451 2.196C7.02451 3.195 6.61051 4.275 6.32251 5.4ZM9.89551 16.164C9.14851 15.084 8.56351 13.887 8.17651 12.6H11.6145C11.2275 13.887 10.6425 15.084 9.89551 16.164ZM12.0015 10.8H7.78951C7.70851 10.206 7.64551 9.612 7.64551 9C7.64551 8.388 7.70851 7.785 7.78951 7.2H12.0015C12.0825 7.785 12.1455 8.388 12.1455 9C12.1455 9.612 12.0825 10.206 12.0015 10.8ZM12.2265 15.804C12.7665 14.805 13.1805 13.725 13.4685 12.6H16.1235C15.2595 14.085 13.8825 15.237 12.2265 15.804ZM13.8195 10.8C13.8915 10.206 13.9455 9.612 13.9455 9C13.9455 8.388 13.8915 7.794 13.8195 7.2H16.8615C17.0055 7.776 17.0955 8.379 17.0955 9C17.0955 9.621 17.0055 10.224 16.8615 10.8H13.8195Z"
      fill="currentColor"
    />
  </svg>
);

export default WebsiteIcon;
