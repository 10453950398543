const LinkedInIcon: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6501 21H20.9982L21 13.3051C21 9.52742 20.1874 6.62374 15.7806 6.62374C14.0982 6.56115 12.4536 7.4338 11.6037 8.88946V6.97256H7.42972V20.9995H11.7776V14.0604C11.7776 12.2306 12.1241 10.4584 14.3888 10.4584C16.6211 10.4584 16.6501 12.5523 16.6501 14.1789V21Z"
      fill="currentColor"
    />
    <path
      d="M0 2.52764C0 3.91426 1.13954 5.05514 2.52374 5.05514C3.90767 5.05501 5.04643 3.91347 5.04643 2.52711C5.04616 1.14075 3.90728 0 2.52321 0C1.13901 0 0 1.14101 0 2.52764Z"
      fill="currentColor"
    />
    <path d="M0.345197 21H4.69769V6.97256H0.345197V21Z" fill="currentColor" />
  </svg>
);

export default LinkedInIcon;
