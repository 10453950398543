interface Props {
  className?: string;
}

const RightArrowIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} width="30" height="12" viewBox="0 0 30 12" fill="none">
    <line y1="6" x2="28" y2="6" stroke="currentColor" strokeWidth="2" />
    <path d="M23.0762 1L28.1143 6.03809L23.0762 11.0762" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default RightArrowIcon;
