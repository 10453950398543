import { useState, useRef, useEffect } from "react";

const useToggleOverflow = <T extends HTMLElement = HTMLDivElement>() => {
  const ref = useRef<T>(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const { current: element } = ref;

    const update = () => {
      if (!element) return;

      setHasOverflow(element.scrollHeight > element.clientHeight);
    };

    if (element) {
      update();
    }
  }, [ref.current]);

  return { ref, hasOverflow, isOpen, toggle: () => setOpen((prev) => !prev) };
};

export { useToggleOverflow };
