import React, { ElementType } from "react";
import classNames from "classnames";

const baseClassName = "inline-flex items-center justify-center font-semibold leading-tighter";
const primaryClassName = "text-stealth-bomber bg-white transition-shadow";
const secondaryClassName = "text-white bg-transparent transition-all hover:text-stealth-bomber hover:bg-white";
const tertiaryClassName = "py-2 text-white underline";
const primaryAndSecondaryClassName =
  "w-full h-14 px-4 border border-white rounded-sm text-center max-w-95 hover:shadow-[0_2px_20px_rgba(255,255,255,0.5)]";

const Arrow: React.FC = () => (
  <span className="ml-3 overflow-hidden" aria-hidden>
    <svg
      width="74"
      height="16"
      viewBox="0 0 74 16"
      fill="none"
      className="flex-shrink-0 translate-x-[-10px] transition-transform group-hover:translate-x-0"
    >
      <line x1="1" y1="8" x2="72" y2="8" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path d="M67 2L73 8L67 14" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  </span>
);

type Props<T extends ElementType> = React.ComponentPropsWithRef<T> & {
  variant?: "primary" | "secondary" | "tertiary";
};

export const ArrowButton = React.forwardRef<HTMLButtonElement, Props<"button">>(
  ({ className, children, variant = "tertiary", ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      className={classNames(baseClassName, className, "disabled:opacity-50 disabled:cursor-not-allowed", {
        group: !props.disabled,
        [primaryClassName]: variant === "primary",
        [`${secondaryClassName} disabled:hover:text-white disabled:hover:bg-transparent`]: variant === "secondary",
        [tertiaryClassName]: variant === "tertiary",
        [`${primaryAndSecondaryClassName} disabled:hover:shadow-none`]:
          variant === "primary" || variant === "secondary",
      })}
    >
      {children}
      {variant !== "secondary" && <Arrow />}
    </button>
  ),
);

export const ArrowAnchorButton = React.forwardRef<HTMLAnchorElement, Props<"a">>(
  ({ className, children, variant = "tertiary", ...props }, ref) => (
    <a
      {...props}
      ref={ref}
      className={classNames("group", baseClassName, className, {
        [primaryClassName]: variant === "primary",
        [secondaryClassName]: variant === "secondary",
        [tertiaryClassName]: variant === "tertiary",
        [primaryAndSecondaryClassName]: variant === "primary" || variant === "secondary",
      })}
    >
      {children}
      {variant !== "secondary" && <Arrow />}
    </a>
  ),
);

ArrowButton.displayName = "ArrowButton";
ArrowAnchorButton.displayName = "ArrowAnchorButton";

export default ArrowButton;
