import { Image as DatoImage, ResponsiveImageType } from "react-datocms";
import classNames from "classnames";
import Image, { StaticImageData } from "next/image";
import { FixedAspectRatioContainer } from "@/components";
import { VideoFields } from "../ProductFeatures/ProductFeatures";
import VideoPlayer, { VideoPlayerProps } from "./VideoPlayer";

type ImageProps = {
  width: number;
  height: number;
  mainImageSrc: StaticImageData;
  videoProps?: never;
  datoVideoOrImage?: never;
};

type VideoProps = {
  width: number;
  height: number;
  mainImageSrc?: never;
  videoProps: Pick<VideoPlayerProps, "sources" | "poster">;
  datoVideoOrImage?: never;
};

type DatoVideoOrImage = {
  width?: number;
  height?: number;
  mainImageSrc?: never;
  videoProps?: never;
  datoVideoOrImage: {
    responsiveImage: ResponsiveImageType;
    video: VideoFields;
  };
};

type ImageWithTextProps = {
  title: string;
  orientation: "image-left" | "image-right";
  icon: React.ReactNode;
  overlaidImages?: React.ReactNode;
  className?: string;
  htmlDescription?: boolean;
} & (ImageProps | VideoProps | DatoVideoOrImage);

const ImageWithText: React.FC<ImageWithTextProps> = ({
  title,
  orientation,
  icon,
  datoVideoOrImage,
  mainImageSrc,
  videoProps,
  width: widthProvided = 598,
  height: heightProvided = 392,
  overlaidImages,
  className,
  children,
  htmlDescription = false,
}) => {
  const canAutoSize =
    datoVideoOrImage &&
    ((datoVideoOrImage.responsiveImage &&
      datoVideoOrImage.responsiveImage.width &&
      datoVideoOrImage.responsiveImage.height) ||
      (datoVideoOrImage.video && datoVideoOrImage.video.width && datoVideoOrImage.video.height));

  const width = canAutoSize
    ? datoVideoOrImage.responsiveImage?.width || datoVideoOrImage.video?.width || widthProvided
    : widthProvided;
  const height = canAutoSize
    ? datoVideoOrImage.responsiveImage?.height || datoVideoOrImage.video?.height || heightProvided
    : heightProvided;

  return (
    <div
      className={classNames(
        "flex flex-col items-center w-full text-center md:text-left",
        {
          "md:flex-row": orientation === "image-left",
          "md:flex-row-reverse": orientation === "image-right",
        },
        className,
      )}
    >
      <FixedAspectRatioContainer className="relative flex-grow w-full md:w-auto" aspectRatio={width / height}>
        {datoVideoOrImage && datoVideoOrImage.responsiveImage && (
          <DatoImage
            priority
            data={{
              ...datoVideoOrImage.responsiveImage,
            }}
            objectFit="cover"
            layout="fill"
            className="absolute top-0 right-0 bottom-0 left-0"
          />
        )}
        {mainImageSrc && (
          <Image
            src={mainImageSrc}
            alt={title}
            width={width}
            height={height}
            quality="90"
            placeholder="blur"
            priority
          />
        )}
        {videoProps && <VideoPlayer {...videoProps} width={width} height={height} className="rounded-md" />}
        {datoVideoOrImage && datoVideoOrImage.video && (
          <VideoPlayer
            poster={datoVideoOrImage.video.thumbnailUrl}
            sources={[
              {
                src: datoVideoOrImage.video.mp4Url,
                type: "video/mp4",
              },
            ]}
            width={width}
            height={height}
            className="rounded-md"
          />
        )}
        {overlaidImages}
      </FixedAspectRatioContainer>
      <div
        className={classNames("flex flex-col w-full md:w-19/40 items-center md:items-start mt-8 md:mt-0", {
          "md:pl-8 md:ml-8 lg:pl-10 lg:ml-10": orientation === "image-left",
          "md:pr-8 md:mr-8 lg:pr-10 lg:mr-10": orientation === "image-right",
        })}
      >
        <div className="flex mb-4 lg:-mt-2">{icon}</div>
        <h3 className="mb-4 text-h3 font-semibold">{title}</h3>
        {htmlDescription ? (
          <div className="text-lg" dangerouslySetInnerHTML={{ __html: children as string }} />
        ) : (
          <p className="text-lg">{children}</p>
        )}
      </div>
    </div>
  );
};

export default ImageWithText;
