import { FormSuccessIcon } from "@/components";

const SuccessMessage: React.FC = () => (
  <div className="flex flex-col items-center">
    <h2 className="text-h3 font-semibold leading-compact mb-6 lg:text-h2">Thanks!</h2>
    <p className="mb-8">A member of our team will be in touch with you shortly.</p>
    <div className="ml-4">
      <FormSuccessIcon />
    </div>
  </div>
);

export default SuccessMessage;
