import React from "react";
import { Image as DatoImage } from "react-datocms";
import classNames from "classnames";
import { ArtistStyle } from "@/types";

const SLIDE_WIDTH = 182;
const SLIDE_GAP = 20;

type ArtistStylesCarouselSlideProps = {
  artistStyle: ArtistStyle;
  selected: boolean;
  onClick: () => void;
};

function ArtistStylesCarouselSlide({ artistStyle, selected, onClick }: ArtistStylesCarouselSlideProps) {
  return (
    <button
      className={classNames(`relative pointer-events-auto group`, {
        "text-white": selected,
      })}
      style={{
        width: `${SLIDE_WIDTH}px`,
        minWidth: `${SLIDE_WIDTH}px`,
        maxWidth: `${SLIDE_WIDTH}px`,
        margin: `0 ${SLIDE_GAP / 2}px`,
      }}
      onClick={onClick}
    >
      {selected && (
        <div
          className={classNames(
            "bg-[url('/images/selected-artist-style-slide-bg.png')] bg-contain bg-no-repeat absolute top-0 right-0 bottom-0 left-0 opacity-0 transition-opacity",
            { "opacity-100": selected },
          )}
        />
      )}
      {!selected && (
        <div
          className={
            "bg-[url('/images/hovered-artist-style-slide-bg.png')] bg-contain bg-no-repeat absolute top-0 right-0 bottom-0 left-0 opacity-0 transition-opacity group-hover:opacity-100"
          }
        />
      )}
      <div className="flex flex-col items-center relative pt-4 pb-[30px] mb-3">
        <div className="relative w-[120px] h-[120px]">
          <DatoImage
            data={artistStyle.artist.profilePicture.responsiveImage}
            className="rounded-full"
            pictureClassName="object-cover object-center"
            style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
          />
        </div>

        <p className="font-semibold mt-3">{artistStyle.artist.name}</p>
        <p className="text-xxs">{artistStyle.name}</p>
      </div>
    </button>
  );
}

export { ArtistStylesCarouselSlide };
