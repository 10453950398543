import { ArrowButton, Container } from "@/components";
import { MIN_CHARGEABLE_ENTRIES } from "./EntryForm";

type Props = {
  entryCount: number;
  onAddEntry(): void;
  isError?: boolean;
  disableSubmit?: boolean;
  disableAddEntry?: boolean;
};

const FormActions: React.FC<Props> = ({ entryCount, onAddEntry, isError, disableSubmit, disableAddEntry }) => (
  <div className="fixed left-0 bottom-0 w-full py-3 border-t border-white bg-black md:py-4.5">
    <Container>
      <div className="flex flex-col gap-3 items-center justify-between md:flex-row md:gap-0">
        {isError && (
          <p className="px-4 text-14 text-red-600 text-center leading-tight md:order-2">
            Please check the form for errors
          </p>
        )}

        <ArrowButton type="submit" variant="primary" className="h-12 md:h-14 md:order-3" disabled={disableSubmit}>
          Continue to {entryCount < MIN_CHARGEABLE_ENTRIES ? "upload" : "payment"}
        </ArrowButton>

        <ArrowButton
          type="button"
          variant="secondary"
          className="h-12 md:h-14 md:order-1"
          disabled={disableAddEntry}
          onClick={onAddEntry}
        >
          + Add another entry
        </ArrowButton>
      </div>
    </Container>
  </div>
);

export default FormActions;
