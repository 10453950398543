import { useState } from "react";
import { ProductInterval } from "@/api";

const useBillingIntervalToggle = () => {
  const [interval, setInterval] = useState<ProductInterval>(ProductInterval.Year);

  return [interval, setInterval] as const;
};

export { useBillingIntervalToggle };
