import React, { ElementType } from "react";
import classNames from "classnames";

const baseClassName =
  "relative z-10 inline-flex items-center justify-center px-5 py-4 border-2 border-current text-14 font-semibold text-center tracking-wider cursor-pointer transition-colors duration-150 hover:text-white hover:border-transparent";

const pseudoElementStyles = `
  .jazzy-button::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    opacity: 0;
    transition: opacity 0.15s;
    background: linear-gradient(90deg, #425d9c 19%, #b44893 83%, #f16370 100%);
  }
  .jazzy-button-rounded::before {
    border-radius: 9999px;
  }
  .jazzy-button:hover::before {
    opacity: 1;
  }
`;

type Props<T extends ElementType> = React.ComponentPropsWithRef<T> & {
  rounded?: boolean;
};

export const JazzyButton = React.forwardRef<HTMLButtonElement, Props<"button">>(
  ({ className, children, rounded = true, ...props }, ref) => (
    <>
      <button
        {...props}
        ref={ref}
        className={classNames("jazzy-button", baseClassName, className, {
          "jazzy-button-rounded rounded-full": rounded,
        })}
      >
        {children}
      </button>
      <style jsx>{pseudoElementStyles}</style>
    </>
  ),
);

export const JazzyAnchorButton = React.forwardRef<HTMLAnchorElement, Props<"a">>(
  ({ className, children, rounded = true, ...props }, ref) => (
    <>
      <a
        {...props}
        ref={ref}
        className={classNames("jazzy-button", baseClassName, className, {
          "jazzy-button-rounded rounded-full": rounded,
        })}
      >
        {children}
      </a>
      <style jsx>{pseudoElementStyles}</style>
    </>
  ),
);

JazzyButton.displayName = "JazzyButton";
JazzyAnchorButton.displayName = "JazzyAnchorButton";

export default JazzyButton;
