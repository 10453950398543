import { AnchorHTMLAttributes } from "react";
import classNames from "classnames";
import { EditPlan } from "@/types";

type EditPlanLinkProps = {
  plan: EditPlan;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

function EditPlanLink({ plan, ...props }: EditPlanLinkProps) {
  return (
    <a
      className={classNames(
        "w-full p-[0.9rem] font-medium text-base block cursor-pointer rounded-sm transition-colors transition-opacity",
        {
          border: !plan.highlighted,
          "border-ekans": !plan.highlighted,
          "text-ekans": !plan.highlighted,
          "hover:bg-ball-bearing": !plan.highlighted,
          "text-white": plan.highlighted,
          "bg-gradient-to-r from-ekans from-60% via-[#B44893] from-126% to-[#B1017C]": plan.highlighted,
          "hover:opacity-90": plan.highlighted,
        },
      )}
      {...props}
    >
      {plan.ctaButtonText}
    </a>
  );
}

export default EditPlanLink;
