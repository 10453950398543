import { useEffect, useRef } from "react";
import classNames from "classnames";
import Image from "next/image";
import PlanTile from "@/modules/edit/EditPlanTile";
import { EditPlan } from "@/types";
import { CreditPurchasingPack } from "@/utils";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

type EditPlansSectionProps = {
  packs: CreditPurchasingPack[];
  plans: EditPlan[];
  plansTitle: string;
  plansDescription?: string;
  withBgImage?: boolean;
  className?: string;
  withHorizontalRule?: boolean;
};

function EditPlansSection({
  plans,
  packs,
  plansTitle,
  plansDescription,
  withBgImage = true,
  withHorizontalRule = false,
  className,
}: EditPlansSectionProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      scrollContainerRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className={classNames("relative pt-20 pb-16 md:pt-[94px] md:pb-[127px]", className, {
        "bg-mankey": !withBgImage,
        "bg-ekans": withBgImage,
      })}
    >
      {withBgImage && (
        <Image
          className="absolute top-0 right-0 bottom-0 left-0 object-cover object-center"
          src="/images/textures/ekans-bg@2x.png"
          layout="fill"
          alt=""
          sizes="100vw"
          quality={100}
          priority
        />
      )}
      <div className="max-w-[986px] mx-auto relative text-center">
        <div
          className={classNames("px-5", {
            "text-white": withBgImage,
            "text-stealth-bomber": !withBgImage,
          })}
        >
          {withHorizontalRule && (
            <HorizontalRule
              className={classNames("mb-6 mx-auto", {
                "border-white": withBgImage,
                "border-stealth-bomber": !withBgImage,
              })}
            />
          )}
          <h2 className="text-h2 font-semibold leading-compact mb-3">{plansTitle}</h2>
          {plansDescription && <p>{plansDescription}</p>}
        </div>

        <div
          className="flex mt-12 gap-2.5 md:gap-5 overflow-x-auto snap-x snap-mandatory hide-scrollbar px-2.5 md:px-5"
          ref={scrollContainerRef}
        >
          {plans.map((plan) => (
            <PlanTile
              key={plan.id}
              plan={plan}
              packs={plan.isPaidPlan ? packs : []}
              className="snap-start md:scroll-ml-5 scroll-ml-2.5"
            />
          ))}
        </div>

        <div className="flex justify-center md:hidden">
          {plans.map((plan, i) => (
            <button
              key={plan.id}
              className={classNames("h-12 w-12 flex flex-col items-center justify-center")}
              onClick={() => handleNavButtonClick(scrollContainerRef.current, plans.length, i)}
            >
              <div
                className="rounded-full bg-white h-2.5 w-2.5 transition-opacity"
                data-nav-dot
                style={{ opacity: i === 0 ? "1" : "0.2" }}
              />
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

function getActiveDotIndex(scrollPercent: number, totalDots: number): number {
  // Calculate the threshold for each dot based on total dots
  const dotThreshold = 100 / totalDots;

  // Loop through each dot index
  for (let i = 0; i < totalDots; i++) {
    // Check if scroll percentage falls within the current dot's threshold range
    if (scrollPercent >= i * dotThreshold && scrollPercent < (i + 1) * dotThreshold) {
      return i;
    }
  }

  // If no dot matches, return the last dot index (assuming edge case handling)
  return totalDots - 1;
}

function handleScroll(e: Event) {
  const el = e.currentTarget as HTMLDivElement;
  const horizontalScrollPercentage = (100 * el.scrollLeft) / (el.scrollWidth - el.clientWidth);
  const navDots = el.nextElementSibling?.querySelectorAll("[data-nav-dot]");
  if (navDots) {
    const activeDotIndex = getActiveDotIndex(horizontalScrollPercentage, navDots.length);
    navDots.forEach((dot, i) => ((dot as HTMLDivElement).style.opacity = activeDotIndex === i ? "1" : "0.2"));
  }
}

function handleNavButtonClick(scrollContainer: HTMLDivElement | null, totalButtons: number, i: number) {
  if (scrollContainer) {
    const width = scrollContainer.scrollWidth - scrollContainer.clientWidth;
    scrollContainer.scrollTo({
      left: (width / totalButtons) * i + 1,
      behavior: "smooth",
    });
  }
}

export { EditPlansSection };
