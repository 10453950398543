import classNames from "classnames";
import { Section, Container, BlogCard, LinkWithArrow } from "@/components";
import { ROUTES } from "@/config";
import { IBlogPost } from "@/types";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

interface Props {
  title: string;
  items: IBlogPost[];
}

const BlogThreeUp: React.FC<Props> = ({ title, items }) => (
  <Section className="bg-white">
    <Container>
      <div className="relative text-center">
        <HorizontalRule bottomMargin />
        <h1 className="text-h2 font-semibold leading-tight">{title}</h1>
        <LinkWithArrow href={`/${ROUTES.BLOG.SLUG}`} className="mt-4 md:absolute md:bottom-0 md:right-0 md:mt-0">
          View all posts
        </LinkWithArrow>
      </div>

      <div className="mt-8 grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {items.map((post, i) => (
          <BlogCard {...post} key={post.id} className={classNames({ "sm:hidden lg:flex": i === 2 })} />
        ))}
      </div>
    </Container>
  </Section>
);

export default BlogThreeUp;
