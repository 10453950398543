const InstagramIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81679 0.0541595C6.7932 0.0103603 7.10491 0 9.5911 0C12.0773 0 12.389 0.0103603 13.3654 0.0541595C14.3398 0.0978872 15.0052 0.250005 15.5876 0.472502C16.1895 0.702501 16.7001 1.01024 17.209 1.51057C17.7179 2.0109 18.0309 2.51281 18.2649 3.10463C18.4912 3.67713 18.6459 4.33133 18.6904 5.2893C18.7349 6.2492 18.7455 6.55569 18.7455 8.99994C18.7455 11.4442 18.7349 11.7507 18.6904 12.7106C18.6459 13.6686 18.4912 14.3228 18.2649 14.8953C18.0309 15.4871 17.7179 15.989 17.209 16.4893C16.7001 16.9896 16.1895 17.2974 15.5876 17.5274C15.0052 17.7499 14.3398 17.902 13.3654 17.9457C12.389 17.9895 12.0773 17.9998 9.5911 17.9998C7.10491 17.9998 6.7932 17.9895 5.81679 17.9457C4.84238 17.902 4.17695 17.7499 3.59463 17.5274C2.99265 17.2974 2.48214 16.9896 1.97322 16.4893C1.4643 15.989 1.15129 15.487 0.917341 14.8953C0.691026 14.3228 0.536298 13.6686 0.49182 12.7106C0.447269 11.7507 0.436768 11.4442 0.436768 8.99994C0.436768 6.55569 0.447269 6.2492 0.49182 5.2893C0.536298 4.33133 0.691026 3.67713 0.917341 3.10463C1.15129 2.51281 1.4643 2.0109 1.97322 1.51057C2.48214 1.01024 2.99265 0.702501 3.59463 0.472502C4.17695 0.250005 4.84238 0.0978872 5.81679 0.0541595ZM4.88956 9.00039C4.88956 6.44797 6.99423 4.37879 9.59045 4.37879C12.1867 4.37879 14.2913 6.44797 14.2913 9.00039C14.2913 11.5528 12.1867 13.622 9.59045 13.622C6.99423 13.622 4.88956 11.5528 4.88956 9.00039ZM9.59091 11.9985C7.90566 11.9985 6.53946 10.6553 6.53946 8.99851C6.53946 7.34168 7.90566 5.99852 9.59091 5.99852C11.2762 5.99852 12.6424 7.34168 12.6424 8.99851C12.6424 10.6553 11.2762 11.9985 9.59091 11.9985ZM14.478 5.27576C15.0847 5.27576 15.5765 4.79221 15.5765 4.19574C15.5765 3.59927 15.0847 3.11577 14.478 3.11577C13.8713 3.11577 13.3795 3.59927 13.3795 4.19574C13.3795 4.79221 13.8713 5.27576 14.478 5.27576Z"
      fill="currentColor"
    />
  </svg>
);

export default InstagramIcon;
