import { useMemo, useState } from "react";
import axios from "axios";
import { Formik, Form, FormikHelpers, FieldArray, FormikErrors, FormikTouched } from "formik";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Api } from "@/api";
import { SelectField, HorizontalRule, Entry, FormActions, UploadProgress } from "@/components";
import { IS_PRODUCTION, IS_STAGING, ROUTES } from "@/config";
import { useQueryParams } from "@/hooks";
import { useAlertDispatch } from "@/providers";
import { AwardsCategory, IEntryForm, IEntry } from "@/types";
import { countryOptions, urlRegex, getCategoryOptions, getPaymentURL, waitForElement } from "@/utils";
import { TextField } from "@material-ui/core";
import { AlertActionType } from "@narrative-software/narrative-web-ui";

const FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes
const FILE_TYPES = ["image/jpg", "image/jpeg"];

const abortController = new AbortController();

const ACTIVE_CAMPAIGN_HIDDEN_VALUES =
  IS_PRODUCTION || IS_STAGING
    ? {
        u: "30",
        f: "30",
        s: "",
        c: "0",
        m: "0",
        act: "sub",
        v: "2",
        or: "973bae27ece8a53af05745640008f591",
      }
    : {
        u: "3",
        f: "3",
        s: "",
        c: "0",
        m: "0",
        act: "sub",
        v: "2",
        or: "5ea080e7fd87e5aa6112c832a3ae4740",
      };

const getEntryObj = (category = ""): IEntry => ({
  category,
  id: uuidv4(),
  title: "",
  description: "",
  file: undefined,
});

const getInitialValues = (category = ""): IEntryForm => ({
  firstname: "",
  lastname: "",
  email: "",
  country: "",
  business: "",
  website: "",
  entries: [{ ...getEntryObj(category) }],
});

const isFormError = (touched: FormikTouched<IEntryForm>, errors: FormikErrors<IEntryForm>) => {
  // eslint-disable-next-line no-prototype-builtins
  const isDetailsError = Object.keys(touched).some((key) => key !== "entries" && errors?.hasOwnProperty(key));
  const isEntriesError = touched?.entries?.some(
    // eslint-disable-next-line no-prototype-builtins
    (entry, i) => entry && Object.keys(entry).some((key) => errors?.entries?.[i]?.hasOwnProperty(key)),
  );
  return isDetailsError || isEntriesError;
};

const MAX_ENTRIES = 15;
const MIN_ENTRIES = 1;
const MIN_CHARGEABLE_ENTRIES = 6;

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First name required"),
  lastname: Yup.string().required("Last name required"),
  email: Yup.string().required("Email required").email("Invalid email"),
  country: Yup.string().optional(),
  business: Yup.string().optional(),
  website: Yup.string().matches(urlRegex, "Invalid URL").optional(),
  entries: Yup.array()
    .of(
      Yup.object().shape({
        category: Yup.string().required("Category is required"),
        title: Yup.string().required("Image title is required"),
        description: Yup.string().optional(),
        file: Yup.mixed()
          .required("An image is required")
          .test("fileSize", "File size too large (20MB max)", (value) => value && value.size <= FILE_SIZE)
          .test(
            "fileType",
            "Unsupported image format (JPEG only)",
            (value) => value && FILE_TYPES.includes(value.type),
          ),
      }),
    )
    .min(MIN_ENTRIES, "At least one entry is required")
    .max(MAX_ENTRIES, `Maximum of ${MAX_ENTRIES} entries allowed`),
});

type Props = {
  categories: AwardsCategory[];
};

const EntryForm: React.FC<Props> = ({ categories }) => {
  const router = useRouter();
  const queryParams = useQueryParams();
  const alertDispatch = useAlertDispatch();
  const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories]);

  const [open, setOpen] = useState(false);
  const [upload, setUpload] = useState({
    count: 0, // Number of files to upload
    progress: 0, // Total file size progress as percentage
  });

  // Get initial values with pre-selected category if provided in query params
  const initialValues = useMemo(() => {
    const category = queryParams.category && categories.find(({ slug }) => slug === queryParams.category);
    return getInitialValues(category && `${category.group}: ${category.title}`);
  }, [categories, queryParams.category]);

  // Upload cancel
  const onUploadCancel = () => {
    abortController.abort();
    setOpen(false);
    alertDispatch({
      type: AlertActionType.SetContent,
      payload: {
        type: "attention",
        children: "Upload cancelled",
      },
    });
  };

  // Add entry
  const onAddEntry = async (entryID: string, addEntry: (entryObj: IEntry) => void) => {
    addEntry({ ...getEntryObj() });
    const element = await waitForElement(`#${entryID}`);
    element.scrollIntoView({ behavior: "smooth" });
  };

  // Submit
  const onSubmit = async (values: IEntryForm, actions: FormikHelpers<IEntryForm>) => {
    try {
      const files = values.entries.map((entry) => entry.file).filter((file) => file) as File[];
      const uploadCount = files.length;
      const uploadSizeTotal = files.reduce((acc, file) => acc + file.size, 0);
      let uploadSizeProgress = 0;

      setUpload({ progress: 0, count: uploadCount });
      setOpen(true);

      const response = await Api.sendAwardsEntry(values, abortController.signal);
      const s3URLs = response.data.data?.attributes["entry-urls"];

      if (uploadCount !== s3URLs.length) {
        throw new Error("There was an error uploading your entry");
      }

      for (let i = 0; i < uploadCount; i++) {
        await axios.put(s3URLs[i], files[i], {
          signal: abortController.signal,
          onUploadProgress: ({ loaded }) => {
            setUpload((prevState) => ({
              ...prevState,
              progress: Math.round(((loaded + uploadSizeProgress) / uploadSizeTotal) * 100),
            }));
          },
        });
        uploadSizeProgress += files[i].size;
      }

      const { email, firstname, lastname } = values;

      await Api.sendActiveCampaignsForm({ ...ACTIVE_CAMPAIGN_HIDDEN_VALUES, email, firstname, lastname });

      if (uploadCount < MIN_CHARGEABLE_ENTRIES) {
        await router.push(`/${ROUTES.AWARDS.COMPLETE.SLUG}`); // No payment required. Go to complete page
      } else {
        window.location.href = getPaymentURL(uploadCount); // Redirect to Stripe payment page
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);
      setOpen(false);
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "error",
          title: "There was an error submitting your form",
          children: e.message,
        },
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <UploadProgress open={open} progress={upload.progress} uploadCount={upload.count} onCancel={onUploadCancel} />

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
        {({ values, touched, errors, handleChange, handleBlur, isSubmitting }) => {
          const disableAddEntry =
            isSubmitting ||
            values.entries.length >= MAX_ENTRIES ||
            (!values.entries[0].category && !values.entries[0].file);

          const fieldProps = (name: keyof IEntryForm, helperText?: string) => {
            const touch = touched[name];
            const error = errors[name];
            return {
              name,
              type: "text",
              id: `entry-form-${name}`,
              value: values[name],
              error: touch && !!error,
              helperText: helperText ? (touch && error) || helperText : touch && error,
              onChange: handleChange,
              onBlur: handleBlur,
              fullWidth: true,
            };
          };

          return (
            <Form className="awards-entry-form" noValidate>
              <fieldset name="details">
                <HorizontalRule className="mb-6" />
                <h2 className="text-40 leading-tighter mb-8">Your details</h2>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                  <TextField {...fieldProps("firstname")} label="First name" />
                  <TextField {...fieldProps("email")} label="Email" type="email" />
                  <TextField {...fieldProps("business")} label="Business name (optional)" />
                  <TextField {...fieldProps("lastname")} label="Last name" />
                  <SelectField {...fieldProps("country")} label="Country (optional)">
                    <option aria-label="None" disabled />
                    {countryOptions}
                  </SelectField>
                  <TextField {...fieldProps("website")} label="Your photography website (optional)" type="url" />
                </div>
              </fieldset>

              <FieldArray
                name="entries"
                validateOnChange={false}
                render={({ push, remove }) => (
                  <>
                    {values.entries.map((entry, index, arr) => {
                      let errorsObj = errors.entries?.[index];
                      if (typeof errorsObj === "string") {
                        errorsObj = undefined;
                      }
                      return (
                        <Entry
                          key={entry.id}
                          index={index}
                          categoryOptions={categoryOptions}
                          values={entry}
                          touched={touched.entries?.[index]}
                          errors={errorsObj}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onRemove={arr.length > 1 ? remove : undefined}
                        />
                      );
                    })}
                    <FormActions
                      entryCount={values.entries.length}
                      disableSubmit={isSubmitting}
                      disableAddEntry={disableAddEntry}
                      isError={isFormError(touched, errors)}
                      onAddEntry={() => onAddEntry(`entry-${values.entries.length + 1}`, push)}
                    />
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export { MAX_ENTRIES, MIN_CHARGEABLE_ENTRIES };

export default EntryForm;
