import classNames from "classnames";

interface Props {
  aspectRatio: number;
  className?: string;
}

const FixedAspectRatioContainer: React.FC<Props> = ({ aspectRatio, className, children }) => (
  <div className={classNames("flex flex-col w-full", className)}>
    <div className="relative flex h-0" style={{ paddingBottom: 100 / aspectRatio + "%" }}>
      <div className="absolute inset-0 flex flex-col">{children}</div>
    </div>
  </div>
);

export default FixedAspectRatioContainer;
