const PublishLogo: React.FC = () => (
  <svg width="121" height="31" viewBox="0 0 121 31" fill="none">
    <path
      d="M40 25.1168H41.5363V16.7575H45.2124C49.0257 16.7575 51.8788 15.2004 51.8788 11.1846V11.0754C51.8788 7.11425 48.9983 5.58444 45.185 5.58444H40V25.1168ZM41.5363 15.5282V6.84106H45.2673C48.3399 6.84106 50.3425 7.90647 50.3425 11.0754V11.1846C50.3425 14.1896 48.477 15.5282 45.3222 15.5282H41.5363Z"
      fill="#1C1C1C"
    />
    <path
      d="M59.6413 25.3353C62.1653 25.3353 64.0033 23.9421 64.6617 22.385V25.1168H66.1157V10.8295H64.6617V19.5166C64.6617 22.5216 62.3024 24.0787 59.9706 24.0787C57.4741 24.0787 56.0475 22.8221 56.0475 19.7898V10.8295H54.5935V19.8717C54.5935 23.8055 56.6785 25.3353 59.6413 25.3353Z"
      fill="#1C1C1C"
    />
    <path
      d="M76.9438 25.3353C80.6473 25.3353 83.3907 22.5216 83.3907 18.0414V17.8229C83.3907 13.3701 80.565 10.6109 76.9438 10.6109C74.4198 10.6109 72.3074 12.3047 71.4844 14.1623V4H70.0304V25.1168H71.4844V21.8659C72.2526 23.6143 74.1181 25.3353 76.9438 25.3353ZM76.834 24.106C73.8437 24.106 71.457 22.0025 71.457 18.0961V17.8775C71.457 13.9437 74.0083 11.8402 76.8066 11.8402C79.55 11.8402 81.8818 13.9437 81.8818 17.8775V18.0961C81.8818 21.9206 79.8517 24.106 76.834 24.106Z"
      fill="#1C1C1C"
    />
    <path d="M86.2959 25.1168H87.7774V4H86.2959V25.1168Z" fill="#1C1C1C" />
    <path
      d="M91.7902 25.1168H93.2442V10.8295H91.7902V25.1168ZM92.5035 7.76988C93.0796 7.76988 93.5186 7.30547 93.5186 6.73179C93.5186 6.18543 93.0796 5.72103 92.5035 5.72103C91.9274 5.72103 91.461 6.18543 91.461 6.73179C91.461 7.30547 91.9274 7.76988 92.5035 7.76988Z"
      fill="#1C1C1C"
    />
    <path
      d="M101.51 25.3626C104.775 25.3626 106.558 23.6143 106.558 21.2103C106.558 18.4239 104.802 17.6863 101.729 17.1399C98.794 16.6209 97.8064 16.0199 97.8064 14.3808C97.8064 12.851 99.1507 11.8402 101.153 11.8402C103.238 11.8402 104.418 12.6598 104.775 14.4901H106.174C105.79 11.6763 103.76 10.6109 101.181 10.6109C98.7666 10.6109 96.4621 12.0042 96.4621 14.4355C96.4621 16.8941 97.8887 17.7956 101.4 18.4239C104.089 18.9156 105.159 19.4893 105.159 21.3196C105.159 23.0406 103.924 24.1333 101.51 24.1333C98.8489 24.1333 97.6418 22.7948 97.3949 20.8552H95.9958C96.1878 23.587 98.1356 25.3626 101.51 25.3626Z"
      fill="#1C1C1C"
    />
    <path
      d="M109.368 25.1168H110.822V16.457C110.822 13.452 113.181 11.8676 115.568 11.8676C118.119 11.8676 119.546 13.2061 119.546 16.2384V25.1168H121V16.3204C121 12.25 118.942 10.6109 115.897 10.6109C113.346 10.6109 111.48 12.0588 110.822 13.6159V4H109.368V25.1168Z"
      fill="#1C1C1C"
    />
    <circle cx="15.166" cy="15.166" r="15.166" fill="#F16370" />
    <path
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33398 11.082L9.33398 21.5815L11.0839 21.5815L11.0839 11.082L16.3326 11.082L16.3326 18.0817H18.0825L18.0825 11.082H18.0836L18.0836 9.33203L11.0839 9.33203L9.33398 9.33203L9.33398 11.082ZM21.5825 9.33205L19.8326 9.33205L19.8326 19.8315L14.5824 19.8315L14.5824 12.8318L12.8325 12.8318L12.8325 19.8315L12.8325 21.5814V21.5814H14.5824V21.5814L19.8326 21.5814V21.5815L21.5825 21.5815L21.5825 9.33205Z"
    />
  </svg>
);

export default PublishLogo;
