const SelectLogo: React.FC = () => (
  <svg width="111" height="31" viewBox="0 0 111 31" fill="none">
    <path
      d="M48.0629 24.7374C51.8865 24.7374 54.4089 22.3477 54.4089 19.3472C54.4089 15.7892 52.3644 14.5944 48.116 13.9571C44.1597 13.373 43.1242 12.2578 43.1242 10.1867C43.1242 7.90319 44.797 6.54902 47.7177 6.54902C50.5323 6.54902 52.0192 7.74387 52.4706 10.107H53.8248C53.3999 7.08006 51.2492 5.32761 47.7177 5.32761C44.1597 5.32761 41.7435 7.34559 41.7435 10.2929C41.7435 13.2933 43.7349 14.674 47.8771 15.2847C51.7006 15.8689 52.9751 16.7982 52.9751 19.4534C52.9751 21.7901 51.0368 23.516 48.0629 23.516C44.1066 23.516 42.779 21.4714 42.4604 19.0817H41C41.3983 22.1883 43.3632 24.7374 48.0629 24.7374Z"
      fill="#1C1C1C"
    />
    <path
      d="M63.0161 24.7374C65.9899 24.7374 68.1938 23.3035 68.7779 20.6483H67.3706C66.8927 22.5866 65.3261 23.5425 63.0161 23.5425C59.7236 23.5425 57.9446 21.4183 57.8915 17.701H68.8841V17.1965C68.8841 12.603 66.1492 10.4257 62.8036 10.4257C59.0863 10.4257 56.4311 13.3199 56.4311 17.5151V17.7276C56.4311 21.8963 59.0067 24.7374 63.0161 24.7374ZM57.9446 16.5592C58.2101 13.5323 60.1219 11.6205 62.8036 11.6205C65.512 11.6205 67.291 13.1871 67.4237 16.5592H57.9446Z"
      fill="#1C1C1C"
    />
    <path d="M71.6537 24.5249H73.0875V4H71.6537V24.5249Z" fill="#1C1C1C" />
    <path
      d="M82.4678 24.7374C85.4417 24.7374 87.6455 23.3035 88.2296 20.6483H86.8224C86.3444 22.5866 84.7778 23.5425 82.4678 23.5425C79.1753 23.5425 77.3963 21.4183 77.3432 17.701H88.3359V17.1965C88.3359 12.603 85.601 10.4257 82.2554 10.4257C78.538 10.4257 75.8828 13.3199 75.8828 17.5151V17.7276C75.8828 21.8963 78.4584 24.7374 82.4678 24.7374ZM77.3963 16.5592C77.6618 13.5323 79.5736 11.6205 82.2554 11.6205C84.9637 11.6205 86.7427 13.1871 86.8755 16.5592H77.3963Z"
      fill="#1C1C1C"
    />
    <path
      d="M96.6549 24.7374C99.8146 24.7374 102.204 22.6928 102.576 19.719H101.275C100.93 22.2149 98.9649 23.5425 96.6549 23.5425C93.7341 23.5425 91.4771 21.3918 91.4771 17.7276V17.5151C91.4771 13.8509 93.7606 11.6205 96.6549 11.6205C98.8321 11.6205 100.717 12.7092 101.063 15.0458H102.443C102.072 11.9126 99.4694 10.4257 96.6549 10.4257C92.9906 10.4257 90.0168 13.2668 90.0168 17.5151V17.7276C90.0168 21.9494 92.911 24.7374 96.6549 24.7374Z"
      fill="#1C1C1C"
    />
    <path
      d="M109.168 24.7108C110.018 24.7108 110.549 24.578 111 24.4187V23.2239C110.522 23.3832 109.964 23.516 109.248 23.516C107.973 23.516 107.176 22.7459 107.176 21.0731V11.8329H110.814V10.6381H107.176V7.47835H105.769V10.6381H103.725V11.8329H105.769V21.1793C105.769 23.4097 106.964 24.7108 109.168 24.7108Z"
      fill="#1C1C1C"
    />
    <circle cx="15.166" cy="15.166" r="15.166" fill="#4F429C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33203 11.082L9.33203 21.5815L11.082 21.5815L11.082 11.082L16.3306 11.082L16.3306 18.0817H18.0805L18.0805 11.082H18.0817L18.0817 9.33203L11.082 9.33203L9.33203 9.33203L9.33203 11.082ZM21.5806 9.33205L19.8307 9.33205L19.8307 19.8315L14.5805 19.8315L14.5805 12.8318L12.8306 12.8318L12.8306 19.8315L12.8306 21.5814V21.5814H14.5805V21.5814L19.8307 21.5814V21.5815L21.5806 21.5815L21.5806 9.33205Z"
      fill="white"
    />
  </svg>
);

export default SelectLogo;
