import { Image as DatoImage } from "react-datocms";
import { Section } from "@/components";
import { formatImageData } from "@/config";
import { ImageType } from "@/types";
import { Carousel as UICarousel } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface CarouselFragmentType {
  _modelApiKey: typeof MODULE_KEYS.carousel;
  id: string;
  items: CarouselItemFragmentType[];
  heading?: string;
  subheading?: string;
  isSection?: boolean;
  hideHeaderDash?: boolean;
}

export interface CarouselItemFragmentType {
  id: string;
  quote: string;
  name?: string;
  image?: ImageType;
  logo?: ImageType;
  secondary?: string;
  logoBlackBackground?: boolean;
}

// Fragment
export const carouselFragment = `
  fragment testimonialCarouselFragment on ModuleTestimonialCarouselRecord {
    _modelApiKey
    id
    heading
    subheading
    isSection
    items {
      id
      name
      quote
      secondary
      logoBlackBackground
      image {
        url
        alt
        width
        height
        responsiveImage(imgixParams: { auto: format, fit: crop, w: 440, h: 440 }) {
          ...responsiveImageFragment
        }
      }
      logo {
        url
        alt
        width
        height
        responsiveImage(imgixParams: { auto: format }) {
          ...responsiveImageFragment
        }
      }
    }
  }
`;

export const Carousel: React.FC<CarouselFragmentType & { randomize?: boolean }> = ({
  id,
  items,
  heading,
  subheading,
  randomize,
  hideHeaderDash,
}) => {
  if (!items?.length) return null;

  let cmsItems = [...items];

  if (cmsItems.length < 5) {
    cmsItems = [...items, ...items];
  }

  const _items = cmsItems.map(({ id, image, logo, logoBlackBackground, ...rest }, i) => ({
    id: `${id}-${i}`,
    image: image && <DatoImage data={formatImageData({ image })} priority />,
    logo: logo && <DatoImage data={formatImageData({ image: logo })} className="max-h-full" priority />,
    isLogoBackgroundBlack: logoBlackBackground,
    ...rest,
  }));

  return (
    <Section key={id} className="flex-col">
      <UICarousel
        hideHeaderDash={hideHeaderDash}
        title={heading}
        subheading={subheading}
        items={_items}
        randomize={randomize}
      />
    </Section>
  );
};
