import { CouponHelper } from "@/helpers";
import { useQueryParams } from "@/hooks";

/**
 * Get referral data
 */
export const useReferral = () => {
  const cookieCode = CouponHelper.getCode();
  const { affiliate, referral, fp_ref } = useQueryParams();

  let code: string | undefined = undefined;
  let scheme: "affiliate" | "referral" | "fp_ref" | undefined = undefined;

  if (affiliate) {
    code = affiliate;
    scheme = "affiliate";
  } else if (referral) {
    code = referral;
    scheme = "referral";
  } else if (fp_ref) {
    code = fp_ref;
    scheme = "fp_ref";
  } else if (cookieCode) {
    code = cookieCode;
  }

  if (code && scheme) {
    CouponHelper.setCookie({ code, scheme });
  }

  return {
    affiliate,
    referral,
    fp_ref,
    scheme,
    coupon: code,
  };
};
