import { Container, AwardsSection as Section } from "@/components";
import { AwardsPartner } from "@/types";

interface Props {
  partners: AwardsPartner[];
}

const Partners: React.FC<Props> = ({ partners }) => (
  <Section>
    <Container>
      {/* <HorizontalRule className="mb-6" /> */}
      <h2 className="text-40 leading-tight mb-4">Awards partners</h2>
      <div className="flex items-center justify-between flex-grow flex-wrap lg:flex-nowrap">
        {partners.map(({ heading, image }) => (
          <div key={heading} className="flex justify-center flex-grow w-1/2 p-3 sm:w-1/4 lg:w-[12.5%]">
            <img src={image.url} alt={image.alt} className="max-h-17" loading="lazy" />
          </div>
        ))}
      </div>
    </Container>
  </Section>
);

export default Partners;
