import React, { useState, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import { ArrowButton } from "@/components";

type Props = {
  index: number;
  setImage: (file?: File) => void;
  file?: File;
  error?: string;
};

const ImagePreview: React.FC<Props> = ({ index, setImage, file, error }) => {
  const [imageURL, setImageURL] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = useCallback(() => fileInputRef.current?.click(), [fileInputRef]);
  const onImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      setImage(file);
    },
    [index, setImage],
  );

  useEffect(() => {
    if (imageURL) URL.revokeObjectURL(imageURL);

    if (file && !error) {
      const url = URL.createObjectURL(file);
      setImageURL(url);
    } else {
      setImageURL("");
    }

    return () => URL.revokeObjectURL(imageURL);
  }, [file, error]);

  return (
    <div className="aspect-w-15 aspect-h-10 border border-gumboot bg-black">
      {imageURL && !error ? (
        <img src={imageURL} alt="" className="object-contain" />
      ) : (
        <div className="flex flex-col items-center justify-center text-center pt-7">
          <div className="w-full max-w-2/3">
            <ArrowButton type="button" variant="secondary" onClick={onButtonClick}>
              Add image
            </ArrowButton>
          </div>
          <p
            className={classNames("text-12 mt-2.5", {
              "text-red-600": error,
              "text-shady-lady": !error,
            })}
          >
            {error || "Image must be a JPEG and no larger than 20MB"}
          </p>
        </div>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        name={`entries.${index}.file`}
        className="hidden"
        onChange={onImageChange}
      />
    </div>
  );
};

export default ImagePreview;
