import classNames from "classnames";

const InfoTooltip = ({ description, special }: { description: string; special?: "bf-publish" | "bf-select" }) => (
  <span
    className={classNames("group hover:text-white hidden md:inline", {
      "text-gumboot": special,
      "text-cinderBlock": !special,
    })}
    data-tip={description}
  >
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill="#1C1C1C"
        className="invisible group-hover:visible"
      />
      <circle cx="7" cy="4" r="1" fill="currentColor" />
      <rect x="6" y="6" width="2" height="5" fill="currentColor" />
      <path
        d="M13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7Z"
        stroke={special ? "#4F4F4F" : "#E0E0E0"}
        className="visible group-hover:invisible"
      />
    </svg>
  </span>
);

export default InfoTooltip;
