type Props = {
  className?: string;
};

const LocationIcon: React.FC<Props> = ({ className }) => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" className={className}>
    <circle cx="10.5" cy="11" r="10" stroke="black" />
    <circle cx="10.5" cy="9" r="2" stroke="black" />
    <path
      d="M15.5 9C15.5 11.088 14.3279 13.3554 13.0325 15.1442C12.3919 16.029 11.7372 16.7744 11.2168 17.2949C10.9558 17.5558 10.7345 17.7543 10.5703 17.8837C10.5447 17.9039 10.5212 17.9218 10.5 17.9375C10.4788 17.9218 10.4553 17.9039 10.4297 17.8837C10.2655 17.7543 10.0442 17.5558 9.78324 17.2949C9.2628 16.7744 8.60814 16.029 7.96747 15.1442C6.67207 13.3554 5.5 11.088 5.5 9C5.5 6.23858 7.73858 4 10.5 4C13.2614 4 15.5 6.23858 15.5 9Z"
      stroke="black"
    />
  </svg>
);

export default LocationIcon;
