const EyeIcon: React.FC = () => (
  <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 18.7266C20.2091 18.7266 22 16.9357 22 14.7266C22 13.8607 21.7249 13.059 21.2572 12.4043C21.3728 12.6353 21.4378 12.896 21.4378 13.1718C21.4378 14.1208 20.6685 14.89 19.7196 14.89C18.7707 14.89 18.0014 14.1208 18.0014 13.1718C18.0014 12.2229 18.7707 11.4537 19.7196 11.4537C20.057 11.4537 20.3716 11.5509 20.6371 11.7188C19.933 11.1011 19.0102 10.7266 18 10.7266C15.7909 10.7266 14 12.5174 14 14.7266C14 16.9357 15.7909 18.7266 18 18.7266Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2572 12.4043C21.3728 12.6353 21.4378 12.896 21.4378 13.1718C21.4378 13.8588 21.0346 14.4516 20.452 14.7266C20.4229 14.7403 20.3933 14.7532 20.3633 14.7654C20.1645 14.8458 19.9472 14.89 19.7196 14.89C18.7707 14.89 18.0014 14.1208 18.0014 13.1718C18.0014 12.8827 18.0728 12.6102 18.199 12.3711C18.4869 11.8255 19.0598 11.4537 19.7196 11.4537C20.057 11.4537 20.3716 11.5509 20.6371 11.7188C20.362 11.4775 20.0536 11.2733 19.7196 11.114C19.1987 10.8656 18.6156 10.7266 18 10.7266C17.7864 10.7266 17.5767 10.7433 17.3721 10.7756C15.4612 11.0768 14 12.7311 14 14.7266C14 16.9357 15.7909 18.7266 18 18.7266C19.8561 18.7266 21.417 17.4623 21.8684 15.7479C21.9543 15.4219 22 15.0796 22 14.7266C22 14.1751 21.8884 13.6498 21.6866 13.1718C21.5715 12.8993 21.4271 12.6421 21.2572 12.4043ZM19.5379 16.5215C19.1245 16.876 18.5873 17.0902 18 17.0902C16.6946 17.0902 15.6364 16.032 15.6364 14.7266C15.6364 14.0538 15.9175 13.4466 16.3686 13.0162C16.3662 13.0678 16.3651 13.1197 16.3651 13.1718C16.3651 14.9635 17.7697 16.4271 19.5379 16.5215Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9998 22.9092C22.5185 22.9092 26.1816 19.2461 26.1816 14.7274C26.1816 10.2087 22.5185 6.54554 17.9998 6.54554C13.4811 6.54554 9.818 10.2087 9.818 14.7274C9.818 19.2461 13.4811 22.9092 17.9998 22.9092ZM17.9998 24.5455C23.4223 24.5455 27.818 20.1498 27.818 14.7274C27.818 9.30493 23.4223 4.90918 17.9998 4.90918C12.5774 4.90918 8.18164 9.30493 8.18164 14.7274C8.18164 20.1498 12.5774 24.5455 17.9998 24.5455Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14.7274C0 14.7274 8.18182 24.5455 18 24.5455C27.8182 24.5455 36 14.7274 36 14.7274C36 14.7274 27.8182 4.90918 18 4.90918C8.18182 4.90918 0 14.7274 0 14.7274ZM2.21792 14.7274C2.33219 14.8454 2.45597 14.9714 2.5889 15.1044C3.46859 15.9841 4.73961 17.1571 6.3 18.3274C9.46134 20.6984 13.6121 22.9092 18 22.9092C22.3879 22.9092 26.5387 20.6984 29.7 18.3274C31.2604 17.1571 32.5314 15.9841 33.4111 15.1044C33.544 14.9714 33.6678 14.8454 33.7821 14.7274C33.6678 14.6093 33.544 14.4833 33.4111 14.3504C32.5314 13.4707 31.2604 12.2977 29.7 11.1274C26.5387 8.75636 22.3879 6.54554 18 6.54554C13.6121 6.54554 9.46134 8.75636 6.3 11.1274C4.73961 12.2977 3.46859 13.4707 2.5889 14.3504C2.45597 14.4833 2.33219 14.6093 2.21792 14.7274Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.27184 6.54545L3.27184 0L4.9082 0V6.54545H3.27184Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2729 0.000355005L9.81836 0.000355005V1.63672L3.2729 1.63672V0.000355005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2729 27.8177H9.81836V29.4541H3.2729V27.8177Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1811 0.000355005L32.7266 0.000355005V1.63672L26.1811 1.63672V0.000355005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1811 27.8177H32.7266V29.4541H26.1811V27.8177Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M31.0902 6.54545V0L32.7266 0V6.54545H31.0902Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.27184 29.4546L3.27184 22.9092H4.9082L4.9082 29.4546H3.27184Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.0902 29.4546V22.9092H32.7266V29.4546H31.0902Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeIcon;
