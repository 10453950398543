import { FormControl, InputLabel, Select, SelectProps, FormHelperText } from "@material-ui/core";

type Props = SelectProps & { helperText?: React.ReactNode };

const SelectField: React.FC<Props> = ({ id, label, labelId, error, helperText, fullWidth, ...rest }) => (
  <FormControl {...{ error, fullWidth }}>
    <InputLabel htmlFor={labelId}>{label}</InputLabel>
    <Select {...{ id, labelId, fullWidth, ...rest }} native />
    {helperText && <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>}
  </FormControl>
);

export default SelectField;
