import React from "react";
import classNames from "classnames";
import { ProfileBeforeAfterSlider } from "@/components/shared/ProfileBeforeAfterSlider";
import { ProfileBeforeAfterStatic } from "@/components/shared/ProfileBeforeAfterStatic";
import { ImageType } from "@/types";

type EditStylePreviewsProps = {
  images: { beforeImage: ImageType; afterImage: ImageType }[];
};

function EditStylePreviewsSection({ images }: EditStylePreviewsProps) {
  const styleImages = chunkArrayAlternating(
    images.map(({ beforeImage, afterImage }) => ({ beforeImage, afterImage, ratio: getImageRatio(afterImage) })),
  );

  return (
    <section className="bg-mankey">
      <div className="max-w-[1212px] mx-auto w-full md:mb-[100px] mb-[80px]">
        <div className="border-mankey border-t-[5px] border-l-[11px] border-r-[11px] border-b-[11px]">
          {styleImages.flatMap((row, i) => {
            const isLandscape = row.every(({ ratio }) => ratio === "landscape");
            const isPortrait = row.every(({ ratio }) => ratio === "portrait");
            const isMixed = !isLandscape && !isPortrait;

            // The padding bottom percentages are to create responsive containers for the images. All images are assumed to be 3:2 ratio for landscape and 2:3 for portrait.
            // Images alternate between 1 and 2 per row, so the padding bottom percentages are calculated based on the number of images in the row, and the aspect ratio of those images.
            // A row with 2 images could have 2x landscape, 2x portrait, or 1x each. A row with 1 image could be landscape or portrait.
            // In practice the team will endeavour to ensure that portrait images never appear on their own as a single image in one row, but the code is written to handle this case.
            return (
              <div
                key={i}
                className={classNames("relative flex h-0 w-full ", {
                  "pb-[167.7%]": isPortrait && row.length === 1,
                  "pb-[75%]": isPortrait && row.length === 2,
                  "pb-[66.7%]": isLandscape && row.length === 1,
                  "pb-[33.33%]": isLandscape && row.length === 2,
                  "pb-[46%]": isMixed,
                })}
              >
                <div className="absolute top-0 right-0 bottom-0 left-0 flex">
                  {row.map((image, i) => {
                    return (
                      <div
                        data-ratio={image.ratio}
                        key={`${image.afterImage.url}-${i}`}
                        className={classNames(
                          "border-mankey relative h-full w-full border-[9px] bg-cover bg-center bg-no-repeat",
                          {
                            "w-[50%]": row.length === 2 && !isMixed,
                            "w-[69%]": isMixed && image.ratio === "landscape",
                            "w-[31%]": isMixed && image.ratio === "portrait",
                            "w-[100%]": row.length === 1,
                          },
                        )}
                      >
                        <ProfileBeforeAfterSlider
                          beforeImage={image.beforeImage.responsiveImage}
                          afterImage={image.afterImage.responsiveImage}
                          className="absolute top-0 right-0 bottom-0 left-0 h-full"
                          data-mouse
                        />
                        <ProfileBeforeAfterStatic
                          beforeImage={image.beforeImage.responsiveImage}
                          afterImage={image.afterImage.responsiveImage}
                          className="absolute top-0 right-0 bottom-0 left-0 h-full"
                          data-touch
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function getImageRatio(image: ImageType) {
  if (image.responsiveImage.aspectRatio !== undefined) {
    image.responsiveImage.aspectRatio > 1 ? "landscape" : "portrait";
  }
  return "landscape";
}

/**
 *
 * @param arr - An array of any type
 * @example
 * // Returns [[1, 2], [3], [4, 5], [6], [7, 8], [9]]
 * chunkArrayAlternating([1, 2, 3, 4, 5, 6, 7, 8, 9])
 * @returns - The array chunked into subarrays of alternating lengths of 1 and 2
 */
function chunkArrayAlternating<T>(arr: T[]): T[][] {
  const result: T[][] = [];
  let i = 0;

  while (i < arr.length) {
    const lastChunk = result[result.length - 1];
    const chunkLength = i === 0 ? 2 : lastChunk?.length === 1 ? 2 : 1;
    const chunk = arr.slice(i, i + chunkLength);
    result.push(chunk);
    i += chunkLength;
  }

  return result;
}

export { EditStylePreviewsSection };
