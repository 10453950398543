import { useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { ROUTES } from "@/config";
import { useScrollPosition } from "@/hooks";
import { Logo, DesktopNav, MobileButton, MobileNav } from "./components";

export type NavItem = {
  href: string;
  label: string;
  isActive?: boolean;
  isExternal?: boolean;
  isNewTab?: boolean;
  hideMobile?: boolean;
};

export type NavItemPrimary = NavItem & {
  secondaryNav?: NavItem[];
};

type Props = {
  items: NavItemPrimary[];
  className?: string;
  mobileLegalItems?: NavItem[];
};

const AwardsHeader: React.FC<Props> = ({ items, className, mobileLegalItems }) => {
  const { pathname } = useRouter();
  const { y: scrollY } = useScrollPosition();

  const [open, setOpen] = useState(false);
  const closeNav = () => setOpen(false);
  const toggleNav = () => setOpen((prevState) => !prevState);

  const navItems = [
    ...items,
    {
      href: `/${ROUTES.BASE.SLUG}`,
      label: "Narrative Website",
    },
  ];

  return (
    <header
      className={classNames(
        "fixed z-20 top-0 left-0 right-0 bg-transparent overscroll-none lg:transition lg:duration-300",
        className,
        {
          shadow: scrollY > 0,
          "bottom-0 bg-gray-900 lg:bottom-auto lg:bg-transparent": open,
          "bg-black bg-opacity-80 backdrop-blur": !open && scrollY > 0,
        },
      )}
    >
      <nav>
        <div className="flex justify-between h-16 px-5 lg:h-18 lg:px-10">
          <Logo href={`/${ROUTES.AWARDS.BASE.SLUG}`} />
          <DesktopNav items={navItems} />
          <MobileButton open={open} onClick={toggleNav} />
        </div>

        <MobileNav
          open={open}
          items={navItems}
          activePath={pathname}
          mobileLegalItems={mobileLegalItems}
          onClose={closeNav}
        />
      </nav>
    </header>
  );
};

export default AwardsHeader;
