import { useState } from "react";
import classNames from "classnames";
import { InfoTooltip } from "@/components";
import { EditPlan } from "@/types";
import { CreditPurchasingPack } from "@/utils";
import { Tooltip } from "@narrative-software/narrative-web-ui";
import EditPlanLink from "./EditPlanLink";
import EditPlanPackRadioItem from "./EditPlanPackRadioItem";

type EditPlanTileProps = {
  plan: EditPlan;
  packs: CreditPurchasingPack[];
  className?: string;
};

function EditPlanTile({ plan, className, packs }: EditPlanTileProps) {
  const [selectedPackId, setSelectedPackId] = useState<string>(packs?.[packs?.length - 1]?.id || "");

  const lowestPerCreditPack = packs.reduce(
    (prevPack, currentPack) => (prevPack.perCreditAmount < currentPack.perCreditAmount ? prevPack : currentPack),
    packs[0],
  );

  return (
    <div
      className={classNames(
        "bg-off-white text-stealth-bomber max-w-[483px] w-full flex flex-col relative min-w-[340px] md:min-w-0",
        className,
      )}
    >
      {plan.highlighted && (
        <div
          className="absolute top-0 left-0 right-0 h-2"
          style={{
            background:
              "linear-gradient(90deg, #31256F 0%, #4F429C 26.82%, #B44893 50.26%, #F16370 71.09%, #F77B58 87.76%, #FCB76F 100%)",
          }}
        />
      )}

      <div className="pt-[58px] pb-[35px] px-3 border-b border-cinder-block">
        <h3 className="text-h2 font-semibold">{plan.title}</h3>
        <p className="relative inline-block whitespace-nowrap text-22 font-light mt-12">
          {plan.isPaidPlan && <span className="absolute right-[100%] bottom-0">From</span>}
          <span className="text-[6.25rem] leading-[0%] font-semibold">
            {plan.isPaidPlan ? lowestPerCreditPack.perCreditAmount : 0}
          </span>
          <span className="absolute left-[100%] bottom-0">c /image</span>
        </p>
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <div>
          <ul className="pt-5 pb-4 px-4 md:px-5 lg:px-10">
            {plan.features.map((feature, i) => (
              <li
                key={feature.id}
                className={classNames("flex gap-3 text-xs md:text-sm items-center justify-between", {
                  "mb-2.5": i < plan.features.length,
                })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path d="M1 7.6L7.21739 13L17 1" stroke="#B44893" strokeWidth="2" />
                </svg>
                <div dangerouslySetInnerHTML={{ __html: feature.feature }} className="w-full text-left" />
                {feature.tooltip && <InfoTooltip description={feature.tooltip} />}
              </li>
            ))}
          </ul>
          {packs.length > 0 && (
            <div className="bg-ball-bearing px-5 lg:px-10 py-3">
              <fieldset id="pack" className="flex flex-col gap-2">
                {packs.map((pack) => (
                  <EditPlanPackRadioItem
                    key={pack.id}
                    pack={pack}
                    selected={pack.id === selectedPackId}
                    onSelectPack={(pack) => setSelectedPackId(pack.id)}
                  />
                ))}
              </fieldset>
            </div>
          )}
        </div>

        <div className="px-5 lg:px-10 mb-10 pt-5">
          <EditPlanLink plan={plan} href={selectedPackId ? `${plan.ctaHref}?packId=${selectedPackId}` : plan.ctaHref} />
        </div>
      </div>
      <Tooltip />
    </div>
  );
}

export default EditPlanTile;
