import Image from "next/image";

interface Props {
  children: React.ReactNode;
}

const PhotoCollageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="relative overflow-visible w-full grid grid-cols-12 grid-rows-4 text-center z-[2]">
      <div className="flex items-center justify-between col-span-12 lg:col-span-2 row-span-4 h-full">
        <div className="hidden lg:block flex-shrink-0 relative lg:w-[225px] lg:h-[167px] xl:w-[340px] xl:h-[221px] z-0">
          <Image src="/images/bf-sample-image.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
        <div className="relative w-[128px] h-[93px] flex-shrink-0 lg:hidden block z-0">
          <Image src="/images/bf-sample-image-3.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
        <div className="relative w-[103px] h-[121px] flex-shrink-0 lg:hidden block z-0">
          <Image src="/images/bf-sample-image-1.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
      </div>

      <div className="flex flex-col col-span-12 lg:col-span-8 row-span-4">
        <div className="hidden lg:flex justify-start flex-shrink-0 relative lg:w-[130px] lg:h-[153px] xl:w-[174px] xl:h-[204px] z-0">
          <Image
            src="/images/bf-sample-image-1.png"
            layout="fill"
            alt="Black Friday Sample Image"
            className="self-start"
          />
        </div>

        <div className="flex flex-col items-center relative z-10">{children}</div>
        <div className="justify-between items-end hidden lg:flex">
          <div className="relative lg:w-[152.25px] lg:h-[154.5px] xl:w-[203px] xl:h-[206px] flex-shrink-0 z-0">
            <Image src="/images/bf-sample-image-5.png" layout="fill" alt="Black Friday Sample Image" />
          </div>
          <div className="relative lg:w-[165px] lg:h-[127.5px] xl:w-[219px] xl:h-[150px] flex-shrink-0 z-0">
            <Image src="/images/bf-sample-image-2.png" layout="fill" alt="Black Friday Sample Image" />
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-2 row-span-4 h-full flex lg:flex-col justify-between lg:justify-start lg:items-end pt-12 lg:pt-0 lg:space-y-20">
        <div className="relative lg:w-[230px] lg:h-[166.5px] xl:w-[306px] xl:h-[222px] flex-shrink-0 lg:block hidden z-0">
          <Image src="/images/bf-sample-image-3.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
        <div className="relative lg:w-[146px] lg:h-[175px] xl:w-[195px] xl:h-[228px] flex-shrink-0 lg:block hidden z-0">
          <Image src="/images/bf-sample-image-4.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
        <div className="flex-shrink-0 relative w-[134px] h-[91px] lg:hidden block z-0">
          <Image src="/images/bf-sample-image.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
        <div className="flex-shrink-0 relative w-[109px] h-[119px] lg:hidden block z-0">
          <Image src="/images/bf-sample-image-5.png" layout="fill" alt="Black Friday Sample Image" />
        </div>
      </div>
    </div>
  );
};

export default PhotoCollageLayout;
