interface Props {
  className?: string;
}

const LimitedTimeIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="114"
    height="67"
    viewBox="0 0 114 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8009 30.8842C27.0587 31.6089 27.7091 31.0407 28.0219 31.0552C30.2263 23.4586 27.0453 16.5754 25.5871 14.3938C24.0906 12.1547 22.3145 9.16938 15.3119 5.36636C16.8577 5.39492 20.8457 4.92676 20.9257 4.78832C20.9896 4.67757 20.4598 3.31018 20.1539 3.4014C18.5692 3.87407 14.0831 4.17506 12.0847 3.90172C12.3198 4.2974 14.7963 11.4216 15.435 11.2443C15.7463 11.1579 15.5786 10.5705 15.4966 10.3662L14.0329 5.95867C16.1248 6.71726 21.155 9.53903 24.541 14.7574C28.7736 21.2803 26.4786 29.9782 26.8009 30.8842Z"
      fill="#4F4F4F"
    />
    <path
      d="M8.32841 56.1686C8.26936 55.8898 8.09154 55.7741 7.79805 55.8363C7.56326 55.886 7.42981 56.0523 7.42084 56.2995C6.79727 56.8302 6.17421 57.1462 5.54321 57.2798C4.91221 57.4135 4.45246 57.3422 4.14929 57.0691C3.82834 56.7844 3.58974 56.2369 3.41569 55.4152C3.10489 53.9477 3.08825 51.6973 3.37199 48.6933C3.43222 48.0366 3.46035 47.6627 3.44171 47.5746C3.38265 47.2958 3.20483 47.1801 2.92601 47.2392C2.67655 47.292 2.51548 47.6175 2.4366 48.1862C2.28954 49.2293 2.20377 50.4894 2.20865 51.9603C2.20904 53.5548 2.31404 54.7745 2.50052 55.655C2.94807 57.7681 4.02297 58.6444 5.72521 58.2839C6.95786 58.0228 7.77617 57.5429 8.20948 56.8378C8.33671 56.6422 8.38125 56.4181 8.32841 56.1686Z"
      fill="black"
    />
    <path
      d="M10.4528 46.2124C10.3222 45.5961 10.0044 45.326 9.5077 45.3699C9.15414 45.5828 8.99532 45.8464 9.03434 46.1755C9.0561 46.2782 9.08942 46.3631 9.10807 46.4512C9.21685 46.9648 9.56093 47.2139 10.155 47.1954C10.4716 47.0977 10.5709 46.77 10.4528 46.2124ZM10.8321 51.9125C10.7326 51.4429 10.3978 51.2378 9.84867 51.3235C9.72057 51.4426 9.67207 51.5755 9.70004 51.7076C9.70936 51.7516 9.7489 51.8659 9.81554 52.0358C9.83356 54.1479 9.89454 55.3769 9.97535 55.7585C10.0748 56.228 10.2259 56.5794 10.4348 56.8418C10.7032 57.1682 10.9491 57.3155 11.1839 57.2658C11.36 57.2285 11.4409 57.104 11.3974 56.8985C11.3477 56.6638 11.1953 56.4507 10.961 56.2857C10.7994 55.5226 10.7339 54.4172 10.7793 52.9663C10.8012 52.563 10.8208 52.2215 10.8321 51.9125Z"
      fill="black"
    />
    <path
      d="M23.6738 53.7158C23.6613 53.6571 23.5662 53.57 23.4115 53.4187C23.1532 53.2128 22.9133 52.8036 22.6622 52.1975C22.4507 51.7056 22.3072 51.2454 22.214 50.8051C22.071 50.1301 21.8644 49.8059 21.6149 49.8587C20.9839 49.9924 20.1395 51.2905 19.0756 53.7237C18.7165 53.1865 18.4002 52.2722 18.1267 50.9808C17.9899 50.3352 17.8011 50.0225 17.5809 50.0691C17.1407 50.1624 16.219 51.3849 14.7803 53.7135C14.7773 53.1928 14.7744 52.6721 14.783 52.1337C14.7845 51.4894 14.7491 50.9602 14.6683 50.5787C14.4881 49.7276 14.2218 49.3393 13.8843 49.4108C13.7376 49.4419 13.6179 49.5285 13.5076 49.6592C13.3595 49.8286 13.2879 49.9971 13.2958 50.1794C13.538 50.5267 13.6913 50.8163 13.7473 51.0804C13.8281 51.4619 13.8639 52.2823 13.8777 53.506C13.8805 54.5327 13.9332 55.2882 14.0326 55.7578C14.1756 56.4328 14.4263 56.7477 14.8078 56.6669C15.0133 56.6234 15.1334 56.3219 15.142 55.7835C15.1582 55.1361 15.4115 54.3771 15.9249 53.4711C16.3226 52.7429 16.7715 52.1112 17.263 51.6084C17.5543 52.9113 17.9301 53.8897 18.4053 54.5404C18.6924 54.9549 18.9444 55.1315 19.1792 55.0817C19.3994 55.0351 19.6053 54.7768 19.7793 54.2953C20.1843 53.1669 20.7022 52.1373 21.3006 51.1979C22.0233 53.4521 22.6473 54.5159 23.1609 54.4071C23.5718 54.3201 23.7546 54.0974 23.6738 53.7158Z"
      fill="black"
    />
    <path
      d="M25.6357 42.9967C25.5052 42.3804 25.1873 42.1104 24.6906 42.1542C24.3371 42.3671 24.1782 42.6308 24.2173 42.9598C24.239 43.0625 24.2724 43.1475 24.291 43.2355C24.3998 43.7491 24.7439 43.9982 25.3379 43.9797C25.6545 43.882 25.7538 43.5543 25.6357 42.9967ZM26.015 48.6968C25.9155 48.2272 25.5808 48.0222 25.0316 48.1078C24.9035 48.2269 24.855 48.3599 24.883 48.4919C24.8923 48.536 24.9318 48.6502 24.9985 48.8201C25.0165 50.9322 25.0775 52.1613 25.1583 52.5428C25.2577 53.0124 25.4088 53.3637 25.6177 53.6261C25.8862 53.9526 26.132 54.0998 26.3668 54.0501C26.5429 54.0128 26.6239 53.8883 26.5804 53.6829C26.5306 53.4481 26.3782 53.2351 26.1439 53.07C25.9823 52.3069 25.9168 51.2015 25.9622 49.7506C25.9841 49.3473 26.0038 49.0059 26.015 48.6968Z"
      fill="black"
    />
    <path
      d="M32.5122 46.9681C32.4251 46.5572 32.0117 46.4148 31.2779 46.5702C31.1605 46.5951 30.9258 46.6448 30.5705 46.7047C30.2183 46.7793 30.0128 46.8228 29.9688 46.8321C29.9672 46.1732 29.9964 45.3697 30.0385 44.4101C30.0618 43.8686 30.0868 43.4799 30.1313 43.2558C30.1474 43.1145 30.155 43.0055 30.1394 42.9321C30.068 42.5946 29.8692 42.4527 29.5464 42.5211C29.3703 42.5584 29.2662 42.7184 29.2457 42.9834C29.1366 44.7851 29.0842 46.1302 29.0621 47.0395C28.4458 47.17 28.1718 47.3967 28.2371 47.7049C28.3117 48.0571 28.5806 48.1688 29.0649 48.0662L29.0988 50.5429C29.0985 51.555 29.1596 52.278 29.256 52.7329C29.4051 53.4373 29.6589 53.7668 30.0405 53.686C30.1579 53.6612 30.2482 53.5807 30.3322 53.4709C30.4163 53.3611 30.4532 53.2459 30.4284 53.1285C30.388 52.9378 30.2866 52.8213 30.1297 52.7318C29.9836 52.0421 29.9185 51.2279 29.9197 50.2924C29.9042 50.219 29.9155 49.404 29.9391 47.8504C30.0272 47.8317 30.3238 47.7842 30.7934 47.6848C31.2661 47.6 31.5742 47.5347 31.7063 47.5068C31.8192 47.6055 31.949 47.6394 32.102 47.6376L32.2928 47.5972C32.4694 47.3452 32.5495 47.1442 32.5122 46.9681Z"
      fill="black"
    />
    <path
      d="M39.5752 50.1947C39.5503 50.0773 39.4845 49.9839 39.3716 49.8851C39.2649 49.8157 39.1644 49.7757 39.047 49.8006C38.9737 49.8161 38.8154 49.865 38.5574 49.9502C38.2879 50.0533 38.0856 50.1115 37.9535 50.1395C37.3079 50.2762 36.7877 50.0644 36.3637 49.5102C36.9503 49.0947 37.4093 48.5835 37.7408 47.9766C38.1069 47.3164 38.2383 46.706 38.1171 46.1337C38.0363 45.7521 37.818 45.4457 37.4917 45.2081C37.0294 44.9074 36.619 44.7797 36.2522 44.8574C35.6505 44.9848 35.2324 45.472 34.9916 46.2897C34.7792 47.0246 34.7492 47.7516 34.9015 48.4706C34.945 48.6761 35.0063 48.8931 35.1001 49.1186C35.0151 49.1519 34.9124 49.1736 34.8244 49.1923C34.5749 49.2451 34.3099 49.2246 34.0262 49.116C33.7426 49.0074 33.5772 48.9505 33.5332 48.9598C33.2397 49.0219 33.1271 49.2144 33.1924 49.5226C33.2421 49.7574 33.4679 49.9549 33.8783 50.0826C34.2091 50.1966 34.6386 50.1976 35.1522 50.0888C35.299 50.0577 35.4457 50.0266 35.5569 49.9724C36.2858 50.9527 37.2195 51.3069 38.3641 51.0645C39.2446 50.878 39.6591 50.5909 39.5752 50.1947ZM37.2637 46.3757C37.3476 46.7719 37.2492 47.1761 36.9863 47.5998C36.7265 48.0381 36.3747 48.404 35.9455 48.6942C35.8726 48.495 35.8144 48.2926 35.7771 48.1165C35.6777 47.6469 35.681 47.1556 35.7932 46.6719C35.9054 46.1881 36.1082 45.9152 36.4017 45.853C36.5631 45.8188 36.7401 45.858 36.9295 45.9559C37.1073 46.0715 37.2295 46.2143 37.2637 46.3757Z"
      fill="black"
    />
    <path
      d="M43.7484 38.4706C43.6862 38.1771 43.52 38.0436 43.2705 38.0965C43.0357 38.1462 42.9347 38.3209 42.9528 38.6237C42.9905 40.3943 43.1739 42.5634 43.5355 45.1394C43.1381 44.8556 42.7895 44.7301 42.496 44.7922C41.9384 44.9103 41.4393 45.522 40.9632 46.6042C40.4902 47.701 40.3361 48.6382 40.5039 49.4307C40.6096 49.9296 40.8109 50.301 41.1372 50.5385C41.4488 50.7792 41.8498 50.8629 42.3341 50.7603C42.7743 50.6671 43.1795 50.3359 43.5528 49.7816C43.8566 49.3339 44.0893 48.84 44.2215 48.306C44.4791 49.2327 44.7652 50.0767 45.086 50.8674C45.2317 51.2659 45.4251 51.4549 45.6599 51.4052C45.7479 51.3866 45.8382 51.3061 45.9223 51.1963C45.9917 51.0896 46.0171 50.9922 45.9953 50.8895C45.958 50.7134 45.879 50.4848 45.755 50.1891C45.2591 49.0062 44.8713 47.7544 44.5885 46.419C44.3989 45.5238 44.2028 44.3081 43.9969 42.7571C44.1233 42.485 44.1523 42.1875 44.0839 41.8647C44.028 41.6006 43.9845 41.3951 43.9681 41.2453C43.9321 40.9309 43.8821 40.4048 43.8441 39.6463C43.7954 38.9819 43.7732 38.588 43.7484 38.4706ZM43.7605 46.5637C43.7252 47.3378 43.5506 48.0341 43.237 48.6525C42.8917 49.3389 42.4696 49.735 41.9853 49.8375C41.6038 49.9184 41.378 49.7209 41.2754 49.2366C41.1604 48.6936 41.2731 47.9951 41.6429 47.1348C41.9896 46.3101 42.3374 45.8531 42.6749 45.7816C42.7043 45.7754 42.7661 45.7777 42.8866 45.7675C43.0941 46.1682 43.3795 46.4297 43.7605 46.5637Z"
      fill="black"
    />
    <path
      d="M56.0854 41.9754C55.9983 41.5645 55.5849 41.4221 54.8511 41.5775C54.7337 41.6024 54.4989 41.6521 54.1436 41.712C53.7915 41.7866 53.586 41.8301 53.542 41.8395C53.5404 41.1805 53.5696 40.377 53.6117 39.4175C53.635 38.8759 53.66 38.4873 53.7045 38.2632C53.7206 38.1218 53.7282 38.0128 53.7126 37.9395C53.6411 37.602 53.4424 37.46 53.1196 37.5284C52.9435 37.5657 52.8394 37.7258 52.8189 37.9908C52.7098 39.7925 52.6573 41.1375 52.6353 42.0468C52.0189 42.1774 51.745 42.404 51.8102 42.7122C51.8848 43.0644 52.1538 43.1761 52.6381 43.0735L52.672 45.5503C52.6717 46.5623 52.7328 47.2853 52.8291 47.7402C52.9783 48.4446 53.2321 48.7742 53.6137 48.6933C53.7311 48.6685 53.8213 48.588 53.9054 48.4782C53.9895 48.3684 54.0264 48.2533 54.0016 48.1359C53.9612 47.9451 53.8598 47.8286 53.7029 47.7391C53.5568 47.0494 53.4917 46.2353 53.4929 45.2997C53.4773 45.2264 53.4887 44.4113 53.5123 42.8577C53.6004 42.8391 53.897 42.7916 54.3666 42.6921C54.8392 42.6073 55.1474 42.5421 55.2795 42.5141C55.3924 42.6128 55.5222 42.6467 55.6752 42.6449L55.8659 42.6045C56.0425 42.3525 56.1227 42.1515 56.0854 41.9754Z"
      fill="black"
    />
    <path
      d="M58.244 36.0904C58.1135 35.4741 57.7956 35.2041 57.2989 35.248C56.9454 35.4609 56.7866 35.7245 56.8256 36.0535C56.8473 36.1563 56.8807 36.2412 56.8993 36.3292C57.0081 36.8429 57.3522 37.092 57.9462 37.0735C58.2629 36.9758 58.3621 36.6481 58.244 36.0904ZM58.6233 41.7906C58.5238 41.321 58.1891 41.1159 57.6399 41.2015C57.5118 41.3207 57.4633 41.4536 57.4913 41.5857C57.5006 41.6297 57.5401 41.744 57.6068 41.9139C57.6248 44.026 57.6858 45.255 57.7666 45.6365C57.866 46.1061 58.0171 46.4574 58.226 46.7198C58.4945 47.0463 58.7403 47.1936 58.9751 47.1438C59.1512 47.1065 59.2322 46.9821 59.1887 46.7766C59.1389 46.5418 58.9865 46.3288 58.7522 46.1637C58.5906 45.4007 58.5251 44.2953 58.5705 42.8444C58.5924 42.4411 58.6121 42.0996 58.6233 41.7906Z"
      fill="black"
    />
    <path
      d="M71.465 43.5939C71.4526 43.5352 71.3574 43.448 71.2027 43.2968C70.9445 43.0908 70.7045 42.6817 70.4534 42.0755C70.2419 41.5837 70.0985 41.1234 70.0052 40.6832C69.8623 40.0082 69.6556 39.684 69.4061 39.7368C68.7751 39.8704 67.9308 41.1686 66.8669 43.6018C66.5077 43.0646 66.1914 42.1503 65.9179 40.8589C65.7812 40.2132 65.5923 39.9006 65.3722 39.9472C64.932 40.0404 64.0103 41.2629 62.5715 43.5916C62.5686 43.0709 62.5656 42.5502 62.5742 42.0117C62.5758 41.3674 62.5404 40.8383 62.4596 40.4567C62.2793 39.6056 62.0131 39.2174 61.6756 39.2888C61.5288 39.3199 61.4092 39.4066 61.2989 39.5373C61.1507 39.7067 61.0791 39.8752 61.087 40.0575C61.3293 40.4048 61.4826 40.6943 61.5385 40.9585C61.6193 41.34 61.6551 42.1604 61.6689 43.3841C61.6717 44.4108 61.7244 45.1663 61.8239 45.6358C61.9668 46.3109 62.2175 46.6258 62.5991 46.545C62.8045 46.5014 62.9246 46.2 62.9333 45.6615C62.9495 45.0141 63.2027 44.2552 63.7161 43.3491C64.1139 42.6209 64.5627 41.9892 65.0542 41.4865C65.3455 42.7894 65.7214 43.7678 66.1965 44.4184C66.4836 44.8329 66.7357 45.0095 66.9705 44.9598C67.1906 44.9132 67.3966 44.6549 67.5706 44.1734C67.9755 43.045 68.4934 42.0154 69.0918 41.076C69.8145 43.3302 70.4385 44.394 70.9521 44.2852C71.363 44.1982 71.5458 43.9754 71.465 43.5939Z"
      fill="black"
    />
    <path
      d="M78.3313 41.9863C78.3064 41.8689 78.2406 41.7755 78.1277 41.6768C78.021 41.6074 77.9206 41.5673 77.8032 41.5922C77.7298 41.6078 77.5715 41.6566 77.3135 41.7419C77.044 41.845 76.8417 41.9032 76.7096 41.9311C76.064 42.0679 75.5438 41.8561 75.1198 41.3019C75.7064 40.8863 76.1654 40.3751 76.4969 39.7683C76.863 39.1081 76.9944 38.4976 76.8732 37.9253C76.7924 37.5438 76.5742 37.2374 76.2479 36.9998C75.7855 36.6991 75.3751 36.5713 75.0083 36.649C74.4066 36.7765 73.9885 37.2637 73.7477 38.0813C73.5354 38.8163 73.5053 39.5432 73.6576 40.2623C73.7011 40.4677 73.7624 40.6847 73.8562 40.9102C73.7713 40.9435 73.6685 40.9653 73.5805 40.9839C73.331 41.0368 73.066 41.0162 72.7824 40.9077C72.4987 40.7991 72.3333 40.7421 72.2893 40.7514C71.9958 40.8136 71.8832 41.0061 71.9485 41.3143C71.9982 41.549 72.2241 41.7465 72.6344 41.8743C72.9652 41.9882 73.3947 41.9892 73.9084 41.8805C74.0551 41.8494 74.2018 41.8183 74.313 41.7641C75.042 42.7443 75.9756 43.0986 77.1202 42.8561C78.0007 42.6697 78.4152 42.3826 78.3313 41.9863ZM76.0198 38.1674C76.1038 38.5636 76.0054 38.9678 75.7424 39.3914C75.4826 39.8298 75.1308 40.1956 74.7016 40.4858C74.6287 40.2866 74.5706 40.0843 74.5333 39.9082C74.4338 39.4386 74.4371 38.9473 74.5493 38.4635C74.6615 37.9798 74.8643 37.7068 75.1578 37.6447C75.3192 37.6105 75.4962 37.6497 75.6856 37.7475C75.8634 37.8632 75.9856 38.006 76.0198 38.1674Z"
      fill="black"
    />
    <path
      d="M88.7417 37.5736C88.6267 37.0306 88.3565 36.5512 87.9489 36.1469C87.5266 35.7457 87.0473 35.5098 86.5111 35.4394C86.3226 35.418 86.1958 35.3989 86.1665 35.4051C86.0931 35.4206 86.0229 35.4508 85.9673 35.4779C85.881 35.6495 85.7182 35.822 85.482 36.01C85.039 36.3798 84.736 36.904 84.5615 37.6003C84.3954 38.2641 84.3761 38.8968 84.5005 39.4838C84.6403 40.1441 84.9376 40.6791 85.4101 41.1004C85.9036 41.5479 86.4616 41.721 87.0779 41.5905C87.4594 41.5097 87.8068 41.2674 88.1493 40.8576C88.4918 40.4477 88.6782 40.0249 88.7411 39.5976C88.7794 39.3442 88.8008 39.1556 88.8199 39.0289C88.8921 38.6457 88.866 38.1605 88.7417 37.5736ZM87.9372 37.9739C88.0398 38.4582 87.984 38.9913 87.7699 39.5733C87.5359 40.2055 87.2427 40.5589 86.8612 40.6397C86.509 40.7143 86.1991 40.6266 85.9168 40.3798C85.6346 40.1329 85.451 39.7731 85.3516 39.3035C85.2335 38.7459 85.2421 38.2074 85.3983 37.7144C85.5861 37.1533 85.9264 36.8052 86.4338 36.6671C86.5992 36.724 86.814 36.7246 87.0487 36.6748C87.4862 36.8582 87.7911 37.2842 87.9372 37.9739Z"
      fill="black"
    />
    <path
      d="M95.2173 34.3928C95.1831 34.2314 95.0786 34.1002 94.9186 33.9961C94.7585 33.892 94.5963 33.8497 94.4171 33.8723C93.366 34.8462 92.4283 36.2101 91.5891 37.9671C91.3374 36.7785 91.207 35.6561 91.2305 34.6085C91.231 34.3938 91.2377 34.2084 91.236 34.0554C91.1987 33.8793 91.1058 33.7303 90.9511 33.5791C90.7733 33.4634 90.6079 33.4065 90.4465 33.4406C90.3291 33.4655 90.289 33.566 90.2907 33.7189C90.288 35.2988 90.3961 36.5332 90.5888 37.443C90.766 38.2794 90.997 38.9358 91.285 39.4268C91.4801 39.7688 91.7081 39.9045 92.0016 39.8423C92.0603 39.8299 92.1119 39.7116 92.1649 39.4551C92.2126 39.2457 92.2455 39.0394 92.2901 38.8153C92.5946 37.6468 93.3455 36.4145 94.5283 35.1214L94.8041 37.6542C94.8324 38.0775 94.8714 38.4066 94.9003 38.6151C94.9344 38.7765 95.0242 38.9108 95.1696 39.018C95.3181 39.1399 95.4803 39.1822 95.6418 39.148C95.7592 39.1232 95.7992 39.0227 95.7975 38.8697C95.7177 38.0587 95.6365 37.3859 95.5865 36.8598C95.4694 35.8727 95.3571 35.0531 95.2173 34.3928Z"
      fill="black"
    />
    <path
      d="M98.1858 27.3397C98.1423 27.1342 97.9813 26.9536 97.7293 26.777C97.451 26.6213 97.2385 26.559 97.0917 26.5901C96.9743 26.615 96.9049 26.7216 96.8982 26.9071C96.7498 29.3917 96.914 31.6875 97.3616 33.8006C97.5667 34.7692 97.7461 35.5438 97.9291 36.1184C97.8722 36.2837 97.8654 36.4692 97.9121 36.6893C97.9245 36.748 97.9587 36.9094 98.0324 37.1851C98.0946 37.4786 98.1434 37.6369 98.1559 37.6956C98.2025 37.9157 98.3465 38.1612 98.6172 38.4259C98.891 38.7052 99.1337 38.8378 99.3392 38.7943C99.4859 38.7632 99.5438 38.6743 99.5127 38.5275C99.1173 37.3846 98.7385 35.8856 98.3469 34.0366C98.1728 33.2148 98.0202 32.2046 97.9152 30.9848C97.7964 29.8447 97.7504 28.9038 97.7689 28.1946L97.8498 28.0701C98.1207 27.8288 98.2386 27.5891 98.1858 27.3397Z"
      fill="black"
    />
    <path
      d="M106.179 34.6011L106.026 33.8821C105.849 33.0456 105.598 32.6542 105.275 32.7226C105.04 32.7723 104.908 33.0151 104.845 33.4424C104.701 34.2854 104.483 34.991 104.186 35.5445C103.881 36.1304 103.549 36.446 103.182 36.5237C103.065 36.5486 102.953 36.5263 102.846 36.4569C102.71 36.3937 102.644 36.3004 102.619 36.183C102.56 35.9041 102.502 35.4106 102.455 34.6844C102.4 33.9908 102.339 33.4825 102.27 33.1597C102.202 32.8368 102.081 32.5558 101.937 32.3103C101.764 32.071 101.589 31.97 101.413 32.0073C101.09 32.0757 100.971 32.2388 101.024 32.4883C101.037 32.547 101.073 32.6466 101.169 32.8103C101.25 32.977 101.311 33.1176 101.336 33.235C101.404 33.5578 101.466 34.0661 101.55 34.7535C101.605 35.4472 101.678 35.9377 101.743 36.2458C101.843 36.7154 102.038 37.0574 102.343 37.2687C102.626 37.5156 102.962 37.5824 103.373 37.4954C103.842 37.3959 104.274 37.0439 104.661 36.4099C104.958 35.9329 105.193 35.3772 105.339 34.7636C105.523 35.9206 105.633 36.5872 105.638 36.7548C105.788 40.0658 105.319 42.3422 104.227 43.5549C103.837 43.9594 103.402 44.2203 102.932 44.3197C102.844 44.3384 102.709 44.3517 102.53 44.3743C102.347 44.3823 102.227 44.3924 102.139 44.4111C102.051 44.4297 101.957 44.4955 101.888 44.6022C101.815 44.6942 101.793 44.8063 101.818 44.9237C101.899 45.3052 102.324 45.4298 103.072 45.2713C104.774 44.9108 105.852 43.41 106.322 40.7805C106.658 38.7467 106.62 36.6849 106.179 34.6011Z"
      fill="black"
    />
    <path
      d="M109.366 25.2938C109.3 24.9857 109.134 24.8522 108.87 24.9082C108.679 24.9486 108.513 25.1064 108.366 25.3522C108.273 25.4945 108.244 25.7155 108.253 26.0507C108.258 26.2184 108.265 26.3242 108.271 26.3535C108.29 26.4416 108.326 26.5412 108.392 26.6346C108.391 28.0762 108.561 29.8188 108.902 31.8625C109.038 32.723 109.126 33.2103 109.147 33.3131C109.213 33.6212 109.376 33.74 109.669 33.6778C109.948 33.6188 110.058 33.4116 110.012 33.0532C109.905 32.4013 109.759 31.4204 109.591 30.1219C109.409 28.8266 109.336 27.4006 109.375 25.8439C109.381 25.582 109.387 25.3965 109.366 25.2938ZM110.789 35.9246C110.699 35.499 110.511 35.2628 110.254 35.2098C110.122 35.1613 110.024 35.136 109.936 35.1546C109.496 35.2478 109.308 35.5177 109.372 35.9641C109.4 36.0962 109.421 36.1989 109.466 36.2661C109.52 36.3773 109.616 36.4645 109.778 36.5067C109.936 36.6726 110.119 36.7412 110.324 36.6977C110.72 36.6138 110.879 36.3501 110.789 35.9246Z"
      fill="black"
    />
  </svg>
);

export default LimitedTimeIcon;
