type DownChevronIconProps = {
  className?: string;
};

const DownChevronIcon = ({ className }: DownChevronIconProps) => (
  <svg className={className} width="13" height="9" viewBox="0 0 13 9" fill="none">
    <path d="M1 1L6.5 7L12 1" stroke="#4F4F4F" strokeWidth="1.5" />
  </svg>
);

export { DownChevronIcon };
