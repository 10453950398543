import Link from "next/link";
import { ROUTES } from "@/config";
import { AnchorButton, PromoBanner } from "@narrative-software/narrative-web-ui";

const BottomPromoSaleBanner = () => (
  <PromoBanner
    title={<div className="w-80">Black Friday Sale! Up to 50% off</div>}
    description={<>Get started and tell your photography stories </>}
    form={
      <Link href={`/${ROUTES.PUBLISH.PRICING.SLUG}`} passHref>
        <AnchorButton className="mt-2 sm:w-1/2 sm:mt-0" colour={"slowpoke"}>
          Shop sale
        </AnchorButton>
      </Link>
    }
    backgroundImagePath={`/images/bottom-banner-bg.png`}
    imageAlignment="left"
    large={false}
  />
);

export default BottomPromoSaleBanner;
