import { Image as DatoImage } from "react-datocms";
import classNames from "classnames";
import Link from "next/link";
import { IBlogPost } from "@/types";
import { createHref } from "@/utils";

type Props = IBlogPost & {
  className?: string;
};

const BlogCard: React.FC<Props> = ({ title, category, image, className, ...rest }) => (
  <article className={classNames("flex flex-1", className)}>
    <Link href={createHref(rest)}>
      <a
        className="flex flex-col flex-1 bg-off-white text-center transform transition duration-150 hover:scale-103 hover:shadow-md"
        style={{ willChange: "transform, box-shadow" }}
      >
        <div className="relative pb-2/3 bg-cinder-block overflow-hidden">
          {image && (
            <div className="absolute">
              <DatoImage data={{ alt: title, ...image.responsiveImage }} />
            </div>
          )}
        </div>
        <div className="flex flex-col flex-1 pt-7 pb-9 pl-7 pr-7">
          <h5 className="mb-4 text-h5 text-gray">{title}</h5>
          {category && (
            <p className="mt-auto text-10 font-semibold text-robot-friend uppercase tracking-wider">{category.name}</p>
          )}
        </div>
      </a>
    </Link>
  </article>
);

export default BlogCard;
