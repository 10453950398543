import { useState } from "react";
import classNames from "classnames";
import Image from "next/image";
import { Section, FixedAspectRatioContainer } from "@/components";

const videos = [
  {
    label: "India Earl",
    url: "https://prismic-io.s3.amazonaws.com/narrative-marketing%2Fdbacf84b-f4f3-47aa-be1b-cb6796e67c92_postscroll_india-earl.mp4",
  },
  {
    label: "The Wayfarers",
    url: "https://prismic-io.s3.amazonaws.com/narrative-marketing%2Ff188b9d0-b0d9-4632-8b40-5db1aaf9b213_postscroll_wayfarers.mp4",
  },
  {
    label: "Serafin Castill",
    url: "https://prismic-io.s3.amazonaws.com/narrative-marketing%2F08cdf261-80f0-4bed-b72c-0424c3b02afb_postscroll_serafin-castill.mp4",
  },
  {
    label: "Katch Silva",
    url: "https://prismic-io.s3.amazonaws.com/narrative-marketing%2F9a12fc71-a71a-4e3d-ad1e-dc6b1c39c91f_postscroll_katch-silva.mp4",
  },
  {
    label: "Joshua Mikhaiel",
    url: "https://prismic-io.s3.amazonaws.com/narrative-marketing/be823304-4f15-47c0-bfd0-71e3e6127e5c_PostScroll_joshua-mikhaiel.mp4",
  },
  {
    label: "Tomasz Wagner",
    url: "https://prismic-io.s3.amazonaws.com/narrative-marketing%2F8c26b1fa-23b4-4579-b123-058e9eda6f29_postscroll_tomasz_wagner.mp4",
  },
];

const PublishVideosSection: React.FC = () => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const videoUrl = videos[activeVideoIndex]?.url;
  const playNextVideo = () => setActiveVideoIndex((activeVideoIndex + 1) % videos.length);

  return (
    <Section className="flex-col self-stretch items-center bg-white overflow-x-hidden" addXPadding>
      <div className="flex flex-col items-center w-full max-w-7xl text-center">
        <div className="w-11 h-1 bg-current mb-8" />
        <h2 className="text-h2 font-semibold leading-compact mb-5">Build beautiful stories, like these</h2>
        <div className="text-lg max-w-3xl mb-8 lg:mb-10">
          Publish is a macOS desktop program that allows you to create lightning-fast image-heavy blog posts that appear
          directly on your website.
        </div>
        <FixedAspectRatioContainer aspectRatio={16 / 9} className="w-full max-w-4xl mb-8">
          <video
            className="w-full h-full border border-current rounded overflow-hidden"
            src={videoUrl}
            autoPlay
            muted
            playsInline
            poster="/images/publish-video-placeholder.jpg"
            onEnded={playNextVideo}
          />
          <div
            className="absolute hidden lg:flex"
            style={{ right: "-180px", bottom: "-20px", transform: "scaleX(-1)" }}
          >
            <Image src="/images/crouching-photographer.svg" alt="" width="256" height="316" unoptimized />
          </div>
        </FixedAspectRatioContainer>
        <div className="flex items-center justify-center flex-wrap -mt-2 mb-4">
          {videos.map(({ label }, i) => (
            <button
              key={i}
              onClick={() => setActiveVideoIndex(i)}
              className={classNames("text-xs p-2 transition-colors duration-200", {
                "text-current underline": i === activeVideoIndex,
                "text-shady-lady hover:text-current": i !== activeVideoIndex,
              })}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default PublishVideosSection;
