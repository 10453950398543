import { IEntryForm } from "@/types";

const ApiHelper = {
  getAwardsEntryBody: ({ firstname, lastname, email, country, business, website, entries }: IEntryForm) => ({
    data: {
      email,
      country,
      first_name: firstname,
      last_name: lastname,
      business_name: business,
      photography_website: website,
      entries: entries.map(({ category, title, description, file }) => ({
        category,
        title,
        description,
        image_name: file?.name,
      })),
    },
  }),
};

export default ApiHelper;
