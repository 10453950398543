import parse, { domToReact, attributesToProps } from "html-react-parser";
import { Image as DatoImage } from "react-datocms";
import { Element } from "domhandler/lib/node";
import { Formik, FormikHelpers, Form } from "formik";
import * as Yup from "yup";
import { Api } from "@/api";
import { Section, Container, SelectField, LegalLinks, HtmlToElement } from "@/components";
import { IS_DEVELOPMENT } from "@/config";
import { useAlertDispatch } from "@/providers";
import { ImageType } from "@/types";
import { mockApiRequest } from "@/utils";
import { TextField } from "@material-ui/core";
import { AlertActionType, Button, HorizontalRule } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface FormFragmentType {
  _modelApiKey: typeof MODULE_KEYS.form;
  id: string;
  heading: string;
  formHtml: string;
  text?: string;
  image?: ImageType;
  formHeading?: string;
  successMessage?: string;
  isSection?: boolean;
}

// Fragment
export const formFragment = `
  fragment formFragment on ModuleFormRecord {
    _modelApiKey
    id
    heading
    text
    formHeading
    successMessage
    formHtml
    isSection
    image {
      url
      alt
      width
      height
      responsiveImage(imgixParams: { auto: format }) {
        ...responsiveImageFragment
      }
    }
  }
`;

const validElements = ["input", "select", "label"];

/**
 * Restore the original form field names
 */
const prepareData = (values: Record<string, string>, fields: Element[]) =>
  Object.entries(values).reduce((acc, [formikName, value]) => {
    const field = fields.find(({ attribs }) => attribs["data-formik-name"] === formikName);
    const name = field?.attribs.name || "";
    return { ...acc, [name]: value };
  }, {});

/**
 * Parser for form HTML
 */
const parser = (formHtml: string) => {
  const fields: Element[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labels: Record<string, any> = {};
  const values: Record<string, string> = {};

  parse(formHtml, {
    replace: (domNode) => {
      if (domNode instanceof Element && validElements.includes(domNode.name)) {
        const { name: element, attribs, children } = domNode;
        if (element === "label") {
          labels[attribs.for] = children;
        } else {
          // Hack to get around Formik bug with field names that contain sqaure brackets
          const formikName = attribs.name.replace("[", "").replace("]", "");
          attribs["data-formik-name"] = formikName;
          values[formikName] = attribs.value || "";
          fields.push(domNode);
        }
      }
    },
  });

  return { fields, labels, values };
};

/**
 * Parsed form JSX
 */
const ParsedForm: React.FC<{ formHtml: string; successMessage?: string }> = ({ formHtml, successMessage }) => {
  const alertDispatch = useAlertDispatch();
  const { fields, labels, values: initialValues } = parser(formHtml);

  const validationSchema = Yup.object().shape(
    fields
      .filter(({ attribs }) => attribs.type !== "hidden")
      .reduce((acc, { attribs }) => {
        const formikName = attribs["data-formik-name"];
        return {
          ...acc,
          [formikName]:
            formikName === "email"
              ? Yup.string().email("Invalid email").required("Required")
              : Yup.string().required("Required"),
        };
      }, {}),
  );

  // Handle submit
  const handleSubmit = async (values: Record<string, string>, actions: FormikHelpers<Record<string, string>>) => {
    try {
      const data = prepareData(values, fields);
      const response = IS_DEVELOPMENT ? await mockApiRequest() : await Api.sendActiveCampaignsForm(data);
      if (response.status >= 200 && response.status < 300) {
        alertDispatch({
          type: AlertActionType.SetContent,
          payload: {
            type: "success",
            title: successMessage || "Form submitted successfully. Thank you for your response.",
          },
        });
      } else {
        throw new Error();
      }
    } catch {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "error",
          title: "There was an error submitting your form. Please try again.",
        },
      });
    } finally {
      actions.setSubmitting(false);
      actions.resetForm();
    }
  };

  return (
    <Formik {...{ initialValues, validationSchema }} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
        const getFieldProps = (name: string, helperText?: string) => ({
          name,
          id: name,
          key: name,
          value: values[name],
          error: touched[name] && !!errors[name],
          helperText: helperText ? (touched[name] && errors[name]) || helperText : touched[name] && errors[name],
          onChange: handleChange,
          onBlur: handleBlur,
          fullWidth: true,
        });

        return (
          <Form noValidate>
            <div className="grid grid-cols-1 gap-5">
              {fields.map((field) => {
                const { children, attribs, name: element } = field;

                // Dank hack to get the label text
                const formikName = attribs["data-formik-name"];
                const label = labels[attribs.name]?.[0].data;

                switch (element) {
                  case "select": {
                    return (
                      <SelectField
                        {...getFieldProps(formikName)}
                        label={label}
                        inputProps={{ style: { backgroundColor: "transparent" } }}
                      >
                        {domToReact(children)}
                      </SelectField>
                    );
                  }
                  default: {
                    if (attribs.type === "hidden") {
                      return <input key={formikName} {...attributesToProps(field.attribs)} />;
                    }
                    return (
                      <TextField
                        {...getFieldProps(formikName)}
                        label={label}
                        type={formikName === "email" ? "email" : attribs.type}
                      />
                    );
                  }
                }
              })}
            </div>

            <div className="my-3 md:my-5">
              <LegalLinks />
            </div>

            <Button type="submit" colour="black" isLoading={isSubmitting} showLoader>
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

/**
 * Get form
 */
export const DatoForm: React.FC<FormFragmentType> = ({
  id,
  heading,
  text,
  formHeading,
  formHtml,
  successMessage,
  image,
}) => (
  <Section key={id}>
    <Container>
      <div className="grid gap-6 lg:grid-cols-5 lg:gap-15 xl:gap-20">
        <div className="lg:col-span-3">
          <HtmlToElement element="h1" className="text-h2 font-semibold leading-compact lg:text-h1">
            {heading}
          </HtmlToElement>
          <HorizontalRule align="left" topMargin bottomMargin />
          <HtmlToElement element="p" className="prose text-stealth-bomber">
            {text}
          </HtmlToElement>
          {image ? (
            <div className="hidden mt-8 lg:block">
              <DatoImage data={image.responsiveImage} layout="responsive" />
            </div>
          ) : null}
        </div>

        <div className="lg:col-span-2">
          <div className="p-10 rounded-md bg-white">
            {formHeading && (
              <HtmlToElement element="h3" className="text-h4 font-semibold leading-compact mb-5 lg:text-h3">
                {formHeading}
              </HtmlToElement>
            )}
            <ParsedForm {...{ formHtml, successMessage }} />
          </div>
        </div>
      </div>
    </Container>
  </Section>
);
