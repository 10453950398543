type Props = {
  className?: string;
};

const TooltipIcon: React.FC<Props> = ({ className }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14.5791C10.866 14.5791 14 11.4451 14 7.5791C14 3.71311 10.866 0.579102 7 0.579102C3.13401 0.579102 0 3.71311 0 7.5791C0 11.4451 3.13401 14.5791 7 14.5791Z"
      fill="currentColor"
    />
    <path
      d="M6.484 11.7051C6.036 11.7051 5.812 11.4438 5.812 10.9211C5.812 10.7811 5.83533 10.5851 5.882 10.3331L6.456 7.4211C6.484 7.29043 6.498 7.1831 6.498 7.0991C6.498 6.97777 6.47467 6.8891 6.428 6.8331C6.38133 6.76777 6.32067 6.7351 6.246 6.7351C6.05 6.7351 5.784 6.92177 5.448 7.2951C5.43867 7.30443 5.42467 7.3091 5.406 7.3091C5.36867 7.3091 5.34067 7.2951 5.322 7.2671C5.30333 7.22977 5.30333 7.1971 5.322 7.1691C5.658 6.7771 5.96133 6.49243 6.232 6.3151C6.50267 6.13777 6.76867 6.0491 7.03 6.0491C7.478 6.0491 7.702 6.29177 7.702 6.7771C7.702 6.93577 7.68333 7.09443 7.646 7.2531L7.058 10.3331C7.03933 10.4171 7.03 10.5244 7.03 10.6551C7.03 10.8791 7.1 10.9911 7.24 10.9911C7.33333 10.9911 7.436 10.9491 7.548 10.8651C7.66933 10.7811 7.814 10.6598 7.982 10.5011C8.00067 10.4824 8.01933 10.4731 8.038 10.4731C8.066 10.4731 8.08933 10.4918 8.108 10.5291C8.12667 10.5571 8.122 10.5851 8.094 10.6131C7.77667 10.9864 7.492 11.2618 7.24 11.4391C6.99733 11.6164 6.74533 11.7051 6.484 11.7051ZM7.422 4.5931C7.17933 4.5931 6.98333 4.5231 6.834 4.3831C6.694 4.23377 6.624 4.0331 6.624 3.7811C6.624 3.5571 6.694 3.3751 6.834 3.2351C6.98333 3.0951 7.17933 3.0251 7.422 3.0251C7.66467 3.0251 7.85133 3.0951 7.982 3.2351C8.122 3.3751 8.192 3.5571 8.192 3.7811C8.192 4.04243 8.122 4.2431 7.982 4.3831C7.85133 4.5231 7.66467 4.5931 7.422 4.5931Z"
      fill="white"
    />
  </svg>
);

export default TooltipIcon;
