import { Section, ImageWithText, StopWatchIcon, LayoutIcon, MagnifyingGlassIcon } from "@/components";
import Screenshot1 from "@/images/publish-screenshot-1.jpg";
import Screenshot2 from "@/images/publish-screenshot-2.jpg";
import Screenshot3 from "@/images/publish-screenshot-3.jpg";

const PublishKeyFeaturesSection: React.FC = () => (
  <Section className="flex-col self-center items-center w-full max-w-7xl overflow-x-hidden" addXPadding>
    <div className="w-11 h-1 bg-current mb-8" />
    <h2 className="text-h2 font-semibold leading-compact mb-8 lg:mb-16 text-center">
      <u>Publish</u> key features
    </h2>

    {/* 1 */}
    <ImageWithText
      orientation="image-left"
      className="mb-10 md:mb-24"
      icon={<StopWatchIcon />}
      title="Build image heavy posts in 5 minutes"
      mainImageSrc={Screenshot1}
      width={585}
      height={358}
    >
      Save time. Build a blog post in 5 minutes (or less) with our drag and drop interface or Magic Wand feature which
      builds your content for you.
    </ImageWithText>

    {/* 2 */}
    <ImageWithText
      orientation="image-right"
      className="mb-10 md:mb-20"
      icon={<LayoutIcon />}
      title="Design beautiful & creative posts"
      mainImageSrc={Screenshot2}
      width={585}
      height={358}
    >
      Let your blog stand out. Image crop, resize, and enhance your blog with text, spacers, gif and video. Each blog
      post is completely unique.
    </ImageWithText>

    {/* 3 */}
    <ImageWithText
      orientation="image-left"
      icon={<MagnifyingGlassIcon />}
      title="Fits into to your SEO strategy"
      mainImageSrc={Screenshot3}
      width={585}
      height={359}
    >
      To help you reach your SEO goals, we’ve developed a tool that highlights keywords most relevant to your content.
      We’ll let you know if your keywords are great (green!), ok (orange) or needs work (red).
    </ImageWithText>
  </Section>
);

export default PublishKeyFeaturesSection;
