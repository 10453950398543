const CrossIcon: React.FC = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
    <rect
      width="1.99517"
      height="14.9638"
      transform="matrix(0.706489 0.707724 -0.706489 0.707724 10.5879 0.000488281)"
      fill="#E0E0E0"
    />
    <rect
      width="1.99517"
      height="14.9638"
      transform="matrix(-0.707582 0.706631 -0.707582 -0.706631 12 10.5737)"
      fill="#E0E0E0"
    />
  </svg>
);

export default CrossIcon;
