type Props = {
  className?: string;
};

const CopyIcon: React.FC<Props> = ({ className }) => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2V18H1C0.447715 18 0 17.5523 0 17V1C0 0.447716 0.447715 0 1 0H14C14.5523 0 15 0.447715 15 1V2H2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C4 4.44772 4.44772 4 5 4H18C18.5523 4 19 4.44772 19 5V21C19 21.5523 18.5523 22 18 22H5C4.44772 22 4 21.5523 4 21V5ZM6 20V6H17V20H6Z"
      fill="currentColor"
    />
  </svg>
);

export default CopyIcon;
