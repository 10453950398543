/* eslint-disable no-useless-escape */
import { useEffect } from "react";
import { AppProps } from "next/app";
import Script from "next/script";
import "styles/index.scss";
import { CookieConsent } from "@/components";
import { GTM_ID, VWO_ACCOUNT_ID, IS_PRODUCTION } from "@/config";
import { AlertContainer, ModalContainer } from "@/containers";
import { initIntercom } from "@/events";
import { useGlobalAnalyticsEvents, useReferral } from "@/hooks";
import { AlertProvider, ModalProvider } from "@/providers";
import { checkBrowserSupport } from "@/utils";

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  useGlobalAnalyticsEvents();
  useReferral(); // Get referral or affiliate code from query param and set as cookie

  useEffect(() => {
    checkBrowserSupport();
    initIntercom();
  }, []);

  return (
    <>
      {/* Google Tag Manager */}
      {IS_PRODUCTION && GTM_ID ? (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({
              'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`,
          }}
        />
      ) : null}

      {/* VWO */}
      {IS_PRODUCTION && VWO_ACCOUNT_ID ? (
        <Script
          id="vwoCode"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `window._vwo_code=window._vwo_code || (function() {
                    var account_id=${VWO_ACCOUNT_ID},
                      version = 1.5,
                      settings_tolerance=2000,
                      library_tolerance=2500,
                      use_existing_jquery=false,
                      is_spa=1,
                      hide_element='body',
                      hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
                      /* DO NOT EDIT BELOW THIS LINE */
                      f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`,
          }}
        />
      ) : null}
      <AlertProvider>
        <ModalProvider>
          <AlertContainer />
          <ModalContainer />
          <Component {...pageProps} />
        </ModalProvider>
      </AlertProvider>
      <CookieConsent />
    </>
  );
};

export default App;
