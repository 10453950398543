const WebIcon: React.FC = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 19H20.5C21.9114 19 23 18.0226 23 16.6112V2.5556C23 1.14417 21.8558 0 20.4445 0H2.55554C1.14417 0 0 1.14417 0 2.5556V16.6112C0 18.0226 1.14417 19 2.55554 19H6.27777H10V22H7V23H16V22H13V19ZM1 3H22V16H1V3ZM3.06197 12H4.34145L5.05156 8.82517L5.75528 12H7.12432L8.19269 7H6.97718L6.35663 10.1469L5.6913 7H4.52698L3.91282 10.1189L3.32426 7H2L3.06197 12ZM9.46562 12H10.7451L11.4552 8.82517L12.1589 12H13.528L14.5963 7H13.3808L12.7603 10.1469L12.095 7H10.9306L10.3165 10.1189L9.72792 7H8.40365L9.46562 12ZM17.1488 12H15.8693L14.8073 7H16.1316L16.7201 10.1189L17.3343 7H18.4986L19.1639 10.1469L19.7845 7H21L19.9316 12H18.5626L17.8589 8.82517L17.1488 12Z"
      fill="white"
    />
  </svg>
);

export default WebIcon;
