import { Dispatch, SetStateAction } from "react";
import { StructuredTextGraphQlResponse } from "react-datocms";
import classNames from "classnames";
import { isEmptyDocument } from "datocms-structured-text-utils";
import { ProductInterval } from "@/api";
import { Container, Section } from "@/components";
import { BillingIntervalToggle } from "@/components/pricing/BillingIntervalToggle/BillingIntervalToggle";
import { BILLING_INTERVALS_WITH_LABELS } from "@/components/pricing/BillingIntervalToggle/constants";
import { ImageType } from "@/types";
import { HorizontalRule, Tooltip } from "@narrative-software/narrative-web-ui";
import { StructuredText } from "../../modules/structured-text";

type PlanComparisonSectionProps = {
  title: string;
  description: StructuredTextGraphQlResponse;
  showBillingIntervalControl?: boolean;
  showSectionIntroDivider?: boolean;
  darkMode?: boolean;
  backgroundImage?: ImageType;
  billingInterval: ProductInterval;
  setBillingInterval: Dispatch<SetStateAction<ProductInterval>>;
  planComparison: React.ReactNode;
  planComparisonMobileNav: React.ReactNode;
};

const PlanComparisonSection = ({
  title,
  description,
  showBillingIntervalControl,
  showSectionIntroDivider = true,
  darkMode,
  backgroundImage,
  billingInterval,
  setBillingInterval,
  planComparison,
  planComparisonMobileNav,
}: PlanComparisonSectionProps) => (
  <Section
    style={backgroundImage ? { backgroundImage: `url(${backgroundImage.responsiveImage.src})` } : {}}
    className={classNames("bg-cover", {
      "bg-black": !backgroundImage && darkMode,
    })}
  >
    <Container>
      <div className={classNames({ "text-white": backgroundImage })}>
        {showSectionIntroDivider && (
          <HorizontalRule
            className={classNames("mb-7", {
              "border-white": darkMode,
              "border-current": !darkMode,
            })}
          />
        )}
        <div
          className={classNames("flex flex-col items-center -mt-1 mb-10 text-center", {
            "text-white": darkMode,
          })}
        >
          {title && (
            <h1
              className={classNames("font-semibold leading-tight mb-5", {
                "text-h2 lg:text-h1": title.length < 53,
                "text-h2": title.length >= 53,
              })}
            >
              {title}
            </h1>
          )}
          {!isEmptyDocument(description) && <StructuredText data={description} />}
          {showBillingIntervalControl && (
            <div>
              <BillingIntervalToggle
                selected={billingInterval}
                eventHandler={setBillingInterval}
                intervals={BILLING_INTERVALS_WITH_LABELS}
              />
            </div>
          )}
        </div>
      </div>
      {planComparison}
      {planComparisonMobileNav}
    </Container>
    <Tooltip />
  </Section>
);

export { PlanComparisonSection };
