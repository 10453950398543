const StopWatchIcon: React.FC = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="16" stroke="#1C1C1C" strokeWidth="2" />
    <circle cx="17" cy="17" r="12" stroke="#1C1C1C" strokeWidth="2" />
    <rect x="16" y="9" width="2" height="8" fill="#1C1C1C" />
    <rect x="22" y="16" width="2" height="6" transform="rotate(90 22 16)" fill="#1C1C1C" />
    <rect x="30.3438" y="4.24219" width="2" height="3.07167" transform="rotate(45 30.3438 4.24219)" fill="#1C1C1C" />
    <rect
      width="4.44377"
      height="2"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 29.4805 3.37793)"
      fill="#1C1C1C"
    />
  </svg>
);

export default StopWatchIcon;
