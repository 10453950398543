import React from "react";
import classNames from "classnames";
import { ProductInterval } from "@/api";

type BillingInterval = {
  value: ProductInterval;
  label: string;
};

type BillingIntervalToggleProps = {
  intervals: BillingInterval[];
  selected: ProductInterval;
  eventHandler(interval: ProductInterval): void;
};

const BillingIntervalToggle = ({ intervals, selected, eventHandler }: BillingIntervalToggleProps) => (
  <ul className="flex justify-center">
    {intervals.map((interval) => (
      <li key={interval.value}>
        <button
          type="button"
          onClick={() => eventHandler(interval.value)}
          className={classNames("px-5 py-2 text-14 capitalize cursor-pointer focus:outline-none", {
            underline: selected === interval.value,
            "underline-offset-4": selected === interval.value,
            "text-gray-600": selected !== interval.value,
          })}
        >
          {interval.label}
        </button>
      </li>
    ))}
  </ul>
);

export { BillingIntervalToggle };
