import { Image as DatoImage } from "react-datocms";
import { Section, Container } from "@/components";
import { formatImageData } from "@/config";
import { ImageType } from "@/types";
import { TextMedia, VideoInternalType, VideoExternalType } from "@narrative-software/narrative-web-ui";
import { ButtonFragmentType, DatoButton } from "./button";
import { MODULE_KEYS } from "./config";

export interface TextMediaItemFragmentType {
  _modelApiKey: typeof MODULE_KEYS.textMediaItem;
  id: string;
  title: string;
  heading: string;
  headingSize: "h1" | "h2" | "h3";
  content: string;
  textSize: "small" | "base" | "large";
  textAlignment: "left" | "center" | "right";
  imageAlignment: "left" | "right" | "top" | "bottom";
  imageWidthPercentage: number;
  isVideo: boolean;
  isVideoFile: boolean;
  hasLine: boolean;
  linePosition: "top" | "bottom";
  hasBorder: boolean;
  hasColourFrame: boolean;
  callToAction: ButtonFragmentType[];
  icon?: ImageType;
  image?: ImageType;
  videoInternal?: VideoInternalType;
  videoExternal?: VideoExternalType;
  isSection?: undefined;
}

export interface TextMediaSectionFragmentType {
  _modelApiKey: typeof MODULE_KEYS.textMediaSection;
  id: string;
  heading: string;
  items: TextMediaItemFragmentType[];
  isSection?: boolean;
}

// Fragment
export const textMediaItemFragment = `
  fragment textMediaItemFragment on ModuleImageTextItemRecord {
    _modelApiKey
    id
    title
    heading
    headingSize
    content
    textSize
    textAlignment
    imageAlignment
    imageWidthPercentage
    isVideo
    isVideoFile
    hasLine
    linePosition
    hasBorder
    hasColourFrame
    videoInternal {
      url
      width
      height
      alt
      mimeType
    }
    videoExternal {
      url
      width
      height
      title
      provider
      providerUid
    }
    callToAction {
      ...buttonFragment
    }
    icon {
      url
      alt
      width
      height
      responsiveImage(imgixParams: { auto: format }) {
        ...responsiveImageFragment
      }
    }
    image {
      url
      alt
      width
      height
      responsiveImage(imgixParams: { auto: format }) {
        ...responsiveImageFragment
      }
    }
  }
`;

// Fragment
export const textMediaSectionFragment = `
  fragment textMediaSectionFragment on ModuleImageTextRecord {
    _modelApiKey
    id
    heading
    isSection
    items {
      ...textMediaItemFragment
    }
  }
`;

const TextMediaItem = ({
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  heading,
  headingSize,
  content,
  icon,
  image,
  callToAction,
  ...rest
}: TextMediaItemFragmentType) => {
  const imageData = image && formatImageData({ image, removeBase64: false });
  return (
    <TextMedia.Item
      key={id}
      title={heading}
      titleSize={headingSize}
      aspectRatio={imageData?.aspectRatio || 0}
      image={imageData && <DatoImage data={imageData} />}
      icon={icon && <DatoImage data={formatImageData({ image: icon, removeBase64: false })} />}
      callToAction={
        callToAction && callToAction.length
          ? callToAction.map((props) => <DatoButton key={props.id} {...props} />)
          : null
      }
      {...rest}
    >
      {content}
    </TextMedia.Item>
  );
};

export const SingleTextMediaSection: React.FC<TextMediaItemFragmentType> = (props) => (
  <Section key={`section-${props.id}`}>
    <Container>
      <TextMediaItem {...props} />
    </Container>
  </Section>
);

export const TextMediaSection: React.FC<TextMediaSectionFragmentType> = ({ id, items, heading }) => (
  <Section key={id}>
    <Container>
      <TextMedia title={heading}>
        {items.map((props) => (
          <TextMediaItem key={props.id} {...props} />
        ))}
      </TextMedia>
    </Container>
  </Section>
);
