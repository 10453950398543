import { Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import { SelectField } from "@/components";
import { IBlogCategory } from "@/types";

interface Props {
  items: IBlogCategory[];
  active: string;
  handleChange: Dispatch<SetStateAction<string>>;
}

const BlogCategories: React.FC<Props> = ({ items, handleChange, active }) => {
  const desktopItems = [{ name: "All", slug: "" }, ...items];
  const mobileItems = [{ name: "Show all topics", slug: "" }, ...items];

  return (
    <>
      <div className="lg:hidden">
        <SelectField
          id="blog-category"
          name="blog-category"
          value={active}
          fullWidth
          onChange={(e) => handleChange(e.target.value as string)}
          onBlur={(e) => handleChange(e.target.value)}
          inputProps={{
            style: { backgroundColor: "transparent" },
          }}
        >
          {mobileItems.map(({ name, slug }) => (
            <option key={slug} value={slug}>
              {name}
            </option>
          ))}
        </SelectField>
      </div>

      <ul className="hidden justify-center lg:flex">
        {desktopItems.map(({ name, slug }) => (
          <li key={slug}>
            <button
              type="button"
              onClick={() => handleChange(slug)}
              className={classNames(
                "px-5 py-2 text-14 cursor-pointer transition-colors duration-150 focus:outline-none hover:text-gray-900",
                {
                  "text-gray-600": slug !== active,
                  "text-gray-900 underline": slug === active,
                },
              )}
            >
              {name}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default BlogCategories;
