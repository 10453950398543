import { Transition } from "@headlessui/react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Button } from "@narrative-software/narrative-web-ui";

const COOKIE_KEY = "narrative-cookie-consent";
const COOKIE_VALUE = "true";

const CookieConsent: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  const setCookie = () => {
    setOpen(false);
    Cookies.set(COOKIE_KEY, COOKIE_VALUE, { expires: 365 });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const cookie = Cookies.get(COOKIE_KEY);
      if (cookie !== COOKIE_VALUE) {
        setOpen(true);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Transition
      appear
      show={isOpen}
      as="article"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
      className="fixed z-30 left-0 right-0 bottom-0 flex flex-wrap items-center justify-center px-4 py-3 bg-white shadow-reverse transform transition-transform duration-500 sm:py-2"
    >
      <p className="text-13 text-center leading-tight w-full mb-2 sm:w-auto sm:mb-0">
        This website uses cookies to enhance the user experience.
      </p>
      <div className="sm:ml-3">
        <Button colour="black" size="small" className="pl-4 pr-4" onClick={setCookie}>
          I understand
        </Button>
      </div>
    </Transition>
  );
};

export default CookieConsent;
