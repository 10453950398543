import classNames from "classnames";
import { useRouter } from "next/router";
import { AwardsHeader, AwardsFooter } from "@/components";
import { ROUTES } from "@/config";

const backgroundStyle = {
  backgroundImage: `
    url("/images/awards/light-leak.png"),
    url("/images/awards/light-leak-reversed.png")
  `,
  backgroundPosition: `
    top right,
    bottom left
  `,
  backgroundSize: `
    auto,
    auto
  `,
  backgroundRepeat: `
    no-repeat,
    no-repeat
  `,
};

const getNavItems = (pathname: string) => [
  // {
  //   href: `/${ROUTES.AWARDS.ENTER.SLUG}`,
  //   label: 'Enter',
  //   isActive: pathname === `/${ROUTES.AWARDS.ENTER.SLUG}`,
  // },
  {
    href: `/${ROUTES.AWARDS.ABOUT.SLUG}`,
    label: "About",
    isActive: pathname === `/${ROUTES.AWARDS.ABOUT.SLUG}`,
  },
  {
    href: `/${ROUTES.AWARDS.CATEGORIES.SLUG}`,
    label: "Categories & Prizes",
    isActive: pathname === `/${ROUTES.AWARDS.CATEGORIES.SLUG}`,
    secondaryNav: [
      {
        href: `/${ROUTES.AWARDS.CATEGORIES.SLUG}#wedding`,
        label: "Wedding",
      },
      {
        href: `/${ROUTES.AWARDS.CATEGORIES.SLUG}#portrait`,
        label: "Portrait",
      },
      {
        href: `/${ROUTES.AWARDS.CATEGORIES.SLUG}#family`,
        label: "Family",
      },
      {
        href: `/${ROUTES.AWARDS.CATEGORIES.SLUG}#unscripted`,
        label: "Unscripted",
      },
      {
        href: `/${ROUTES.AWARDS.CATEGORIES.SLUG}#peoples-choice`,
        label: "People's choice",
      },
    ],
  },
  {
    href: `/${ROUTES.AWARDS.JUDGES.SLUG}`,
    label: "Judges",
    isActive: pathname === `/${ROUTES.AWARDS.JUDGES.SLUG}`,
  },
];

const mobileLegalItems = [
  { href: `/${ROUTES.AWARDS.ABOUT.SLUG}#photography-awards-2022-about-faqs`, label: "FAQs" },
  { href: `/${ROUTES.TERMS.SLUG}`, label: "Narrative terms & conditions" },
  { href: `/${ROUTES.AWARDS.TERMS.SLUG}`, label: "Awards terms & conditions" },
  { href: `/${ROUTES.PRIVACY.SLUG}`, label: "Privacy policy" },
];

interface Props {
  preview?: boolean;
  hideFooter?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const AwardsLayout: React.FC<Props> = ({ children, hideFooter, className, style }) => {
  const { pathname } = useRouter();
  const items = getNavItems(pathname);
  return (
    <div className={classNames("flex flex-col flex-1 text-white bg-black", className)} style={style || backgroundStyle}>
      <AwardsHeader items={items} mobileLegalItems={mobileLegalItems} />
      <main className="flex flex-col flex-grow">{children}</main>
      {!hideFooter && <AwardsFooter items={items} />}
    </div>
  );
};

export default AwardsLayout;
