import { Container, HtmlToElement, AwardsSection as Section } from "@/components";
import { AccordionFragmentType } from "@/modules";
import { Accordion } from "@narrative-software/narrative-web-ui";

const FaqsSection: React.FC<AccordionFragmentType> = ({ heading, items, accordionId, allowMultipleExpanded }) => (
  <Section>
    <Container>
      {/* <HorizontalRule className="mb-6" /> */}
      <HtmlToElement element="h2" className="text-40 leading-tight decoration-2 mb-8 md:mb-12">
        {heading}
      </HtmlToElement>
      <Accordion id={accordionId} allowMultipleExpanded={allowMultipleExpanded} centered={false}>
        {items.map(({ id: itemId, heading, content, isDefaultExpanded }) => (
          <Accordion.Item key={itemId} id={itemId} heading={heading} isExpanded={isDefaultExpanded}>
            {content}
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  </Section>
);

export default FaqsSection;
