import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { Nullable } from "@/types";
import { getSingleQueryParams } from "@/utils";

/**
 * Get query params
 */
export const useQueryParams = () => {
  const { query } = useRouter();
  return getSingleQueryParams(query);
};

/**
 * Check if a component has mounted
 */
export const useMounted = (): boolean => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return isMounted;
};

/**
 * Detect if an element is within the viewport
 */
export const useOnScreen = (ref: React.RefObject<HTMLElement>, rootMargin = "0px"): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

/**
 * Preload images
 */
export const usePreloadImages = (imageSrcs: string[]) => {
  useEffect(() => {
    const randomStr = Math.random().toString(32).slice(2) + Date.now();

    window.usePreloadImagesData = window.usePreloadImagesData ?? {};
    window.usePreloadImagesData[randomStr] = [];

    for (const src of imageSrcs) {
      // preload the image
      const img = new Image();
      img.src = src;
      // keep a reference to the image
      window.usePreloadImagesData[randomStr].push(img);
    }

    return () => {
      delete window.usePreloadImagesData?.[randomStr];
    };
  }, [imageSrcs]);
};

/**
 * Scroll position
 * https://designcode.io/react-hooks-handbook-usescrollposition-hook
 */
export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    let requestRunning: Nullable<number> = null;

    const handleScroll = () => {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition({ x: window.pageXOffset, y: window.pageYOffset });
          requestRunning = null;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (requestRunning) window.cancelAnimationFrame(requestRunning);
    };
  }, []);

  return scrollPosition;
};
