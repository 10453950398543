const TwitterIcon: React.FC = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2.70006C19.2651 3.03414 18.4739 3.26006 17.644 3.361C18.4915 2.84186 19.1419 2.0187 19.4483 1.03811C18.6547 1.51879 17.7778 1.86848 16.841 2.05595C16.0944 1.23999 15.0273 0.730469 13.8463 0.730469C11.5819 0.730469 9.74467 2.61114 9.74467 4.93043C9.74467 5.25969 9.77989 5.57935 9.85033 5.88698C6.4401 5.71154 3.41727 4.04117 1.39226 1.49716C1.03892 2.11964 0.837002 2.84186 0.837002 3.61096C0.837002 5.06742 1.56131 6.35325 2.66244 7.10671C1.99096 7.08629 1.35705 6.89522 0.802958 6.58277V6.63444C0.802958 8.67013 2.21753 10.3681 4.09579 10.7527C3.75183 10.8512 3.38909 10.9005 3.01462 10.9005C2.75048 10.9005 2.49222 10.8753 2.24218 10.826C2.76457 12.494 4.27892 13.7089 6.07501 13.7413C4.67101 14.8685 2.90075 15.5403 0.979046 15.5403C0.648001 15.5403 0.320479 15.5211 0 15.4826C1.81605 16.6723 3.9737 17.3681 6.28984 17.3681C13.8381 17.3681 17.9644 10.969 17.9644 5.41832C17.9644 5.23566 17.9609 5.053 17.9539 4.87395C18.7556 4.28151 19.4518 3.54246 20 2.70006Z"
      fill="currentColor"
    />
  </svg>
);

export default TwitterIcon;
