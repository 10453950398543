import classNames from "classnames";
import styles from "@/styles/custom.module.css";

const SelectPlanComparisonLayout = ({ children }: { children: React.ReactNode }) => (
  <div
    className={classNames(
      "grid grid-cols-[80vw_80vw] md:grid-cols-12 gap-3 md:gap-5 pt-2 px-5 -mx-5 md:mx-0 md:px-0",
      styles["scroll-snap-parent"],
    )}
  >
    {children}
  </div>
);

export { SelectPlanComparisonLayout };
