import { useRef } from "react";
import classNames from "classnames";
import { Container, Figure, Section } from "@/components";
import EyeHeartIcon from "@/components/icons/EyeHeartIcon";
import GlobeIcon from "@/components/icons/GlobeIcon";
import ImageIcon from "@/components/icons/ImageIcon";
import StopWatchIcon from "@/components/icons/StopWatchIcon";
import { useOnScreen } from "@/hooks";
import { MODULE_KEYS } from "./config";

export interface StatFragmentType {
  _modelApiKey: typeof MODULE_KEYS.stat;
  id: string;
  items: StatItemFragmentType[];
  isSection?: boolean;
}

export interface StatItemFragmentType {
  id: string;
  label: string;
  value: number;
  valueToAnimateFrom?: number;
  suffix?: string;
  icon: Icon;
}

// Fragment
export const statFragment = `
  fragment statFragment on ModuleStatRecord {
    _modelApiKey
    id
    items {
      id
      label
      value
      valueToAnimateFrom
      suffix
      icon
    }
  }
`;

type Icon = keyof typeof ICONS;

const ICONS = {
  Image: ImageIcon,
  "Eye Heart": EyeHeartIcon,
  Globe: GlobeIcon,
  "Stop Watch": StopWatchIcon,
} as const;

type StatProps = StatFragmentType & { randomize?: boolean };

const Stat = ({ items }: StatProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);

  if (!items?.length) return null;

  return (
    <Section className="bg-white">
      <Container>
        <div
          ref={ref}
          className={classNames("grid grid-cols-1 gap-8", {
            // purgecss: md:grid-cols-2 md:grid-cols-3 md:grid-cols-4
            [`md:grid-cols-${items.length}`]: true,
          })}
        >
          {items.map((item) => (
            <Figure
              key={item.id}
              end={item.value}
              start={item.valueToAnimateFrom}
              suffix={item.suffix}
              canStart={isOnScreen}
              icon={<StatIcon icon={item.icon} />}
            >
              {item.label}
            </Figure>
          ))}
        </div>
      </Container>
    </Section>
  );
};

const StatIcon = ({ icon }: { icon: Icon }) => {
  const Component = ICONS[icon];
  if (!Component) return <></>;
  return <Component />;
};

export { Stat };
