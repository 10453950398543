interface Props {
  className?: string;
}

const LeftArrowIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} width="31" height="12" viewBox="0 0 31 12" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.11328 7H30.1133V5H2.11328V7Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.41331 6.03816L7.74429 1.70718L6.33008 0.292969L0.584886 6.03816L6.33008 11.7834L7.74429 10.3691L3.41331 6.03816Z"
      fill="currentColor"
    />
  </svg>
);

export default LeftArrowIcon;
