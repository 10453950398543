import { ILinks, ICouponAttributes } from "@/api";

export enum ProductName {
  Select = "Select",
  Publish = "Publish",
  Save = "Save",
}

export enum ProductInterval {
  Year = "year",
  Month = "month",
}

export enum ProductStatus {
  Active = "active",
  Trialing = "trialing",
  PastDue = "past_due",
  Canceled = "canceled",
  Unpaid = "unpaid",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
}

export interface IProductObject {
  type: "products";
  relationships: {
    plan: {
      links: ILinks;
      data: {
        type: "plans";
        id: string;
      };
    };
  };
  links: ILinks;
  id: string;
  attributes: {
    id: string;
    name: ProductName;
    interval: ProductInterval;
    status: ProductStatus;
    coupon: ICouponAttributes | null;
    "current-period-end": number;
    "activation-date": string;
    "has-used-trial": boolean;
    "inserted-at": string;
    "updated-at": string;
  };
}

export interface ICreateProductsRequest {
  data: Array<{
    type: "products";
    attributes: {
      plan_id?: string;
      interval?: ProductInterval;
      coupon_code?: string;
    };
  }>;
}

export interface IUpdateProductRequest {
  data: {
    type: "products";
    id: string;
    attributes: {
      plan_id?: string;
      interval?: ProductInterval;
      coupon_code?: string;
    };
  };
}

export interface IDeleteProductRequest {
  data: {
    type: "products";
    id: string;
  };
}

export interface IProductResponse {
  links: ILinks;
  included: [];
  data: IProductObject;
}

export interface IGetProductsResponse {
  links: ILinks;
  included: [];
  data: IProductObject[];
}
