const LightningBoltIcon: React.FC = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
      <path d="M19.4254 25.8683H13.2754L27.3325 3.02539L24.6968 18.8397H32.604L16.7897 39.9254L19.4254 25.8683Z" />
    </mask>
    <path
      d="M13.2754 25.8683L11.5721 24.8201L9.69626 27.8683H13.2754V25.8683ZM19.4254 25.8683L21.3911 26.2368L21.8352 23.8683H19.4254V25.8683ZM16.7897 39.9254L14.8239 39.5568L18.3897 41.1254L16.7897 39.9254ZM32.604 18.8397L34.204 20.0397L36.604 16.8397H32.604V18.8397ZM24.6968 18.8397L22.724 18.5109L22.3359 20.8397H24.6968V18.8397ZM27.3325 3.02539L29.3053 3.35419L25.6292 1.9772L27.3325 3.02539ZM13.2754 27.8683H19.4254V23.8683H13.2754V27.8683ZM17.4596 25.4997L14.8239 39.5568L18.7554 40.294L21.3911 26.2368L17.4596 25.4997ZM18.3897 41.1254L34.204 20.0397L31.004 17.6397L15.1897 38.7254L18.3897 41.1254ZM32.604 16.8397H24.6968V20.8397H32.604V16.8397ZM26.6696 19.1685L29.3053 3.35419L25.3598 2.69659L22.724 18.5109L26.6696 19.1685ZM25.6292 1.9772L11.5721 24.8201L14.9787 26.9165L29.0359 4.07359L25.6292 1.9772Z"
      fill="#1C1C1C"
      mask="url(#path-1-inside-1)"
    />
    <path
      d="M32.75 12.25C38.4109 17.9109 38.4109 27.0891 32.75 32.75M12.25 32.75C6.58908 27.0891 6.58908 17.9109 12.25 12.25"
      stroke="#1C1C1C"
      strokeWidth="2"
    />
  </svg>
);

export default LightningBoltIcon;
