import { ROUTES } from "@/config";

const LegalLinks: React.FC = () => (
  <p className="text-xxs text-gray-700">
    By clicking the ‘SUBMIT’ button you accept our {/* <br className="hidden sm:block" /> */}
    <a
      href={`/${ROUTES.TERMS.SLUG}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-700 underline hover:text-stealth-bomber transition-colors duration-200"
    >
      Terms and Conditions
    </a>{" "}
    and{" "}
    <a
      href={`/${ROUTES.PRIVACY.SLUG}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-700 underline hover:text-stealth-bomber transition-colors duration-200"
    >
      Privacy Policy
    </a>
    .
  </p>
);

export default LegalLinks;
