const ScrollArrow: React.FC = () => (
  <>
    <svg width="43" height="95" viewBox="0 0 43 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.7928 76.2929L22.5 79.5858L22.5 0H20.5L20.5 79.5858L17.2071 76.2929L15.7928 77.7071L21.5 83.4142L27.2071 77.7071L25.7928 76.2929Z"
        fill="currentColor"
      />
      <path
        d="M0 73.0803C0 63.8787 5.82403 56.0322 14 53V55.1488C6.95052 58.0763 2 65.0061 2 73.0803C2 83.7984 10.7234 92.5 21.5 92.5C32.2766 92.5 41 83.7984 41 73.0803C41 65.0061 36.0495 58.0763 29 55.1488V53C37.176 56.0322 43 63.8787 43 73.0803C43 84.9101 33.3741 94.5 21.5 94.5C9.62588 94.5 0 84.9101 0 73.0803Z"
        fill="currentColor"
      />
    </svg>

    <style jsx>{`
      svg {
        animation-name: bounce;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
      }
      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        30% {
          transform: translateY(-16%);
        }
        50% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(0);
        }
      }
    `}</style>
  </>
);

export default ScrollArrow;
