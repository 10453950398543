import { IS_PRODUCTION, IS_STAGING } from "@/config";
import { AwardsCategory } from "@/types";

export const getCategoryOptions = (categories: AwardsCategory[]) =>
  categories.map(({ title, group, slug }) => {
    const value = `${group}: ${title}`;
    return (
      <option key={slug} value={value}>
        {value}
      </option>
    );
  });

const PRODUCTION_PAYMENT_LINKS = {
  "6_to_10": "https://buy.stripe.com/dR615p9ty0NS34Y5ko",
  "11_to_15": "https://buy.stripe.com/9AQaFZdJOcwA8pi4gh",
} as const;

const TEST_PAYMENT_LINKS = {
  "6_to_10": "https://buy.stripe.com/test_00gcNpfUO6bdgaA4gk",
  "11_to_15": "https://buy.stripe.com/test_5kA28LeQKgPRaQgdQS",
};

const STRIPE_PAYMENT_LINKS = IS_PRODUCTION || IS_STAGING ? PRODUCTION_PAYMENT_LINKS : TEST_PAYMENT_LINKS;

export const getPaymentURL = (uploadCount: number) => {
  if (uploadCount <= 10) return STRIPE_PAYMENT_LINKS["6_to_10"];

  return STRIPE_PAYMENT_LINKS["11_to_15"];
};
