import Image from "next/image";
import { Section, Container } from "@/components";
import AdjustImprove from "@/images/workflow/edit/adjust-and-improve@2x.png";
import ImportCull from "@/images/workflow/edit/import-and-cull@2x.png";
import ShipEdit from "@/images/workflow/edit/ship-and-edit@2x.png";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

type Props = {
  title: string;
  step1Title: string;
  step1Description: string;
  step2Title: string;
  step2Description: string;
  step3Title: string;
  step3Description: string;
};

const EditWorkflowSection: React.FC<Props> = ({
  title,
  step1Title,
  step1Description,
  step2Title,
  step2Description,
  step3Title,
  step3Description,
}) => (
  <Section className="bg-white py-[80px] sm:py-[120px]">
    <Container>
      <HorizontalRule bottomMargin />
      <h2 className="text-h2 font-semibold leading-compact text-center mb-14 lg:mb-16 px-2 md:px-0">{title}</h2>

      <div className="flex flex-col items-center lg:flex-row lg:items-start lg:justify-between">
        {/* Import */}
        <div className="relative flex flex-col flex-1 items-center text-center">
          <Image src={ImportCull} alt={step1Description} width="260" height="172" placeholder="blur" />
          <div className="flex absolute top-0 right-1/2 -mt-3.5 mr-25 w-[86px] h-[109px]">
            <Image src="/images/workflow/select/folder-icon.svg" alt="" width="86" height="109" unoptimized />
          </div>
          <h3 className="mt-3 text-h3 font-semibold lg:mt-8">{step1Title}</h3>
          <p className="mt-3 lg:px-9">{step1Description}</p>
        </div>

        <div className="hidden mt-19 lg:flex">
          <Image src="/images/arrow-right.svg" alt="" width="47" height="16" unoptimized />
        </div>

        {/* Cull */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image src={ShipEdit} alt={step2Description} width="260" height="172" placeholder="blur" />
          <h3 className="mt-3 text-h3 font-semibold lg:mt-8">{step2Title}</h3>
          <p className="mt-3 lg:px-4 xl:px-12">{step2Description}</p>
        </div>

        <div className="hidden mt-19 lg:flex">
          <Image src="/images/arrow-left-right.svg" alt="" width="47" height="16" unoptimized />
        </div>

        {/* Edit */}
        <div className="relative flex flex-col flex-1 items-center mt-12 text-center lg:mt-0">
          <Image src={AdjustImprove} alt={step3Description} width="260" height="172" placeholder="blur" />
          <div className="flex absolute top-0 mt-35">
            <Image src="/images/workflow/edit/lightroom-icon.svg" alt="" width="93" height="42" unoptimized />
          </div>
          <h3 className="mt-8 text-h3 font-semibold">{step3Title}</h3>
          <p className="mt-3 lg:px-10 xl:px-13">{step3Description}</p>
        </div>
      </div>
    </Container>
  </Section>
);

export default EditWorkflowSection;
