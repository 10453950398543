// Added my own node env because Netlify seems to lock NODE_ENV to 'production'
export const IS_DEVELOPMENT = process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_NODE_ENV !== "test";
export const IS_STAGING = process.env.NODE_ENV === "test" || process.env.NEXT_PUBLIC_NODE_ENV === "test";
export const IS_PRODUCTION = process.env.NODE_ENV === "production" && process.env.NEXT_PUBLIC_NODE_ENV !== "test";

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const ACCOUNT_BASE_URL = process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL;
export const BRANCH = process.env.NEXT_PUBLIC_BRANCH;
export const COMMIT_REF = process.env.NEXT_PUBLIC_COMMIT_REF;
export const DATOCMS_API_TOKEN = process.env.DATOCMS_API_TOKEN || "";
export const SELECT_FREE_PROJECT_THRESHOLD_ENDPOINT = `https://narrative-select-project-limit.s3.amazonaws.com/${
  process.env.NEXT_PUBLIC_SELECT_FREE_PROJECT_THRESHOLD_FILE || "project-limit-staging.txt"
}`;

export const IS_BLACK_FRIDAY = process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === "true";

// VWO is A/B testing software
export const VWO_ACCOUNT_ID = process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const INTERCOM_ID = process.env.NEXT_PUBLIC_INTERCOM_ID;
export const ACTIVE_CAMPAIGN_ID = process.env.NEXT_PUBLIC_ACTIVE_CAMPAIGN_ID;

export const IS_GOOGLE_AUTH_ENABLED = process.env.NEXT_PUBLIC_IS_GOOGLE_AUTH_ENABLED === "true";
