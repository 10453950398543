const FormSuccessIcon: React.FC = () => (
  <svg width="113" height="102" viewBox="0 0 113 102" fill="none">
    <path
      d="M50.5 96.5C75.629 96.5 96 76.129 96 51C96 45.7527 95.1117 40.7128 93.4774 36.0226L97.3603 32.1397C99.7079 37.9668 101 44.3325 101 51C101 78.8904 78.3904 101.5 50.5 101.5C22.6096 101.5 0 78.8904 0 51C0 23.1096 22.6096 0.5 50.5 0.5C66.2278 0.5 80.2763 7.68983 89.538 18.962L85.9836 22.5164C77.6441 12.1408 64.8476 5.5 50.5 5.5C25.371 5.5 5 25.871 5 51C5 76.129 25.371 96.5 50.5 96.5ZM112.268 10.2678C113.244 9.29146 113.244 7.70854 112.268 6.73223C111.291 5.75592 109.709 5.75592 108.732 6.73223L47.5 67.9645L27.7678 48.2322C26.7915 47.2559 25.2085 47.2559 24.2322 48.2322C23.2559 49.2085 23.2559 50.7915 24.2322 51.7678L45.7322 73.2678C46.7085 74.2441 48.2915 74.2441 49.2678 73.2678L112.268 10.2678Z"
      fill="#1C1C1C"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default FormSuccessIcon;
